import axios from "axios";
import { getStorageData, StorageKeys } from "lib/storageManager";

const Axios = axios.create({
   baseURL: process.env.REACT_APP_API_BASE_URL,
   timeout: 60 * 3000,
});

Axios.interceptors.request.use(
   async (config) => {
      const token = getStorageData(StorageKeys.token);
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
   },

   (error) => {
      return Promise.reject(error);
   }
);

Axios.interceptors.response.use(
   (response) => response,

   async (error) => {
      // if (error.message.includes("401") || error.message.includes("403")) {
      //   removeStorageData(StorageKeys.token);
      //   removeStorageData(StorageKeys.user);
      //   // window.location.replace("/");
      // }

      return Promise.reject(error);
   }
);

export default Axios;
