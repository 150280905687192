import React from "react";
import "./styles.scss";
import abt_img from "assets/images/abt_img.svg";
import abt_2 from "assets/images/abt_img_2.svg";
export const AboutPage = () => {
  return (
    <section className="about-page-wrapper">
      <div className="row align-items-center">
        <div className="col-md-6 col-12 order-2 order-md-1">
          <div className="inner">
            <div className="sub-title mb-5">Who We Are</div>
            <p className="text-black-000 lh-lg">
              We understand finding Short Term accommodation can be challenging
              at times, that’s why Quickstay was designed to make the process
              easier, from start to finish. We focus predominantly on homes with
              flexibility, convenience, and comfort, whilst always having both
              our guest’s, and our landlord’s best interest at heart. So
              regardless whether you require accommodation for work, holiday, or
              simply circumstantial change, our team are here to help find you
              the perfect home.
            </p>
          </div>
        </div>

        <div className="col-md-6 col-12 order-1 order-md-2 text-end">
          <img className="abt-img" src={abt_img} alt="abt_img" />
        </div>
      </div>

      <div className="row position-relative">
        <div className="col-md-6 col-12 bg-primary-300">
          <div className="inner left">
            <div className="sub-title mb-7">Our Aim</div>
            <p className="text-black-000 lh-lg mb-0">
              Our aim is to have a more hands-on approach, finding the ideal
              accommodation for each individual need. We want to know what our
              guest’s requirements are as well as their deal breakers, to ensure
              the process is as straightforward and efficient as possible!
            </p>
            <br />
            <p className="text-black-000 lh-lg">
              Is finding temporary accommodation proving to be difficult?
              Through bridging the gap between daily bookings and yearly
              contracts, our aim is to make finding temporary accommodation an
              issue of the past.
            </p>
          </div>
        </div>
        <div className="abt_img_2">
          <img src={abt_2} alt="abt_img" />
        </div>

        <div className="col-md-6 col-12 bg-grey-20">
          <div className="inner right">
            <div className="sub-title mb-7">What does QuickStay offer?</div>
            <p className="text-black-000 lh-lg mb-0">
              With several years’ experience Quickstay provides homes which are
              available on a nightly and short term basis. Properties booked on
              a short let basis, e.g weekly or monthly, are typically priced at
              a more fixed and affordable price range. Our aim is to find guests
              the best price for their stay!
            </p>
            <br />
            <p className="text-black-000 lh-lg">
              So if you’re in search of temporary accommodation, such as
              apartments, houses or rooms, feel free to send us an enquiry today
              or email us at info@quickstay.co.uk. Property owners can also
              email info@quickstay.co.uk for help/support with listings and all
              general enquiries.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
