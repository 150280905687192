import React from "react";
import _x32_0_Success from "assets/images/_x32_0_Success.svg";
import loud_image from "assets/images/loud_image.png";
import { Icon } from "@iconify/react";
import "./style.scss";
import useNetworkData from "hooks/useNetworkData";
import { resendCodeRequest } from "services/network/auth";
import { toast } from "react-toastify";

export const RegisterSuccess = ({
  onHide,
   email,
  unverify,
}: {
  onHide: any;
      email: string;
      unverify: any;
}) => {
  const { isLoading, makeRequest } = useNetworkData();
  const resend = async () => {
    const response = await makeRequest({
      payload: { email },
      apiRequest: resendCodeRequest,
    });
    if (response.statusCode === 200) {
      toast.success("Code sent successfully!");
    }
  };

  return (
    <div className="text-center">
      <div className="close-modal" onClick={onHide}>
        <Icon icon="charm:square-cross" />
      </div>
      <img className="success-img" src={unverify && unverify?.vtitle ? loud_image : _x32_0_Success} alt="Success" />
        <h2 className="sub-title mt-5 ">{unverify && unverify?.vtitle ? unverify?.vtitle : 'Account Created Successfully!'}</h2>
      <p className="text-sm mx-auto w-75 my-5">
        {unverify && unverify?.vmessage ? unverify?.vmessage : 'A verification link has been to your email address. Click the link in your email to finish the verification process for your account. If you did not receive the verification email, click on resend email below and we will send it to you.' }
      </p>
      <div className="d-flex justify-content-center click align-items-center mt-5 mb-4 gap-2 text-primary-600">
        <span className="text-lg mb-1">
          <Icon icon="fa-regular:envelope" />
        </span>
        <p className="text-sm m-0 w-fit" onClick={resend}>
          Resend Email{" "}
          {isLoading && (
            <span className="spinner-border spinner-border-sm ms-2 text-primary-600" />
          )}
        </p>
      </div>
      <p className="text-sm mb-6 click w-fit mx-auto" onClick={onHide}>
        Verify Account Later
      </p>
    </div>
  );
};
