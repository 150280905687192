import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "sharedComponents/forms/Button";
import TextInput from "sharedComponents/forms/TextInput";
import useNetworkData from "hooks/useNetworkData";
import Modal from "react-bootstrap/Modal";
import { forgotPasswordRequest } from "services/network/auth";

export const ForgotPasswordModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: any;
}) => {
  const { isLoading, makeRequest } = useNetworkData();
  const [message, setMessage] = useState("");

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().label("Email Address").required(),
  });

  const handleForgot = async (formValues: any) => {
    setMessage("");
    const response = await makeRequest({
      payload: formValues,
      apiRequest: forgotPasswordRequest,
      returnError: false,
    });
    if (response) {
      setMessage(
        "A link has been sent to this email, please click the link to reset your password."
      );
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleForgot}
            validateOnBlur={false}
          >
            {({ handleSubmit, isValid }) => {
              return (
                <Form className="pt-4" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-11 col-12 mx-auto">
                      <div className="close-modal" onClick={handleClose}>
                        <Icon icon="charm:square-cross" />
                      </div>
                      <h2 className="sub-title mt-8 text-center">
                        Forgot Password
                      </h2>
                      <p className="text-sm mx-auto my-5 text-center">
                        Forgot your password? not to worry, we are willing to
                        assist you in getting you back into your account. Kindly
                        enter your registered email address so that we can send
                        your the password reset link.
                      </p>
                      <Field
                        component={TextInput}
                        className="form-control"
                        placeholder="Enter Email Address"
                        type="email"
                        name="email"
                        id="email"
                      />
                      {message && (
                        <p className="text-xs text-info fw-bold">
                          NB:{message}
                        </p>
                      )}
                      <Button
                        title="Send Email"
                        className="btn btn-lg btn-blue-dark mt-5 mb-10 mx-auto"
                        type="submit"
                        disabled={isLoading || !isValid}
                        loading={isLoading}
                        loadingTitle={"Please wait..."}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
