import useNetworkData from "hooks/useNetworkData";
import { getStorageData, StorageKeys } from "lib/storageManager";
import React, { useState } from "react";
import { PropertySchema } from "services/network/_schema";
import Button from "sharedComponents/forms/Button";
import { Editor, EditorState } from "react-draft-wysiwyg";
import "./styles.scss";
import { useContactOwner } from "hooks/useContactOwner";

export const ContactOwner = ({
  property,
  handleShowLogin,
  handleOpenRegister,
}: {
  property: PropertySchema;
  handleShowLogin: () => void;
  handleOpenRegister: () => void;
}) => {
  const token = getStorageData(StorageKeys.token);
  const [editorState, setEditorState] = useState<EditorState | undefined>();
  const { makeRequest, isLoading } = useNetworkData();
  const { messageOwner } = useContactOwner({ makeRequest });

  const submit = () => {
    if (editorState) {
      messageOwner(editorState, property, setEditorState);
    }
  };

  return (
    <section className="contact-wrapper" id="contact">
      {token ? (
        <div className="message-owner">
          <h2 className="sub-title text-center  mb-3">Contact Owner</h2>
          <p className="text-sm text-center mb-5 text-black-500">
            Still have questions? Send in your request and the owner will get in
            touch with you.
          </p>
          <div className="editor">
            <Editor
              editorState={editorState}
              editorClassName="form-control textarea"
              placeholder="Leave a message..."
              toolbarHidden
              onEditorStateChange={setEditorState}
            />
          </div>
          <Button
            title="Send Message"
            loading={isLoading}
            disabled={isLoading || !editorState}
            className="btn btn-md btn-blue-dark w-100 mb-6 mt-4"
            onClick={submit}
          />
        </div>
      ) : (
        <>
          <h2 className="sub-title text-center mb-4">Contact Owner</h2>
          <p className="text-sm text-center mb-6">
            You must be logged in to contact the owner of this property.
          </p>
          <div className="d-flex justify-content-center gap-5">
            <button
              className="btn btn-blue-dark btn-lg"
              id={"login-btn"}
              onClick={() => handleShowLogin()}
            >
              Sign In
            </button>
            <button
              className="btn btn-blue-dark btn-lg"
              onClick={() => handleOpenRegister()}
            >
              Sign Up
            </button>
          </div>
        </>
      )}
    </section>
  );
};
