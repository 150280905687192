import React from "react";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import PropTypes from "prop-types";
import { findCountryByISO2 } from "utilities/country";

export default function PlaceSuggestionWrapper(props) {
  async function handleSelect(address, placeId) {
    try {
      const place = await geocodeByPlaceId(placeId);
      const actualPlace = place[0];
      const latlong = await getLatLng(actualPlace);
      const country = actualPlace.address_components.find(
        (compo) => compo.types[0] === "country"
      );
      const addyLength = actualPlace.address_components.length;
      const countryObject = findCountryByISO2(country.short_name);

      const payload = {
        latitude: latlong.lat,
        longitude: latlong.lng,
        region: address,
        countryISO3: countryObject.iso3,
        countryLanguage: countryObject.language,
        countryCurrency: countryObject.currency,
        country: countryObject.name,
        placeId: actualPlace.place_id,
        street: actualPlace.formatted_address,
        pincode: actualPlace.address_components[addyLength - 1].long_name,
      };
      props.onSelect(payload);
    } catch (e) {
      console.error(e);
    }
  }

  function renderSuggestions(suggestions, props) {
    return suggestions.length ? (
      <div
        className="search-dropdown position-absolute bg-white rounded-lg list-unstyled w-100"
        style={{ border: "solid 1px #d2cfcf", zIndex: "3", top: "43px" }}
      >
        {suggestions.map((suggestion) => (
          <div
            {...props(suggestion)}
            key={suggestion.placeId}
            className="d-flex align-items-center w-100 p-2 border-bottom click"
          >
            <span className="text-dark ml-2">{suggestion.description}</span>
          </div>
        ))}
        <div className="text-right p-2">
          <img
            src={
              "https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
            }
            alt=""
          />
        </div>
      </div>
    ) : null;
  }

  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={props.onChange}
      onSelect={handleSelect}
      googleLogo
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }) => (
        <div
          className="relative d-flex flex-row align-items-center justify-content-center"
          style={{ position: "relative" }}
        >
          <input
            {...getInputProps({
              placeholder: `${props.inputPlaceHolder || "Search by Place or City"}`,
              className: props.inputClassName,
            })}
          />
          {renderSuggestions(suggestions, getSuggestionItemProps)}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

PlaceSuggestionWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  showIcon: PropTypes.bool,
  inputPlaceHolder: PropTypes.string,
};
