import React from "react";
import "./styles.scss";
import failed from "assets/images/failed.png";
import { useNavigate } from "react-router-dom";

export const CheckoutFailed = () => {
  const navigate = useNavigate();

  return (
    <div className="failed-wrapper mt-7 mb-9 text-center">
      <img src={failed} alt="" className="failed-img mb-4" />
      <h3 className="text-primary-600 title">
        Transaction Unsuccessful
      </h3>
      <p className="text-center container my-3 text-lg">
        Sorry, an error occurred while making payment. <br />
        Please try again.
      </p>
      <button
        className="btn btn-primary mx-auto"
        onClick={() => {
          navigate(-2);
        }}
      >
        Go Back
      </button>
    </div>
  );
};
