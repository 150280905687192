import React from "react";
import Carousel from "react-bootstrap/Carousel";
import item1 from "assets/images/banner.png";
import { Icon } from "@iconify/react";
import { StickyNav } from "./stickyNav";
import { PropertySchema } from "services/network/_schema";

export const DetailBanner = ({ property }: { property: PropertySchema }) => {
  const propertyPictures = property?.pictures || [];
  return (
    <>
      <section className="p-details-banner-wrapper">
        <Carousel fade controls={false}>
          {propertyPictures.slice(0, 5).map((item, index) => (
            <Carousel.Item key={index}>
              <div className="carousel-img">
                <img src={item.url} alt="First slide" />
              </div>
              <Carousel.Caption>
                <div className="container">
                  <h1 className="title">
                    {property?.name || "..."} {""}
                    {property?.propertyType || "..."}
                  </h1>
                  <span>
                    <Icon
                      icon="ic:outline-location-on"
                      className="mb-3 text-white text-2xl"
                    />
                  </span>
                  <p>
                    {property?.city || "loading"}, {property?.country || "..."}
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
      <StickyNav />
    </>
  );
};
