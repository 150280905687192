import { Icon } from "@iconify/react";
import React, { useContext, useMemo, useState } from "react";
import { PropertySchema } from "services/network/_schema";
import { currencyObject } from "utilities/country";
import Modal from "react-bootstrap/Modal";
import { LayoutContext } from "context/layoutContext";

export const Overview = ({ property }: { property: PropertySchema }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const [readMore, setReadMore] = useState(false);
  const { rates, currency, getPrice } = useContext(LayoutContext);

  const excerpts = useMemo(() => {
    const count = 900;
    const x = property.description?.split("") || [];
    const y = x?.slice(0, count).join("");
    if (x?.length > count) setReadMore(true);
    return y;
  }, [property.description]);

  return (
    <>
      <h2 className="sub-title text-center my-sm-6 my-4_5" id="overview">
        Overview
      </h2>
      <div className="px-3">
        <div className="row gy-5 ">
          <div className="col-lg-4 col-md-6 col-12 px-4">
            <img
              src={`${process.env.REACT_APP_ASSETS_URL}/${property.featuredImage}`}
              alt={property.name}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-4">
            <p
              className="text-sm text-black lh-lg"
              dangerouslySetInnerHTML={{
                __html: `${excerpts}${readMore ? "..." : ""}`,
              }}
            />
            {readMore && (
              <p
                className="w-fit ms-auto text-primary-600 click"
                onClick={() => setOpenModal(!openModal)}
              >
                Read more <Icon icon="fa6-solid:angle-down" />
              </p>
            )}
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-4">
            <div className="price-overview">
              <p className="sub-title text-lg text-black-000 mb-0">Price</p>
              <h2 className="sub-title">
                {currencyObject[`${rates ? currency : property.currency}`] ||
                  ""}
                {getPrice(property.price, property.currency)} /
                <span className="text-lg">{property.bookingDurations}</span>
              </h2>
              <div className="border-grey-400 mt-5">
                <p className="fw-extra-bold text-sm text-black-500">Bedroom</p>
                <p className="fw-medium text-sm text-black-500">
                  {property.bedroom}
                </p>
              </div>
              <div className="border-grey-400 my-4">
                <p className="fw-extra-bold text-sm text-black-500">Type</p>
                <p className="fw-medium text-sm text-black-500">
                  {property.propertyType}
                </p>
              </div>
              <div className="border-grey-400 my-4">
                <p className="fw-extra-bold text-sm text-black-500">
                  Avalability
                </p>
                <p className="fw-medium text-sm text-black-500">Yes</p>
              </div>
              <div className="border-grey-400 my-4">
                <p className="fw-extra-bold text-sm text-black-500 me-4">
                  Location
                </p>
                <p
                  className="fw-medium text-sm text-black-500"
                  id="no_w_translate"
                >
                  {property.addressLine1}
                </p>
              </div>
              <div className="border-grey-400 my-4">
                <p className="fw-extra-bold text-sm text-black-500">
                  Guest Capacity
                </p>
                <p className="fw-medium text-sm text-black-500">
                  {property.guest}
                </p>
              </div>
              <div className="border-grey-400 my-4">
                <p className="fw-extra-bold text-sm text-black-500">WIFI</p>
                <p className="fw-medium text-sm text-black-500">Yes</p>
              </div>
              <div className="border-grey-400 my-4">
                <p className="fw-extra-bold text-sm text-black-500">Bathroom</p>
                <p className="fw-medium text-sm text-black-500">
                  {property.bathroom}
                </p>
              </div>
              <div className="d-flex justify-content-between my-4">
                <p className="fw-extra-bold text-sm text-black-500">Sofa Bed</p>
                <p className="fw-medium text-sm text-black-500">
                  {property.sofaBedAvailable ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={openModal} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <div
            className="p-md-6 p-5 overflow-scroll"
            style={{ maxHeight: "57rem" }}
          >
            <div className="close-modal" onClick={handleClose}>
              <Icon icon="charm:square-cross" />
            </div>
            <h2 className="sub-title my-5 text-center">Overview Description</h2>
            <p
              className="text-base text-black-500 lh-lg"
              dangerouslySetInnerHTML={{
                __html: (property as any).description,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
