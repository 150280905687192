import { Icon } from "@iconify/react";
import React from "react";
import { AmenityCategoryList } from "services/network/_schema";

export const Amenities = ({ amenities }: { amenities: [] }) => {
  const isFound = (cat: string) => {
    const found = amenities.find((am: any) => am.amenityCategory === cat);
    return found ? true : false;
  };
  if (amenities.length < 1) {
    return null;
  }
  return (
    <div className="container pt-sm-6 pt-5 pb-sm-6 pb-0" id="amenities">
      <h2 className="sub-title text-center mb-sm-6 mb-4_5">Amenities</h2>
      {AmenityCategoryList.filter((list) => isFound(list[1])).map((list) => (
        <div key={list[0]} className="mb-5">
          <div className="amenities-cat">
            <p className=" sub-title text-xl text-white-000 mb-0">{list[1]}</p>
          </div>
          <div className="amenities-grid ms-5">
            {amenities
              .filter((am: any) => am.amenityCategory === list[1])
              .map((am: any) => (
                <div
                  className="d-flex gap-2 align-items-center text-primary-600"
                  key={am.id}
                >
                  <div>
                    {am.url ? (
                      <img src={am.url} alt="" />
                    ) : (
                      <Icon
                        icon="emojione-v1:heavy-check-mark"
                        className="text-lg"
                      />
                    )}
                  </div>
                  <p className="text-sm m-0">{am.name}</p>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};
