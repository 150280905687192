import React, { useEffect, useState } from "react";
import { PropertyCard } from "sharedComponents/propertyCard";
import { PropertyMap } from "./property-map";
import "./styles.scss";
import useNetworkData from "hooks/useNetworkData";
import { getAllListingsRequest, getPaginatedListingsRequest } from "services/network/properties";
import { PropertySchema } from "services/network/_schema";
import { EmptyContainer } from "sharedComponents/EmptyContainer";
import Loader from "sharedComponents/Loader";
import { StayType } from "services/network/_schema";
import { useQuery } from "utilities";
import { useParams } from "react-router-dom";

export const ShortStays = () => {
  const { makeRequest } = useNetworkData();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingSilently, setLoadingSilently] = useState(false);
  const [allProps, setAllProps] = useState<PropertySchema[]>([]);
  const [pagingInfo, setPagingInfo] = useState({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
  });
   const { type } = useParams()
   
   // const capitalized = 

  const [hasMore, setHasMore] = useState(true);

  const getListings = async () => {
    if (!loadingSilently) {
      pagingInfo.currentPage === 1 && setIsLoading(true);
      setLoadingSilently(true);
      const res = await makeRequest({
        payload: {
          limit: pagingInfo.itemsPerPage,
            page: pagingInfo.currentPage,
          type:type ? type.charAt(0).toUpperCase() + type.slice(1) : 'Weekly' || ""
        },
        apiRequest: getPaginatedListingsRequest,
      });
      setIsLoading(false);
       if (res.statusCode === 200) {
         setLoadingSilently(false);
         // setAllProps(res.data)
      //   setAllProps(
      //     res.data.filter(
      //       (prop: PropertySchema) => prop.stayType === StayType.Weekly
      //     )
      //   );
        

        // if (pagingInfo.currentPage === 1) {
        //   setAllProps(res.data);
        //   setLoadingSilently(false);
        // } else {
        //   setAllProps((prev) => [...prev, ...res.data]);
        //   setLoadingSilently(false);
        // }

        // if (pagingInfo.currentPage >= 50) {
        //   setHasMore(false);
        // }

        /** **** Uncomment below when API is paginated *****/

          const { meta, items } = res.data;
        setPagingInfo(meta);
        if(pagingInfo.currentPage === 1){
          setAllProps(items);
         //   setLoadingSilently(false);
        }else{
          setAllProps((prev) => [...prev, ...items]);
         //   setLoadingSilently(false);
        }
        if (pagingInfo.currentPage >= pagingInfo.totalPages) {
          setHasMore(false);
        }
          /** **** Uncomment when API is paginated *****/
          
      }
    }
  };

  useEffect(() => {
    getListings();
  }, [type]);

  // const onScroll = () => {
  //   if (loadingSilently === false) {
  //     const scrollTop = document.documentElement.scrollTop;
  //     const scrollHeight = document.documentElement.scrollHeight;
  //     const clientHeight = document.documentElement.clientHeight;

  //     if (hasMore && scrollTop + clientHeight >= scrollHeight - 900) {
  //       setPagingInfo((prev) => ({
  //         ...prev,
  //         currentPage: prev.currentPage + 1,
  //       }));
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [allProps]);

  return (
    <div className="AllProperties mb-6">
      <PropertyMap />
      <div className="container">
           <h2 className="sub-title text-center my-5">{ type ? type.charAt(0).toUpperCase() + type.slice(1) : "Weekly"} Stay</h2>
        {isLoading ? (
          <div className="py-10">
            <Loader title="Fetching..." />
          </div>
        ) : (
          <>
            {allProps.length > 0 ? (
              <div className="row gy-5">
                {allProps.map((item: PropertySchema) => (
                  <div className="col-lg-4 col-md-6 col-sm-12" key={item.id}>
                    <PropertyCard
                      showViewListing={true}
                      property={item}
                      showFavorite={true}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <EmptyContainer title={"There are no listing yet"} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
