import useNetworkData from "hooks/useNetworkData";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllListingsRequest, getAllListingsRequest2 } from "services/network/properties";
import { PropertySchema } from "services/network/_schema";
import { PropertyCard } from "sharedComponents/propertyCard";

export const Featured = () => {
  const { makeRequest, response } = useNetworkData();

  const getListings = async () => {
     await makeRequest({
      payload: {
         featured:'yes'
       },
      apiRequest: getAllListingsRequest2,
    });
  };

  useEffect(() => {
    getListings();
  }, []);

  return (
    <section className="featured-wrapper">
      <div className="container">
        {response && (
          <>
            {response.data.length > 0 && (
              <>
                <h2 className="sub-title text-center mb-sm-6 mb-5">
                  Featured Properties
                </h2>
                <div className="row gy-5">
                  {response.data.slice(0, 3).map((item: PropertySchema) => (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={item.id}>
                      <PropertyCard
                        showViewListing={true}
                        property={item}
                        showFavorite={true}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
        <Link to={"/properties"} className="text-decoration-none">
          <button className="btn btn-md text-primary-600 btn-primary mx-auto my-md-6 my-5">
            View More
          </button>
        </Link>
      </div>
    </section>
  );
};
