import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { Icon } from "@iconify/react";
import logo from "assets/images/logo-white.png";
import logoDark from "assets/images/logo.png";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import { CustomDropdownToggle } from "sharedComponents/CustomDropdownToggle";
import getCountryDetail from "@treasurechic/country-detail/lib";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { logoutService } from "services/network/auth";
import { AuthContainer } from "sharedComponents/authContainer";
import {
  currencyArray,
  currencyObject,
  localeToFlags,
} from "utilities/country";
import { LayoutContext } from "context/layoutContext";
import {
  AvailableLanguageList,
  NotificationSchema,
} from "services/network/_schema";

export const Navbar = ({
  main,
  isHome,
}: {
  main?: boolean;
  isHome?: boolean;
}) => {
  const [user, setUser] = useState(getStorageData(StorageKeys.user));
  const [showLogin, setShowLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [changeColor, setChangeColor] = useState<string | "">("");
  const {
    currency,
    setCurrency,
    language,
    setLanguage,
    unreadNotifications,
  }: {
    currency: string;
    setCurrency: Function;
    language: string;
    setLanguage: Function;
    unreadNotifications: NotificationSchema[];
  } = useContext(LayoutContext);

  const handleShowLogin = () => {
    if (!user) {
      setShowLogin(true);
    }
  };
  const handleOpenRegister = () => {
    if (!user) {
      setOpenRegister(true);
    }
  };

  const updateUser = () => {
    setUser(getStorageData(StorageKeys.user));
  };

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setChangeColor("bg-blue");
    } else {
      setChangeColor("");
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
    window.addEventListener("storage", updateUser);
    return () => window.removeEventListener("storage", updateUser);
  }, []);

  return (
    <nav
      className={`${
        main ? "bg-blue" : isHome ? changeColor : "bg-white"
      } nav-fixed`}
    >
      <div className="container navbar d-flex justify-content-between align-items-center">
        <Link to="/">
          <img
            src={main || isHome ? logo : logoDark}
            alt="Quickstay_logo"
            className="nav-logo"
          />
        </Link>

        <div
          className={
            main || isHome
              ? "navbar-items ms-auto"
              : "navbar-items dark ms-auto"
          }
        >
          <Link to={"/download-app"} className="text-decoration-none">
            {/* <button
              className="btn btn-outline desktop-item get-app-btn"
              type="button"
              id="no_w_translate" style={{padding:'6px', minWidth:'10rem', minHeight:'1.5rem'}}
            > */}
            <button
              className="btn btn-outline desktop-item get-app-btn"
              type="button"
              id="no_w_translate"
              style={{ padding: "7px" }}
            >
              <Icon icon="clarity:mobile-phone-line" className="me-2 text-xl" />

              <span className="get-app-btn-text">Download App</span>
            </button>
          </Link>
          {user && (
            <div>
              <Link
                to="/dashboard/notifications"
                className="text-decoration-none position-relative"
              >
                <Icon
                  icon="gala:bell"
                  className="text-xl click nav-item d-nil"
                />
                {unreadNotifications.length > 0 ? (
                  <span className="count">
                    {unreadNotifications.length > 9
                      ? "9+"
                      : unreadNotifications.length}
                  </span>
                ) : null}
              </Link>
            </div>
          )}
          {/* <Link className="nav-item desktop-item" to="/short-stays">
          Stay option
              </Link> */}
          <Dropdown className="desktop-item">
            <Dropdown.Toggle
              as={CustomDropdownToggle}
              className="nav-item"
              id="dropdown-toggle no_w_translate"
            >
              {/* <Icon icon="bx:user-circle" className="me-2 text-sm" /> */}
              Stay Options
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-grey-30">
              <NavDropdown.Item
                as={Link}
                to="/stay-options/weekly"
                className="click"
              >
                Weekly
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/stay-options/monthly"
                className="click"
              >
                Monthly
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/stay-options/corporate"
                className="click"
              >
                Corporate
              </NavDropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {!user ? (
            // <div
            //   className="nav-item desktop-item click"
            //   onClick={() => handleShowLogin()}
            // >
            //         Sign In
            //         {/* <span style={{ fontSize: '1.7rem' }}><Icon icon="bx:chevron-down" className="me-2" /></span> */}
            //      </div>

            <Dropdown className="desktop-item">
              <Dropdown.Toggle
                as={CustomDropdownToggle}
                className="nav-item"
                id="dropdown-toggle no_w_translate"
              >
                {/* <Icon icon="bx:user-circle" className="me-2 text-sm" /> */}
                Sign In
              </Dropdown.Toggle>

              <Dropdown.Menu className="bg-grey-30">
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className="click"
                  onClick={() => handleShowLogin()}
                >
                  Sign In
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className="click"
                  onClick={() => handleOpenRegister()}
                >
                  Sign Up
                </NavDropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown className="desktop-item">
              <Dropdown.Toggle
                as={CustomDropdownToggle}
                className="nav-item"
                id="dropdown-toggle no_w_translate"
              >
                <Icon icon="bx:user-circle" className="me-2 text-sm" />
                {user.firstName}
              </Dropdown.Toggle>

              <Dropdown.Menu className="bg-grey-30">
                <NavDropdown.Item
                  as={Link}
                  to="/dashboard/profile"
                  className="click"
                >
                  <Icon icon="bx:user-circle" className="me-3" />
                  My Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/dashboard/my-bookings"
                  className="click"
                >
                  <Icon icon="emojione-monotone:fax-machine" className="me-3" />
                  Bookings
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/dashboard/my-bookings/hospitality"
                  className="click"
                >
                  <Icon
                    icon="material-symbols:shield-with-house-outline"
                    className="me-3"
                  />
                  Hospitality
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/dashboard/messages"
                  className="click"
                >
                  <Icon icon="eva:message-square-outline" className="me-3" />
                  Messages
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/dashboard/listings"
                  className="click"
                >
                  <Icon icon="clarity:house-line" className="me-3" />
                  My Property listings
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/dashboard/payout"
                  className="click"
                >
                  <Icon
                    icon="icon-park-outline:bank-card-one"
                    className="me-3"
                  />
                  Payment & Payouts
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/wishlist" className="click">
                  <Icon icon="el:heart-empty" className="me-3" />
                  Wishlist
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/dashboard/support"
                  className="click"
                >
                  <Icon icon="ic:outline-contact-support" className="me-3" />
                  Chat with an agent
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="click text-error"
                  onClick={() => logoutService(true)}
                >
                  <Icon icon="bx:power-off" className="me-3" />
                  Logout
                </NavDropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}

          <Dropdown className="d-nil" autoClose="outside">
            <Dropdown.Toggle
              as={CustomDropdownToggle}
              className="nav-item"
              id="dropdown-toggle"
            >
              {currency} ({currencyObject[currency]})
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-grey-30">
              {currencyArray.map((cur: any) => (
                <NavDropdown.Item
                  className="click"
                  key={cur}
                  onClick={() => {
                    setCurrency(cur);
                  }}
                >
                  {cur} ({currencyObject[cur]})
                </NavDropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <Dropdown autoClose="outside" id="no_w_translate">
            <Dropdown.Toggle
              as={CustomDropdownToggle}
              className="nav-item text-uppercase"
              id="dropdown-toggle"
            >
              <img
                src={getCountryDetail(localeToFlags[language]).flag || ""}
                alt="GB"
                className="me-2 flag"
              />
              {language}
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-grey-30">
              {AvailableLanguageList.map((lang) => (
                <NavDropdown.Item
                  key={lang[1]}
                  className="click text-uppercase"
                  onClick={() => {
                    setLanguage(lang[1]);
                  }}
                >
                  <img
                    src={getCountryDetail(localeToFlags[lang[1]]).flag || ""}
                    alt={lang[1]}
                    className="me-2 flag"
                  />
                  {lang[1]}
                </NavDropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
          <Link
            to="/get-approved"
            // to={user ? "/get-approved" : "/dashboard/listings/add"}
            className="text-decoration-none desktop-item"
          >
            <button
              className="btn btn-outline desktop-item get-app-btn"
              type="button"
              style={{ padding: "7px" }}
            >
              <span className="get-app-btn-text"> Get Approved</span>
            </button>
          </Link>
        </div>
      </div>
      <AuthContainer
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        handleShowLogin={handleShowLogin}
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
        handleOpenRegister={handleOpenRegister}
      />
    </nav>
  );
};
