import React, { useContext } from "react";
import _x32_0_Success from "assets/images/_x32_0_Success.svg";
import "./styles.scss";
import logo from "assets/images/logo.png";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useQuery } from "utilities";
import { DateTime } from "luxon";
import trophy from "assets/images/trophy.png";
import { LayoutContext } from "context/layoutContext";
import { currencyObject } from "utilities/country";

export const CheckoutSuccess = () => {
  const params = useQuery();
  const { rates, currency, getPrice } = useContext(LayoutContext);

  const printReceipt = () => {
    window.print();
  };
  return (
    <div className="success-wrapper">
      {/* <div className="container">
        <img className="logo-img" src={logo} alt="logo" />
      </div> */}
      <div className=" container mt-6 mb-2">
        <div className="text-center">
          <img className="checker-img " src={_x32_0_Success} alt="Success" />
        </div>
        <h2 className="sub-title mt-2 text-center mobile-text">
          Your booking is complete!
        </h2>
        <p className="text-sm mx-auto my-5 mobile-text">
          Your reservation is confirmed and you can view all your booking
          details at any time on the QuickStay app or my bookings section in
          your account. Your booking details have also been sent to the
          registered email address on your account.
        </p>
      </div>
      <hr />
      <div className="container my-2">
        <h2 className="sub-title mt-2">Booking Details</h2>
      </div>
      <hr />
      <div className="container mb-4">
        <div className="row gy-5 justify-content-between align-items-center ">
          <div className="col-md-4 col-sm-8 col-7 mobile-text-size">
            <h2 className="xxsub-title text-xxl mt-3 text-black-300">
              {params.get("n")}
            </h2>
            <div className="d-flex align-items-center text-black-300 mt-md-4 mt-2">
              <span>
                <Icon className="text-xl me-3" icon="ci:location-outline" />
              </span>
              <p className="text-sm mb-0">
                {params.get("l")}, {params.get("country")}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-4 col-5 mobile-text-size">
            <div className="d-flex gap-4 align-items-center">
              <div>
                <Icon
                  className="text-lg mb-3"
                  icon="simple-line-icons:calender"
                />
              </div>
              <div>
                <div className=" text-sm  d-flex justify-content-between flex-wrap mb-md-3 mb-0  align-items-center">
                  <p className="mb-0">Check In:</p>
                  <p className="mb-0">
                    {params.get("cIn")
                      ? DateTime.fromJSDate(
                          new Date((params as any).get("cIn"))
                        ).toFormat("dd/M/yyyy")
                      : "---"}
                  </p>
                </div>
                <div className=" text-sm  d-flex justify-content-between  flex-wrap mb-md-3 mb-0 align-items-center">
                  <p className="mb-0">Check Out:</p>
                  <p className="mb-0">
                    {params.get("cOut")
                      ? DateTime.fromJSDate(
                          new Date((params as any).get("cOut"))
                        ).toFormat("dd/M/yyyy")
                      : "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1 col-7">
            <h2 className=" fw-regular xsub-title text-xxl text-black-300">
              {currencyObject[rates ? currency : params.get("cur")]}
              {getPrice(params.get("p"), params.get("cur"))}
            </h2>
          </div>
          <div className="col-lg-2 col-md-3 col-4">
            <button
              className="btn btn-lg btn-primary no-print mobile-btn"
              onClick={printReceipt}
            >
              <Icon icon="bi:printer" className="me-3" />
              <p className="text-xs mb-0">Print Receipt</p>
            </button>
          </div>
        </div>
        <div className="restaurant-win my-5 mx-4">
          <div className="img-trophy">
            <img src={trophy} alt="" />
          </div>
          <div className="venue-width">
            <h3 className="xxsub-title text-white-000">
              You can now add hospitality to your stay!
            </h3>
            <p>
              Enjoy access to preferred pricing and exclusive discounts <br />{" "}
              at local and expertly recommended venues!
            </p>
            <Link
              className="text-decoration-none"
              to={`/choose-restaurant/${params.get("pid")}/${params.get(
                "ref"
              )}`}
            >
              <button className="btn btn-primary btn-lg mx-auto my-4_5 text-sm mobile-btn">
                Choose A Venue
              </button>
            </Link>
            <p>Get the code via email and is valid for 8months</p>
          </div>
        </div>
        <div className="d-flex justify-content-center my-md-5 my-1">
          <Link className="text-decoration-none no-print" to="/">
            <button className="btn btn-lg btn-primary mobile-btn">
              Back Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
