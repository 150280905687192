import React from "react";
import date from "assets/images/date.svg";
import payment from "assets/images/payment.svg";
import search from "assets/images/search.svg";

export const HowItWorks = () => {
  return (
    <section className="howitworks-wrapper">
      <div className="container">
        <h2 className="sub-title text-center my-5 mb-5_6 d-none d-sm-block">
          Diverse stays with one thing in common, approved by experts
        </h2>
        <h2 className="sub-title text-center my-5 mb-5_6 d-sm-none d-block">
          Diverse stays, approved by experts 
        </h2>
        <div className="grid-flex my-3">
          <div className="cardd ms-5 ms-md-0">
            <div className="item-card">
              <div>
                <img
                  className="hiw-icon-container"
                  src={search}
                  alt="search-icon"
                  style={{ transform: "rotate(90deg)" }}
                />
                <p className="text-sm my-5">
                  Browse quality-assured homes and hotels approved by our
                  experts for your <br /> peace of mind
                </p>
                <p className="number">1</p>
              </div>
            </div>
          </div>
          <div className="cardd">
            <div className="item-card">
              <div>
                <img
                  className=" hiw-icon-container"
                  src={date}
                  alt="date-icon"
                />
                <p className="text-sm my-5">
                  Check availability for your length of stay and get ready to
                  book seamlessly,
                  <br /> on your terms!
                </p>
                <p className="number">2</p>
              </div>
            </div>
          </div>
          <div className="cardd">
            <div className="item-card">
              <div>
                <img
                  className="hiw-icon-container"
                  src={payment}
                  alt="payment-icon"
                />
                <p className="text-sm my-5">
                  Follow our simple booking process and unlock premium venues
                  and exclusive prices <br /> to add to your stay!
                </p>
                <p className="number">3</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
