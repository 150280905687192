import { Icon } from "@iconify/react";
import React from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "sharedComponents/forms/Button";
import TextInput from "sharedComponents/forms/TextInput";
import useNetworkData from "hooks/useNetworkData";
import Modal from "react-bootstrap/Modal";
import { resetPasswordRequest } from "services/network/auth";
import { useQuery } from "utilities";
import { toast } from "react-toastify";

export const ResetPasswordModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: any;
}) => {
  const { isLoading, makeRequest } = useNetworkData();
  const params = useQuery();

  const initialValues = {
    email: params.get("email") || "",
    code: params.get("code") || "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email().label("Email Address").required(),
    code: yup.string().label("Code").required(),
    password: yup
      .string()
      .label("Password")
      .min(6)
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, " ")
      .required(),
    confirmPassword: yup
      .string()
      .label("Confirm Password")
      .oneOf([null, yup.ref("password")], "Both passwords need to be the same")
      .required(),
  });

  const handleReset = async (formValues: any) => {
    const response = await makeRequest({
      payload: formValues,
      apiRequest: resetPasswordRequest,
      returnError: false,
    });
    if (response) {
      toast.success(`Password Reset Successful!`);
      window.location.replace("/?login=true");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleReset}
            validateOnBlur={false}
          >
            {({ handleSubmit, isValid, errors }) => {
              return (
                <Form className="p-4" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-11 col-12 mx-auto">
                      <div className="close-modal" onClick={handleClose}>
                        <Icon icon="charm:square-cross" />
                      </div>
                      <h2 className="sub-title mt-8 text-center">
                        Reset Password
                      </h2>
                      <p className="text-sm mt-5 mb-1 text-center">
                        Your new password must be different from the previously
                        used password.
                      </p>
                      {/* {!params.get("email") && (
                        <Field
                          component={TextInput}
                          type="email"
                          className="form-control mb-4"
                          placeholder="Enter Email"
                          name="email"
                          id="email"
                        />
                      )}
                      {!params.get("code") && (
                        <Field
                          component={TextInput}
                          type="password"
                          className="form-control mb-4"
                          placeholder="Enter Code"
                          name="code"
                          id="code"
                        />
                      )} */}

                      <Field
                        component={TextInput}
                        type="password"
                        className="form-control mt-4"
                        placeholder="Enter Password"
                        name="password"
                        id="password"
                      />
                      {errors.password && (
                        <p className="text-xs fw-medium text-danger">
                          Password must be at least 6 characters in length and
                          must contain at least 1 lowercase letter, 1 uppercase
                          letter and 1 number
                        </p>
                      )}
                      <Field
                        component={TextInput}
                        type="password"
                        className="form-control my-4"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        id="confirmPassword"
                      />
                      <Button
                        title="Reset"
                        className="btn btn-lg btn-blue-dark mt-5 mb-10 mx-auto"
                        type="submit"
                        disabled={isLoading || !isValid}
                        loading={isLoading}
                        loadingTitle={"Please wait..."}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
