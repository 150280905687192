import React from "react";
import "./styles.scss";
import Skeleton from "react-skeleton-loader";

export default function Loader({ title }: { title: string }) {
  return (
    <div className="loader-component">
      <div className="loading">
        <h2 className="loader-title">{title}</h2>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

Loader.defaultProps = {
  title: "",
};

export const TableLoading = ({
  count,
  row,
}: {
  count: number;
  row?: number;
}) => {
  return (
    <div>
      <table className="table table-hoverable text-nowrap">
        <tbody>
          {[...Array(row || 10)].map((e, index) => (
            <tr key={index}>
              {[...Array(count)].map((value, index) => (
                <td key={index}>
                  <Skeleton color="#ced4da" width="100%" height="25px" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
