import React from "react";
import "./styles.scss";

export const PrivacyPage = () => {
  return (
    <section className="container mb-9">
      <h2 className="sub-title my-6">Privacy Policy</h2>
      <p>
        This privacy policy describes the principles and practices that apply to
        personal information collected by QuickStay. From users ("you") to our
        site and our services, we collect and process information provided
        through the website, in email communication, in telephone communication,
        or during any other use of the services between us and our users.The
        information we collect will be information capable of identifying you as
        an individual.
        <br />
        <br />
        By using the QuickStay services you agree to our use of your personal
        data in accordance with this policy. If you do not agree with our
        privacy policy you can not use the QuickStay website or services and
        should immediately refrain from continuing with the use of the platform
        and any and all of our services.
        <br />
        <br />
        We understand that making a booking online requires a considerable
        amount of trust, therefore we value and take this trust very seriously.
        We will take responsible steps to protect the security of your personal
        information and make it our highest priority to safeguard the
        confidentiality of any personal details provided to us through the
        website and our services.
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">
        Personal information collected
      </h3>
      <p>
        We collect information such as your name, email address and password
        details, and when and where you sign up to create an account. When
        creating your QuickStay account you may be asked for additional
        information such as your address, gender, date of birth, telephone
        number or mobile number, which we will also store to your account for
        your convenience. We will also collect information from any booking made
        on the site or through the service.
        <br />
        <br />
        We may collect information such as payment information including your
        credit or debit card number, cardholders name and expiration date.
        Information about whether you are employed or studying will also be
        collected, and we will also collect and store any identification
        verification provided by users.
        <br />
        <br />
        We may also collect information about how you use our site, including
        your searches, the pages and properties you look at and or wish to rent
        and collect any information of correspondence made with an owner. We
        will also collect data relating to post accommodation bookings or
        searches.
        <br />
        <br />
        We may automatically collect certain information passively as a result
        of your use of the site and services. This may include the dates you
        search for, the properties you look at and the pages you visit.
        <br />
        <br />
        We may also keep a record of technical information such as your Uniform
        Resource Locations, the length of visit to certain pages, and the
        Internet Protocol address.
        <br />
        <br />
        We may also collect your login information (as stated above).
        <br />
        <br />
        There are certain areas of the site you may view without the necessary
        input of personal information, such as viewing or searching for a
        listing, reading about the QuickStay services or accessing the home page
        or our terms.
        <br />
        <br />
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">
        Who we share your information with
      </h3>
      <p>
        QuickStay will share your information to, however not in limitation of:
        <br />
        <br />
        Property owners or property managers, especially in any and all cases
        where a booking has been made. Payment providers, such as the gateways,
        World Pay or Pay Pal in order to facilitate a payment or a refund and
        assist any checks required to complete a payment or refund. Business
        partners and other third party vendors or companies within our corporate
        family.
        <br />
        <br />* In certain circumstances, at our sole desecration, we may choose
        to share information for legal related reasons or for the courts
        purposes.
      </p>
      <h3 className="xsub-title text-lg text-uppercase">Cookies</h3>
      <p>
        <b className="fw-bold">What are cookies?</b><br />
        Cookies are tiny files designed to carry client information specifically
        to their interests and relevant searches. Cookies enable websites to
        gather information unique to individual needs and interests of users.
        The QuickStay website currently uses cookies to allow users interaction
        with the site, however we do not currently use any tracking cookies on
        our website.
        <br />
        <br />* Please be advised that we reserve the right to change or amend
        our privacy policy at any time. It is users responsibility to check the
        terms and conditions and privacy policy and cookies policy regularly to
        keep informed of any changes.
      </p>
    </section>
  );
};
