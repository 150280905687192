import React, { useEffect, useState } from "react";
import { LoginModal } from "pages/guest/loginModal";
import { RegisterModal } from "pages/guest/registerModal";
import { ForgotPasswordModal } from "pages/guest/forgotPasswordModal";
import { ResetPasswordModal } from "pages/guest/resetPasswordModal";
import { VerifySuccess } from "pages/guest/registerModal/verifySuccess";
import { useSearchParams } from "react-router-dom";

const WrappedComponent = ({
  showLogin,
  setShowLogin,
  handleShowLogin,
  handleOpenRegister,
  openRegister,
  setOpenRegister,
}: {
  showLogin: boolean;
  setShowLogin: any;
  handleShowLogin: Function;
  handleOpenRegister?: Function;
  openRegister?: boolean;
  setOpenRegister?: Function;
}) => {
  const [showRegister, setShowRegister] = useState(false);
  const [showFP, setShowFP] = useState(false);
  const [showRP, setShowRP] = useState(false);
  const [showVerified, setShowVerified] = useState(false);

  const handleCloseRegister = () => {
    if (openRegister && setOpenRegister) {
      setOpenRegister(false);
      return;
    }
    setShowRegister(false);
  };
  const handleShowRegister = () => {
    if (openRegister && handleOpenRegister) {
      handleOpenRegister();
      return;
    }
    setShowLogin(false);
    setShowRegister(true);
  };

  const handleCloseFP = () => {
    setShowFP(false);
  };

  const handleShowFP = () => {
    setShowLogin(false);
    setShowRegister(false);
    setShowFP(true);
  };

  const handleCloseRP = () => {
    setShowRP(false);
  };

  const handleShowRP = () => {
    setShowFP(false);
    setShowRP(true);
  };
  const handleCloseLogin = () => {
    setShowVerified(false);
    setShowLogin(false);
  };
  const handleCloseVerified = () => setShowVerified(false);
  const handleShowVerified = () => setShowVerified(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("verified") === "true") {
      handleShowVerified();
      searchParams.delete("verified");
      setSearchParams(searchParams);
    }
    if (searchParams.get("reset") === "true") {
      handleShowRP();
      searchParams.delete("reset");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const openLogin = () => {
    handleCloseVerified();
    if (openRegister && setOpenRegister) {
      setOpenRegister();
    } else {
      setShowRegister(false);
    }
    handleShowLogin();
  };

  return (
    <>
      <LoginModal
        show={showLogin}
        handleClose={handleCloseLogin}
        openFP={handleShowFP}
        openRegister={handleShowRegister}
      />
      <RegisterModal
        show={openRegister ? openRegister : showRegister}
        handleClose={handleCloseRegister}
        openLogin={openLogin}
      />
      <ForgotPasswordModal show={showFP} handleClose={handleCloseFP} />
      <ResetPasswordModal show={showRP} handleClose={handleCloseRP} />
      <VerifySuccess
        show={showVerified}
        handleClose={handleCloseVerified}
        openLogin={openLogin}
      />
    </>
  );
};

export const AuthContainer = React.memo(WrappedComponent);
