import React from "react";
import { SearchProperty } from "sharedComponents/searchProperty";
import { LocationMap } from "sharedComponents/locationMap";

const defaultLocation = {
  lng: -0.1275862,
  lat: 51.50721784,
};
export const PropertyMap = ({ lng, lat }: { lng?: number; lat?: number }) => {
  return (
    <>
      <section className="property-map">
        <LocationMap
          lng={lng || defaultLocation.lng}
          lat={lat || defaultLocation.lat}
        />
      </section>
      <SearchProperty
        containerStyles={{
          // top: "-8vh",
          bottom: "-2vh",
        }}
        isPropertyMap={true}
      />
    </>
  );
};
