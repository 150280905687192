import { Icon } from "@iconify/react";
import React, {
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { CustomDatePicker } from "sharedComponents/forms/dateRangePicker/datePicker";
import "./styles.scss";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import TextInput from "sharedComponents/forms/TextInput";
import PlaceSuggestionWrapper from "sharedComponents/placeSuggestionWrapper";
import { locationSchema } from "pages/authenticated/myListings/add/step2";
import { useNavigate } from "react-router-dom";
import { FilterProperty } from "./filterProperty";
import Accordion from "react-bootstrap/Accordion";
import { useQuery } from "utilities";

export const SearchProperty = ({
  containerStyles,
  isPropertyMap,
}: {
  containerStyles?: object;
  isPropertyMap?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const params = useQuery();
  const [show, setShow] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const handleShow = () => setShow(true);
  const [initialValues, setInitialValues] = useState({
    latitude: "",
    longitude: "",
    guest: "",
    checkIn: "",
    checkOut: "",
    region: "",
  });

  const updateInitialValues = () => {
    const checkIn = params.get("start_date_val");
    const checkOut = params.get("end_date_val");
    const region = params.get("region") || "";
    setInitialValues({
      checkIn: checkIn ? (new Date(checkIn) as any) : "",
      checkOut: checkOut ? (new Date(checkOut) as any) : "",
      latitude: params.get("loc_lat") || "",
      longitude: params.get("loc_lng") || "",
      region,
      guest: params.get("guest") || "",
    });
    setSearchQuery(region);
  };

  useEffect(() => {
    updateInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object().shape({
    guest: yup.number().positive("Must be a valid number").label("Guest"),
  });

  const handleSearch = (formValues: any) => {
    const { latitude, longitude, guest, checkIn, checkOut, region } =
      formValues;
    const price1 = Number(params.get("price1") || "20");
    const price2 = Number(params.get("price2") || "4500");
    const preference = params.get("preference") || "";
    const duration = params.get("duration") || "";
    const accesibility = params.get("accesibility") || "";
    const bookingOption = params.get("bookingOption") || "";
    const stayType = params.get("stayType") || "";
    const bath1 = Number(params.get("bath1")) || "";
    const bath2 = Number(params.get("bath2")) || "";
    const bed1 = Number(params.get("bed1")) || "";
    const bed2 = Number(params.get("bed2")) || "";
    const popularSearch = params.get("popularSearch") || "";
    const priceSort = params.get("priceSort") || "";
    const amenity = params.get("amenity") || "";
    const propertyType = params.get("propertyType") || "";
    const additionalService = params.get("additionalService") || "";

    navigate(
      `/search?loc_lat=${latitude}&loc_lng=${longitude}&region=${region}&guest=${guest}&start_date_val=${checkIn}&end_date_val=${checkOut}&priceSort=${priceSort}&popularSearch=${popularSearch}&bed1=${bed1}&bed2=${bed2}&bath1=${bath1}&bath2=${bath2}&price1=${price1}&price2=${price2}&amenity=${amenity}&additionalService=${additionalService}&bookingOption=${bookingOption}&propertyType=${propertyType}&stayType=${stayType}&accesibility=${accesibility}&duration=${duration}&preference=${preference}`
    );
  };

  const onOpenAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <section className="container">
      <div className="position-relative ">
        <div className="search-wrapper" style={containerStyles}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSearch}
            validateOnBlur={false}
            enableReinitialize
          >
            {({ handleSubmit, isValid, values, setFieldValue }) => {
              return (
                <>
                  <Accordion className="d-md-none d-block">
                    <Accordion.Item
                      eventKey="0"
                      className={`${isPropertyMap ? "bgMap" : ""}`}
                    >
                      <Accordion.Header
                        className={`d-flex justify-content-center align-items-center ${
                          isPropertyMap ? "changeOpacity" : ""
                        }`}
                      >
                        <Icon
                          className="me-4 fw-semi-bold"
                          icon="fluent:search-12-filled"
                          rotate={1}
                          width="24"
                        />
                        <p className="mb-2 fw-semi-bold">Search a Property</p>
                        {isAccordionOpen && (
                          <button
                            className="mobile-filter ms-auto"
                            type="button"
                            onClick={(e) => {
                              handleShow();
                              e.stopPropagation();
                            }}
                          >
                            <Icon
                              className="icon"
                              icon="basil:settings-adjust-outline"
                              width={"25"}
                              rotate={1}
                            />
                          </button>
                        )}
                      </Accordion.Header>
                      <Accordion.Body
                        onEntering={onOpenAccordion}
                        onExiting={onOpenAccordion}
                      >
                        <SearchForm
                          handleSubmit={handleSubmit}
                          setSearchQuery={setSearchQuery}
                          setFieldValue={setFieldValue}
                          searchQuery={searchQuery}
                          values={values}
                          isValid={isValid}
                          handleShow={handleShow}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="d-md-block d-none">
                    <SearchForm
                      handleSubmit={handleSubmit}
                      setSearchQuery={setSearchQuery}
                      setFieldValue={setFieldValue}
                      searchQuery={searchQuery}
                      values={values}
                      isValid={isValid}
                      handleShow={handleShow}
                      isPropertyMap={isPropertyMap}
                      className="d-flex gap-3"
                    />
                  </div>
                </>
              );
            }}
          </Formik>
          <FilterProperty show={show} setShow={setShow} />
        </div>
      </div>
    </section>
  );
};

export const SearchForm = ({
  handleSubmit,
  setSearchQuery,
  setFieldValue,
  searchQuery,
  values,
  isValid,
  className,
  handleShow,
  isPropertyMap,
}: {
  handleSubmit: FormEventHandler<HTMLFormElement> | undefined;
  setSearchQuery: (a: string) => void;
  setFieldValue: (a: string, b: any) => void;
  searchQuery: string;
  values: any;
  isValid: boolean;
  className?: string;
  handleShow: MouseEventHandler<HTMLButtonElement> | undefined;
  isPropertyMap?: boolean;
}) => {
  return (
    <Form className={`pt-4 ${className}`} onSubmit={handleSubmit}>
      <div className="search-grid">
        <div className="item grid-span-2">
          <span className="input-icon">
            <Icon icon="ic:outline-location-on" />
          </span>
          <PlaceSuggestionWrapper
            value={searchQuery}
            onChange={(value) => {
              setSearchQuery(value);
              setFieldValue("longitude", "");
              setFieldValue("latitude", "");
              setFieldValue("region", "");
            }}
            onSelect={(value: locationSchema) => {
              setSearchQuery(value.region);
              setFieldValue("region", value.region);
              setFieldValue("longitude", value.longitude);
              setFieldValue("latitude", value.latitude);
            }}
            inputClassName="form-control bg-transparent overflow-ellipsis manual-increase"
            showIcon
            inputPlaceHolder="Find your perfect stay"
          />
        </div>
        <div className="item">
          <span className="input-icon">
            <Icon icon="mingcute:user-2-line" />
          </span>
          <Field
            component={TextInput}
            type="number"
            name="guest"
            id="guest"
            className="form-control bg-transparent overflow-ellipsis"
            min="1"
            placeholder="Guest"
          />
        </div>
        <div className="item">
          <span className="input-icon">
            <Icon icon="codicon:calendar" />
          </span>
          <CustomDatePicker
            className="bg-transparent p-3_5"
            dateValue={values.checkIn}
            setDateValue={(value: Date) => {
              setFieldValue("checkIn", value);
            }}
            placeholder={"Check-In"}
            minDate={new Date()}
          />
        </div>
        <div className="item">
          <span className="input-icon">
            <Icon icon="codicon:calendar" />
          </span>
          <CustomDatePicker
            className="bg-transparent p-3_5"
            dateValue={values.checkOut}
            setDateValue={(value: Date) => {
              setFieldValue("checkOut", value);
            }}
            placeholder={"Check-Out"}
            minDate={new Date()}
          />
        </div>
        <div className="grid-span-22 ps-0 item bg-transparent">
          <div className="w-100">
            <button
              type="submit"
              className="btn btn-search btn-primary-600"
              disabled={!isValid}
            >
              <span className="icon">
                <Icon
                  icon="material-symbols:search"
                  fontSize={"2.2rem"}
                  rotate={1}
                />
              </span>
              <span className="text">Search</span>
            </button>
          </div>
        </div>
      </div>
      <div className="d-md-block d-none">
        <button
          className={`btn btn-sm filter-btn  ${
            isPropertyMap ? "btn-primary-600" : "btn-outline-white"
          }`}
          type="button"
          onClick={handleShow}
        >
          <Icon
            className="me-2"
            icon="basil:settings-adjust-outline"
            width={"25"}
            rotate={1}
          />
          Filter
        </button>
      </div>
    </Form>
  );
};
