import React from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "55vh",
};

export const LocationMap = ({ lng, lat }: { lng: number; lat: number }) => {
  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat,
          lng,
        }}
        zoom={10}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          <MarkerF position={{ lat, lng }} clickable={true} />
        </>
      </GoogleMap>
    </>
  );
};
