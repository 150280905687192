import useNetworkData from "hooks/useNetworkData";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyRequest } from "services/network/auth";
import Loader from "sharedComponents/Loader";
import { useQuery } from "utilities";

export const VerifyUser = () => {
  const { isLoading, makeRequest, error } = useNetworkData();
  const params = useQuery();
  const navigate = useNavigate();

  const handleVerify = async () => {
    const payload = {
      email: params.get("email"),
      code: params.get("code"),
    };
    const response = await makeRequest({
      payload,
      apiRequest: verifyRequest,
      returnError: true,
    });
    if (!response.error && response.statusCode === 200) {
      navigate("/?verified=true");
    }
  };

  useEffect(() => {
    handleVerify();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        paddingTop: "30vh",
      }}
    >
      {isLoading ? (
        <Loader title="Verifing user..." />
      ) : (
        <div className="text-center ">
          <p className="text-xl text-error text-capitalize">{error?.message}</p>
          <button className="btn btn-blue-dark m-auto" onClick={handleVerify}>
            Click to retry
          </button>
        </div>
      )}
    </div>
  );
};
