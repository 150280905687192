import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";

const stickModeStyles = {
  borderRadius: 0,
  width: "100%",
  paddingInline: "10%",
  zIndex: "10",
};

export const StickyNav = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const location = useLocation();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setScrollPos(window.scrollY);
    });
    return () => {
      document.removeEventListener("scroll", () => {
        setScrollPos(window.scrollY);
      });
    };
  }, []);

  return (
    <Navbar
      sticky="top"
      className="property-navbar"
      style={scrollPos > 560 ? stickModeStyles : {}}
    >
      <a
        className={`nav-link ${location.hash === "#overview" && "active"}`}
        href="#overview"
      >
        Overview
      </a>
      <a
        className={`nav-link ${location.hash === "#availability" && "active"}`}
        href="#availability"
      >
        Availability
      </a>
      <a
        className={`nav-link ${location.hash === "#gallery" && "active"}`}
        href="#gallery"
      >
        Gallery
      </a>
      <a
        className={`nav-link ${location.hash === "#amenities" && "active"}`}
        href="#amenities"
      >
        Amenities
      </a>
      <a
        className={`nav-link ${location.hash === "#location" && "active"}`}
        href="#location"
      >
        Location
      </a>
      <a
        className={`nav-link ${location.hash === "#contact" && "active"}`}
        href="#contact"
      >
        Contact Owner
      </a>
    </Navbar>
  );
};
