import React, { useCallback, useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { PropertyCard } from "sharedComponents/propertyCard";
import { PropertyMap } from "./property-map";
import "./styles.scss";
import useNetworkData from "hooks/useNetworkData";
import {
  getAllListingsRequest,
  searchPropertyRequest,
} from "services/network/properties";
import { PropertySchema } from "services/network/_schema";
import { EmptyContainer } from "sharedComponents/EmptyContainer";
import Loader from "sharedComponents/Loader";
import { useQuery } from "utilities";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";

export const SearchResultsPage = () => {
  const { isLoading, makeRequest } = useNetworkData();
  const params = useQuery();
  const location = useLocation();
  const [availableProps, setAvailableProps] = useState([]);
  const [allProps, setAllProps] = useState([]);
  const [iLoading, setILoading] = useState(true);

  const getResults = useCallback(async () => {
    setILoading(true);

    const checkIn = params.get("start_date_val") || "";
    const checkOut = params.get("end_date_val") || "";
    // const amenity = params.get("amenity")
    //   ? params.get("amenity")?.split(",")
    //   : [];
    // const propertyType = params.get("propertyType")
    //   ? params.get("propertyType")?.split(",")
    //   : [];
    // const additionalService = params.get("additionalService")
    //   ? params.get("additionalService")?.split(",")
    //   : [];
    const payload = {
      latitude: Number(params.get("loc_lat")) || "",
      longitude: Number(params.get("loc_lng")) || "",
      guests: Number(params.get("guest")) || "",
      price1: Number(params.get("price1")) || "",
      price2: Number(params.get("price2")) || "",
      preference: params.get("preference") || "",
      duration: params.get("duration") || "",
      accesibility: params.get("accesibility") || "",
      bookingOption: params.get("bookingOption") || "",
      stayType: params.get("stayType") || "",
      bath1: Number(params.get("bath1")) || "",
      bath2: Number(params.get("bath2")) || "",
      bed1: Number(params.get("bed1")) || "",
      bed2: Number(params.get("bed2")) || "",
      popularSearch: params.get("popularSearch") || "",
      priceSort: params.get("priceSort") || "",
      amenity: params.get("amenity") || "",
      propertyType: params.get("propertyType") || "",
      additionalService: params.get("additionalService") || "",
      checkIn: checkIn
        ? DateTime.fromJSDate(new Date(checkIn)).toFormat("yyyy-LL-dd")
        : "",
      checkOut: checkOut
        ? DateTime.fromJSDate(new Date(checkOut)).toFormat("yyyy-LL-dd")
        : "",
    };

    // Object.entries(payload).forEach((v) => {
    //   if (!v[1]) {
    //     delete (payload as any)[v[0]];
    //   }
    // });
    const res = await makeRequest({
      apiRequest: searchPropertyRequest,
      payload,
    });
    if (res) {
      setAvailableProps(res.data.items);
    }
    setILoading(false);
  }, [makeRequest, params]);

  const getAllProperties = useCallback(async () => {
    const res = await makeRequest({
      apiRequest: getAllListingsRequest,
    });
    if (res) {
      setAllProps(res.data);
    }
  }, [makeRequest]);

  useEffect(() => {
    getAllProperties();
  }, [getAllProperties]);

  useEffect(() => {
    getResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="AllProperties mb-6">
      <PropertyMap
        lng={parseFloat(params.get("loc_lng") || "")}
        lat={parseFloat(params.get("loc_lat") || "")}
      />
      <div className="container mobile-properties">
        <h2 className="sub-title text-center my-5">Search Results</h2>
        {isLoading ? (
          <div className="py-10">
            <Loader title="Please wait..." />
          </div>
        ) : (
          <>
            <Tabs defaultActiveKey="available" id="all-prop-tab">
              {/* <Tab eventKey="all" title="All">
                {allProps.length > 0 ? (
                  <div className="row gy-5">
                    {allProps.map((item: PropertySchema) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        key={item.id}
                      >
                        <PropertyCard
                          showViewListing={true}
                          property={item}
                          showFavorite={true}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <EmptyContainer
                    title={"...there is no property at the moment"}
                  />
                )}
              </Tab> */}
              <Tab eventKey="available" title="Available Properties">
                {iLoading ? (
                  <Loader title="Please wait..." />
                ) : availableProps.length > 0 ? (
                  <div className="row gy-5">
                    {availableProps.map((item: PropertySchema) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        key={item.id}
                      >
                        <PropertyCard
                          showViewListing={true}
                          property={item}
                          showFavorite={true}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <EmptyContainer
                    title={
                      "...there are no search result for the filters provided"
                    }
                  />
                )}
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    </div>
  );
};
