import React from "react";
import "./styles.scss";

export const TermsPage = () => {
  return (
    <section className="terms-page-wrapper mb-9">
      <div className="container">
        <h2 className="sub-title my-6">Terms and Conditions</h2>
        <h3 className="xsub-title text-lg">Information About Us</h3>
        <p className="text-xs">
          PLEASE BE ADVISED THAT THESE TERMS AND CONDITIONS MAY BE SUBJECT TO
          AMENDMENTS OR CHANGE AT ANY TIME.
        </p>
        <br />
        <p className="text-xs">
          QuickStay.co.uk ("us,"we"our) reserve the right at our sole discretion
          to change or alter any terms and conditions without prior notice. You
          must review these terms and conditions on a regular basis to be
          informed of any changes. Please read all the terms and conditions
          thoroughly before using our site. By accessing, viewing or using our
          site you indicate that you accept our terms and conditions ("Terms of
          use"). Whether you are a property owner or a guest or short-term
          tenant completing a booking you acknowledge that by ticking the box or
          clicking the link associated with confirming the terms and conditions
          you indicate that you have read understood and agreed to the terms and
          conditions stipulated (including our privacy policy).
        </p>
        <br />
        <p className="text-xs">
          QuickStay.co.uk is operated by QSP GROUP LTD (Company Number:
          12761867), trading as Quickstay.co.uk ("we","us","our"). By
          registering to the QuickStay website and or using or accessing our
          site; whether as a property owner or managing agent (an
          "Advertiser","Property manager","Property agent") you agree to the
          terms and conditions of our website.
        </p>
        <br />
        <p className="text-xs">
          You understand that the site acts merely as an advertising platform
          for Advertisers ("hosts", "owners" "property managers") and
          accommodation searchers ("guests", "holiday maker(s)"), whereby hosts
          and guests can connect with one another in order to make a booking
          through which a payment will be taken. QuickStays role is solely
          facilitating the booking, through a property listing advertisement via
          the QuickStay website or QuickStay services.
        </p>
        <br />
        <p className="text-xs">
          QuickStay is not by any means, a representative of any property
          listing on the QuickStay website, nor are we a third party to any
          agreement entered into by a guest and host in any area of the site. We
          do not have any further roles, such as but not limited to, owning the
          accommodation, being a joint owner of the accommodation, furnishing
          the accommodation, or operating any accomodation listed on the
          QuickStay site.You also understand and agree that we do not work as a
          contractual agent or an insurer to any property listed via our
          website.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Listings</h3>
        <p className="text-xs">
          Once a user or host has registered, hosts are allowed to create
          listings for a property they wish to advertise. You as the advertiser
          must ensure you make an honest representation of the properties
          advertised on our site, in which we may ask, at any time, for further
          information to authenticate the listing. Although, we will always do
          our best to ensure any disingenuous advertisements are prevented and
          or removed from our site, we disclaim all liability for any listing in
          regards to this.
        </p>
        <br />
        <p className="text-xs">
          Once a listing has been created, the listing will be made publicly
          available through the website, and to other links or services in
          relation to QuickStay web browsers or services that may display the
          listing. Viewers and registered users understand that our site can not
          control the content of any listing, but will also work to ensure that
          listings are as genuine as they appear. QuickStay put in place
          specific procedures to cover our guests from unauthentic or fraudulent
          listings, such as transferring a payment to an owner only after 24
          hours from the check-in time, to allow any issues to be raised and
          reported immediately if this should occur. QuickStay take any and all
          issues regarding fraudulent listings very seriously and will suspend
          any payments should a complaint be put forward until the case has been
          investigated to our satisfaction.
        </p>
        <br />
        <div id="booking">
          <h3 className="xsub-title text-lg">Booking</h3>
          <p className="text-xs">
            A registered user can make a booking on the QuickStay website by
            following the appropriate booking procedure and clicking the
            confirmation button once the relevant information of the user has
            been entered. Guests and tenants must ensure the information in
            relation to the booking is correct prior to confirming and paying
            for a booking. Once a booking has been made the booking will not be
            liable to alteration. You must therefore ensure all details are
            entered correctly when filling out the booking details.
          </p>
          <br />
          <p className="text-xs">
            Once a booking has been made the owner will be notified and given 24
            hours to accept or decline the booking. It is solely down to the
            owners desecration to accept or decline a booking. The owner may
            accept or decline a booking and following the decision the guest
            will be notified promptly by email.
          </p>
          <br />
          <p className="text-xs">
            After a booking has been accepted by a host, both parties are
            required to make all further communication directly. Such
            communication for example, but not in limitation to, may be in
            regards to the handling of keys, check-in and check-out times, and
            additional fees. In relation to any subsequent remaining payments,
            QuickStay will liaise directly with guests and tenants and keep them
            updated of any payments which are due.
          </p>
          <br />
          <p className="text-xs">
            Once a booking has been confirmed and accepted, QuickStay will
            retain the total payment made by the guest until 24 hours after the
            guest has checked into the accommodation. The funds will then be
            released to the owner, should there be no complaint about for
            example the authenticity of the listing.
          </p>
        </div>
        <br />
        <h3 className="xsub-title text-lg">Conduct</h3>
        <p className="text-xs">
          We require all users to communicate appropriately to others. We
          disclaim any responsibility between the communication and conduct made
          between hosts and guests. You agree that QuickStay does not have any
          control over communication or conduct between a host and a guest and
          disclaims all liability in regards to this. We are not responsible for
          any verbal or written communication of the guest or host, during after
          or before the property is letted. You also agree and understand that
          we do not take any responsibility for the service which is provided by
          the host of the accomodation booked throughout the QuickStay site or
          service.
        </p>
        <br />
        <p className="text-xs">
          In any circumstance where by a guest or host has felt an individual
          has acted inappropriately to them during a booking made through the
          QuickStay website or service, and should the individual been subject
          to the following but not limited to, violent, offensive, or sexually
          inappropriate behaviour, you should with out delay report this to the
          appropriate authorities and make QuickStay aware of the circumstances.
          Please be advised that do not take any obligation of liability for you
          or the individuals involved under any situation which might occur.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Financial</h3>
        <p className="text-xs">
          You understand that when a booking is subject to acceptance by a host,
          QuickStay will collect the total payment and retain funds until a
          guest has checked-in to the accommodation. The funds will then be
          released 24hours after a guest has checked in (stated on the listing)
          less the QuickStay fees should there be no complaints in regards to an
          unauthentic listing. Owners are also obligated to pay taxes on any
          payments they receive if they are permitted to do so by their
          country's tax laws. The period of which a property owner will receive
          the payment will depend on the method chosen by the host to be paid by
          QuickStay. Generally, this approximately takes 4-5 working days. You
          the host agree that QuickStay will collect the total payment due on
          your behalf when a booking has been successful. You agree to appoint
          QuickStay as your payment collection agent in regards to booking made
          via QuickStay and agree to pay QuickStay the total fees due where
          applicable.
        </p>
        <br />
        <p className="text-xs">
          You also understand that depending on the method of payment charges,
          may apply in an instance where a third party is involved in the
          transfer. This ultimately depends on to the hosts chosen method of
          payment. You must check the payment methods you choose have payments
          made to. QuickStay will not be liable to pay any charges or fees
          should they apply and will be deducted from the total amount should
          they occur.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Security Deposits</h3>
        <p className="text-xs">
          Owners or hosts may choose to retain a security deposit for their
          listed accomodation or Short-Let accomodation.
        </p>
        <br />
        <p className="text-xs">
          If owners choose to retain a security deposit, this must be specified
          in a listing, in which, in most cases QuickStay will retain the
          deposit on behalf of the owner. However, it is down to the owners
          descresion to choose to collect a security deposit themselves on
          arrival of a guest or alternatively before the start of a short-Let
          tenancy. If a security deposit was retained by QuickStay when the
          booking was made the security deposit will automatically be returned
          to the guest 5 days after the guest has checked out and no there are
          complaints made by owner or property manager. If the host or owner
          collected the deposit directly from the guest or tenant, the deposit
          will be returned to the guest pending the owners reasonable approval
          within the specified time given by the owner. If the owner chooses to
          make a claim against the money taken as the security deposit from the
          guest through QuickStay, we will request adequate information from the
          owner, such as but not in limitation to pictures or video evidence, in
          which we will make a reasonably informed decision on the payout of the
          security deposit to the guest.
        </p>
        <br />
        <p className="text-xs">
          QuickStay disclaims any and all liability to making any decisions in
          regards to security deposits taken directly between the owner and the
          guests. QuickStay limits any liability to all circumstances regarding
          security deposits listed on our site.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Damage to accommodation</h3>
        <p className="text-xs">
          You (the guest) acknowledge and agree to leave all accommodation
          booked or rented through our site in the original condition it met in
          on arrival. Guests are therefore solely responsible for their acts and
          omissions during their stay in any accommodation. You as the guest
          understand that the behaviour of any individual in the accommodation
          during the stay and their departure of which the keys have been
          returned back to the owner or property manager, is the guests full
          responsibility to ensure the accommodation is in the same condition to
          when they arrived and therefore the guest will be liable for any
          damages or inappropriate doing to the accommodation. You agree and
          understand that QuickStay will not be liable for any damages, should
          they occur in accommodation booked through the QuickStay site or
          services.
        </p>
        <br />
        <p className="text-xs">
          It will be the right of the host to, in such event, to claim for any
          damages, negligence or missing item(s) against the security deposit
          taken before the stay. Provided there is some evidence, hosts can
          request that the guest pays a cost to replace any damages or missing
          property present in the accommodation at the start of the stay. Should
          the host choose to take a security deposit directly from the guest and
          not through the QuickStay website or service, it will be solely down
          to the owners discretion to reimburse any costs from the security
          deposit taken. You as a guest acknowledge that you will be liable for
          any costs, as a result of damages, missing property, or broken items
          in the property.
        </p>
        <br />
        <p className="text-xs">
          You acknowledge that we take no responsibility for any damage and or
          unsatisfied service and disclaim all liabilities in regard to this.
        </p>
        <div id="cancellation">
          <br />
          <h3 className="xsub-title text-lg">Cancellation Policy for hosts</h3>
          <p className="text-xs">
            You understand that it is you as a hosts responsibility to ensure
            the property is vacant at the period of which the property is being
            advertised as 'available' on the QuickStay website or any other
            services through QuickStay. If the accommodation should become
            unavailable whilst the listing is active, you agree to amend the
            dates via the 'My account' section immediately. Should the owner
            under sudden unforeseeable circumstances or any other circumstance
            need to cancel a booking after the booking has been confirmed or
            accepted, you agree to being charged£100 cancellation fee which will
            be subject to a deduction via your next booking. This charge will be
            applicable only when a booing has been confirmed cancelled. You
            understand that by accepting these terms, QuickStay may regain fees
            through other methods in order for hosts or guests to cover any fees
            which are owed to the QuickStay Platform.
          </p>
          <br />
          <p className="text-xs">
            Should a host need to cancel a booking, they should cancel the
            booking via their account and immediately contact QuickStay customer
            services on info@quickstay.co.uk or call 0203 4175489.
          </p>
          <br />
          <h3 className="xsub-title text-lg">Cancellation policy for guests</h3>
          <p className="text-xs">
            If a guest decides they want to cancel a booking, they may be liable
            to a refund depending on the cancellation policy associated with the
            booking.
          </p>
          <br />
          <p className="text-xs">
            The cancellation policy is available on the summery page prior to
            confirming the booking and can be accessed again before making the
            payment. If a guest does not pay the remaining balance (which
            includes the booking fee) due after the initial deposit payment at
            the date specified in the booking confirmation email, the total
            deposit will not be refunded and the booking will then be
            automatically cancelled. If you cancel your booking before paying
            the total balance due, you will not be liable to a refund either.
            Once the total balance has been paid you can receive 50% refund of
            the total amount should you the guest cancel 30 days before the
            check-in date. Should you cancel 21days before check-in then you
            will be liable to a 25% refund and should you cancel after 21days
            prior to your booking you will not be entitled a refund.
          </p>
          <br />
          <h3 className="xsub-title text-lg">
            Cancellation for short-let tenants
          </h3>
          <p className="text-xs">
            If the short-let tenant cancels pending a booking request, whereby
            the owner has not yet accepted, QuickStay will cancel the request
            and return 100% of the money back to the tenant
          </p>
          <br />
          <p className="text-xs">
            If the booking is accepted by the owner and the tenant cancels the
            booking, depending on the time of cancellation a refund may be
            subject to the tenant on the basis:
          </p>
          <br />
          <p className="text-xs">
            The cancellation is within 2 months or more before the move in date,
            whereby 100% of the payment will be refunded, less the booking fee.
          </p>
          <p className="text-xs">
            The cancellation is less than 2 months prior to the move in date
            date but more than 1 month, whereby 60% of the payment will be
            refunded.
          </p>
          <p className="text-xs">
            Should however the guest choose to cancel their booking less than 1
            month prior to the move in date it will be down to the owners
            discretion to refund any of the balance.
          </p>
          <br />
          <p className="text-xs">
            * All cancelation policies will be states in summery page prior to
            confirming the booking and will also be included in the confirmation
            email sent to the guest/tenant.
          </p>
        </div>
        <br />
        <h3 className="xsub-title text-lg">Financials for owners and host</h3>
        <p className="text-xs">
          You as a owner or host accept that in any conclusion of a booking
          through QuickStay or a short-term rental you will be required to pay a
          service fee to QuickStay. You as a guest understand that by using the
          QuickStay platform to seek accommodation for yourself or others, you
          agree to pay the total fees and any taxes or VAT associated with a
          booking when a booking is confirmed and accepted. You as a guest
          understand that QuickStay will retain the total payment until you have
          checked into the accommodation. You understand the QuickStay reserve
          the right to collect any payments made on behalf of the host.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Refund Policy</h3>
        <p className="text-xs">
          QuickStay may offer a full refund to guests and short-let tenants
          following a hosts decline to a booking. Guests or tenants will receive
          a full refund of the amount paid, and an email explaining that the
          booking was not accepted and that the payment will be refunded to
          them.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Account Restricts</h3>
        <p className="text-xs">
          You as a guest agree to use the QuickStay site and services site
          solely for the use of listing accommodation. You understand that you
          must not falsely advertise, duplicate or mislead any information in
          relation to or associated with a listing of accommodation. You
          acknowledge that you are not permitted to include inappropriate or
          inaccurate details in a listing or post, including but not limited to
          slanderous, offensive, misrepresentative or inappropriate details or
          posts. You understand QuickStay reserve the right to remove the
          listing or by all means request further information such as personal
          information and or property details to authenticate your listing.
          QuickStay disclaim any liability for listings which may be in accurate
          or proclaimed offensive to any user or viewer, and although we will do
          our best to prevent any scandalous use of our site we are not
          responsible for the content and or any listings, including photos,
          descriptions, videos material and all other content on the QuickStay
          site or service.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Currency</h3>
        <p className="text-xs">
          You understand that the currency displayed on the website will be in
          Sterling, Dollars or Euros depending on the currency chosen by the
          host. The payment will remain in the chosen currency until you are
          required to make a payment. Once the guest has has filled out the
          required information to proceed with the booking the guest will then
          be redirected to make a secure online payment, whereby the payment
          will be automatically displayed in the local currency of the guest.
          You understand that currency conversation rates will very from time to
          time.
        </p>
        <br />
        <p className="text-xs">
          When a refund it permitted by the QuickStay website or service the
          currency may be subject to change in which the refunded amount may
          vary due to the currency conversion rate at the time.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Interruptions</h3>
        <p className="text-xs">
          Whilst QuickStay will always work to ensure the site is accessible to
          all users at all times, the internet is not always a stable zone and
          therefore disruptions, errors, service errors or any other issues can
          occur at any given time, therefore users must acknowledge that
          QuickStay does not guarantee that there will not be any issues to the
          website or platform or the site will remain for any given time so we
          will not be responsible for any issues or implications which might
          occur.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Eligibility and use of our site</h3>
        <p className="text-xs">
          Any user of the QuickStay website or the QuickStay services must be
          over the age of 18 years.
        </p>
        <br />
        <p className="text-xs">
          QuickStay does not permit users to advertise other businesses,
          website, or company links, in relation to accommodation or all other
          business nature at any time. We do not permit any listing to include
          links of any kind on our site and failure to comply with any of these
          regulations may lead to an account being removed, deactivated or
          suspended. Use of the QuickStay website should never suggest, imply or
          state that we are in partnership with hosts or jointly own property
          with hosts. You as a holiday maker(s) and tenant(s) acknowledge that
          we are not the owners of property listings or any accommodation listed
          on to the QuickStay website. Our responsibility as an online platform
          is solely to facilitate payments in relation to a booking made by a
          guest via the QuickStay website or service. We disclaim any
          relationship including but not limited to, a broker, partnership,
          joint venture, contractual agent with any and all listings on our
          website or advertised through our service. Therefore, any registered
          user and or viewer of the site understands that nothing on any area of
          the site such as the booking contract and terms of use suggests we are
          in partnership with a host and disclaim any representation in regards
          to this.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Taxes</h3>
        <p className="text-xs">
          Owners are also obligated to pay taxes on any payments they receive if
          they are permitted to do so by their country's tax laws.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Additional Fees</h3>
        <p className="text-xs">
          The host may choose to include additional fees to a property rental,
          such as but not limited to, cleaning fee, city tax, refundable deposit
          etc that the guest may be obligated to pay. These fees may however not
          be included in the total payment due, although these fees must be
          displayed in the listing to be applicable and therefore required to be
          paid by a guest or tenant. If these charges are displayed in the
          listing and are not included in the total amount due, these charges
          will be required to be paid directly the owner or property manager
          upon check-in.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Law and Jurisdiction</h3>
        <p className="text-xs">
          These terms of us in relation to this advertiser/platform agreement is
          governed by the law of England and Wales and the Courts of England and
          Wales will have exclusive jurisdictions over any claim arising in
          relation to these terms. Any claims which may arise in relation to
          these terms of use will have non-exclusive rights to the English
          courts. However, we reserve the right to bring court proceedings
          against you for the breach of any of these terms in your country of
          residence or any related country. We reserve the right to prosecute
          anyone who comes against our term and condition.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Recurring Payment</h3>
        <p className="text-xs">
          A recurring payment also known as continuous payment authority (CPA)
          is a payment whereby guests are eligible to make payments following
          their initial deposit for a confirmed booking prior to their stay.
          These payments are recurring an may be made one or more times after
          the initial deposit has been made. Payments will be taken from their
          credit or debit card with prior authorisation from the guest. If
          recurring payments are applicable to a confirmed booking, guests will
          be provided with more information about these payments. If this option
          should be chose, you as a guest are appointing QuickStay to take all
          payments on behalf of the host.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Bank Transfer</h3>
        <p className="text-xs">
          Guests may be eligible to make a payment via bank transfer. If guests
          should choose to do so for either a holiday rental, or a short-term
          rental, guests are required to make the payment into the QuickStay
          bank account provided and include the reference number also provided
          in an email. Once the funds have been confirmed, QuickStay will notify
          the owner and an email will be sent to both partied. Typically bank
          transfers can be cost effective especially if a domestic transfer is
          being made, however QuickStay offer other suitable payment methods
          which may suitable to the guest and where applicable other payment
          methods will be presented to the guest, following the initial booking
          confirmation and booking acceptance from the owner.
        </p>
        <br />
        <p className="text-xs">
          In an event where the booking is not accepted, QuickStay may offer
          alternative accommodation options in a similar or in the same price
          range to the original booking. If the guest should choose to go ahead
          with an alternative option, the payment will be transferred to the new
          booking. However, should the guest request a refund, QuickStay will
          refund the payment immediately. Please not that QuickStay will not be
          responsible for zany costs or fees associated with a refund and will
          not be responsible for any costs or fees involved in a bank transfer
          or any other method used to make the payment. Should any fees apply
          the costs will be deducted from the total payment.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Other methods of payment</h3>
        <p className="text-xs">
          Guests and Short-Let tenants may be eligible to make payments via
          other secure providers such as PayPal. If the user is eligible they
          will be notified of other payments methods they can use.
        </p>
        <br />
        <h3 className="xsub-title text-lg">Copyright Infringement</h3>
        <p className="text-xs">
          If you genuinely believe that any material viewed on our site
          infringes your copyright please provide detailed information, your
          personal information and a signed document by the person or authorised
          individual acting on behalf of the of the alleged infringed material.
        </p>
        <br />
        <p className="text-xs">
          This information of the notice of the copyright infringement should be
          either:
        </p>
        <br />
        <p className="text-xs">Emailed to info@quickstay.co.uk or</p>
        <p className="text-xs">Mailed to: QSP Group Ltd</p>
        <p className="text-xs">Attention: Legal Dept</p>
        <p className="text-xs">1st Floor</p>
        <p className="text-xs">239 High Street Kensington</p>
        <p className="text-xs">London</p>
        <p className="text-xs">W8 6SN</p>
        <br />
        <p className="text-xs">
          PLEASE NOTE THAT SHOULD YOU MAKE A FALSE CLAIM OF COPYRIGHT
          INFRINGEMENT YOU MAY BE LIABLE TO ALL COSTS IN RELATION TO THE CLAIM.
        </p>
        <br />
        <p className="text-xs">
          By using the QuickStay website or services you enter into a law
          binding agreement and if you choose to disagree with any of the terms
          in this document you should close the QuickStay web browser and
          refrain from using the site and the QuickStay services.
        </p>
        <br />
        <p className="text-xs">
          If you have any question regarding these terms and conditions, email
          info@quickstay.co.uk
        </p>
      </div>
    </section>
  );
};
