import React, { useEffect, useRef, useState } from "react";
import { PropertyCard } from "sharedComponents/propertyCard";
import { PropertyMap } from "./property-map";
import "./styles.scss";
import useNetworkData from "hooks/useNetworkData";
import { getPaginatedListingsRequest } from "services/network/properties";
import { PropertySchema } from "services/network/_schema";
import { EmptyContainer } from "sharedComponents/EmptyContainer";
import Loader from "sharedComponents/Loader";

export const AllProperties = () => {
  const { makeRequest } = useNetworkData();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingSilently, setLoadingSilently] = useState(false);
  const [allProps, setAllProps] = useState<PropertySchema[]>([]);
  const [pagingInfo, setPagingInfo] = useState({
    currentPage: 1,
    itemsPerPage: 12,
    totalItems: 0,
    totalPages: 1,
  });
  const [hasMore, setHasMore] = useState(true);
  const divRef = useRef(null);

  const getListings = async (page: number) => {
    //Don't make new calls if it is currently loading
    if (!loadingSilently) {
      if (page === 1) {
        // show loader if first page load
        setIsLoading(true);
      } else {
        // Load silently to populate the list on scroll without showing the loader
        setLoadingSilently(true);
      }

      const res = await makeRequest({
        payload: {
          limit: 12,
          page,
        },
        apiRequest: getPaginatedListingsRequest,
      });
      setIsLoading(false);
      if (res.statusCode === 200) {
        const { meta, items } = res.data;
        setPagingInfo(meta);
        setLoadingSilently(false);

        if (page === 1) {
          setAllProps(items);
        } else {
          setAllProps((prev) => [...prev, ...items]);
        }
        if (page >= meta.totalPages) {
          // Set hasmore to false if there is no more item to get from the DB
          setHasMore(false);
        }
      } else {
        page > 1 &&
          setPagingInfo((prev) => ({ ...prev, currentPage: page - 1 }));
        setLoadingSilently(false);
      }
    }
  };

  useEffect(() => {
    getListings(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIntersection = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    if (
      hasMore &&
      !loadingSilently &&
      !isLoading &&
      entries[0].isIntersecting
    ) {
      getListings(pagingInfo.currentPage + 1);
      // Disconnect the observer
      observer.disconnect();
    }
  };

  useEffect(() => {
    const current = divRef.current;
    const observer = new IntersectionObserver((entries) => {
      handleIntersection(entries, observer);
    });

    if (current) {
      observer.observe(current);
    }
    // Cleanup: disconnect the observer when the component unmounts
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProps]);

  return (
    <div className="AllProperties mb-6">
      <PropertyMap />
      <div className="container mobile-properties">
        <h2 className="sub-title text-center mb-5">Properties</h2>
        {isLoading ? (
          <div className="py-10">
            <Loader title="Fetching..." />
          </div>
        ) : (
          <>
            {allProps.length > 0 ? (
              <div className="row gy-5">
                {allProps.map((item: PropertySchema) => (
                  <div className="col-lg-4 col-md-6 col-sm-12" key={item.id}>
                    <PropertyCard
                      showViewListing={true}
                      property={item}
                      showFavorite={true}
                    />
                  </div>
                ))}
                {loadingSilently && (
                  <div className="text-center">Fetching More...</div>
                )}
              </div>
            ) : (
              <EmptyContainer title={"There are no listing yet"} />
            )}
          </>
        )}
        <div ref={divRef}></div>
      </div>
    </div>
  );
};
