import Axios from "services/api";

export const updateUserProfileRequest = (data) => {
   return Axios.patch("/user", data);
};

export const getUserAccountsRequest = () => {
   return Axios.get("/account/user/all");
};

export const addUserAccountRequest = (data) => {
   return Axios.put("/account/add", data);
};

export const updateUserAccountRequest = ({ id, data }) => {
   return Axios.patch(`/account/update/${id}`, data);
};

export const updateUserPictureRequest = (data) => {
   return Axios.patch(`/user/picture`, data);
};
export const closeUserRequest = () => {
   return Axios.delete(`/user/close`);
};

export const deactivateUserAccountRequest = (id) => {
   return Axios.post(`/account/deactivate/${id}`);
};

export const getUserBalanceRequest = () => {
   return Axios.get("/payout/balance");
};

export const payoutRequest = () => {
   return Axios.put("/payout/add");
};

export const changePasswordRequest = (data) => {
   return Axios.patch("/user/password", data);
};

export const getMessagesRequest = () => {
   return Axios.get("/message");
};

export const getMessageByIdRequest = (id) => {
   return Axios.get(`/message/${id}`);
};

export const AddMessageRequest = (data) => {
   return Axios.put("/message/add", data);
};

export const getNotificationsRequest = () => {
   return Axios.get("/notification");
};

export const clearNotificationRequest = () => {
   return Axios.post("/notification");
};

export const getSupportTicketsRequest = () => {
   return Axios.get("/support");
};

export const getSupportTicketByIdRequest = (id) => {
   return Axios.get(`/support/user/${id}`);
};

export const CreateSupportTicketRequest = (data) => {
   return Axios.put("/support/add", data);
};

export const UpdateSupportTicketRequest = ({ data, id }) => {
   return Axios.patch(`/support/${id}`, data);
};

export const getPayoutTransactionsRequest = (params) => {
   return Axios.get("/payout/transactions", { params });
};

export const getSinglePayoutTransactionRequest = (singleId) => {
   return Axios.get(`/payout/transactions/${singleId}`);
};