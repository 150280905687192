import React from "react";
import "./styles.scss";

export const BookingContractPage = () => {
  return (
    <section className="container mb-9">
      <h2 className="sub-title my-6">Booking Contract</h2>
      <p>
        This booking contract ("Rental Agreement","Booking Agreement") is a
        legally binding agreement between the guest(s) ("Tenants",
        "Holidaymakers") making a booking through the QuickStay platform, and
        the Owner ("Agent, "Host", "Property Manager") providing the
        accommodation.
        <br />
        <br />
        In any case of a booking where a guest is required to to sign a Rental
        agreement ("booking contract") prior to their stay in any accommodation
        booked through the QuickStay website, the new Rental Agreement will
        automatically be in effect and supersede this Rental agreement. This
        must only be subject to a signed agreement by the guest(s) or tenant(s)
        prior to their stay.
        <br />
        <br />
        Once a booking has been placed through the QuickStay website, it is the
        guests responsibility to ensure any further payments are met, following
        their initial deposit. If these payments are not met by the stipulated
        time/date, the booking may be subject to cancelation. Should this occur
        the guest may be subject to a loss of their deposit. If however, a guest
        decides they want to cancel a booking, they may be liable to a refund
        depending on the cancellation policy associated with their booking. This
        will be stipulated in the listing page and/or set out in the email
        confirmation.
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">
        Conditions and use of property
      </h3>
      <p>
        Owners ("Agents") must ensure the property is in a good condition for
        guests staying in the property. It is essential for owners to make their
        best effort to ensure the amenities provided in the property are fully
        functional and working to a correct standard. If a problem should occur,
        in respect to an amenity in the property, the owner (or "Agent") should
        try to resolve this issue as soon as possible. Guests should always be
        cautious when using amenities in the property and ensure they take care.
        <br />
        <br />
        The number of guests staying in the property should not exceed the
        amount of guests specified in the booking. Guests should ensure they use
        the property only for the use of residential purposes. The guest must
        also ensure they use the property respectfully without damaging anything
        and leave the property in the original condition it was met in. Guests
        are solely responsible for their actions during their stay. It will be
        the right of the owner (or host) to claim for any damages, negligence or
        missing item(s) against the security deposit taken prior to the guests
        stay.
        <br />
        <br />
        If a guest decides they want to cancel a booking, they may be liable to
        a refund depending on the cancellation policy associated with the
        booking. This will be specified in the booking process and or set out in
        the email confirmation.
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">Arrival & Departure</h3>
      <p>
        The guest can arrive at the property after the specified check-in time,
        stated in the booking confirmation or confirmation email. Guests can
        arrange directly with the owner the time they wish to arrive at the
        property. If the guest is delayed they should contact the owner or
        property agent and let them know the time they will be arriving.
        <br />
        <br />
        When departing the property the guest must ensure to leave the keys
        where the owner can have access to them or return them directly to the
        owner (or property manager). Guests must leave the property at the
        check-out time specified and ensure the property is in the original
        state.
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">Additional Fees</h3>
      <p>
        There may be additional fees associated with a booking. Hosts may impose
        additional fees which they may choose to collect from guests directly.
        Such additional fees may be refundable deposits, cleaning fees, late
        check-in/out fees etc. In cases where these any additional fees may
        apply, it will be displayed on the property listing page on the site.
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">Obligations</h3>
      <p>
        Guests must ensure they take adequate steps to safeguard their
        possessions in the property.
        <br />
        <br />
        Guests agree to comply with any regulation manuals displayed in the
        property. You the guest(s) agree not to cause damage to the property or
        be a nuisance to neighbours. In cases where a room is being rented or a
        guest is sharing a property with another person(s), the guest agrees to
        be respectful and not cause any annoyance to other occupants. Guests
        must also be mindful of their actions and act appropriately towards
        other tenants (or guests).
        <br />
        <br />
        Guests agree to allow the owner or a person in need of carrying out
        maintenance work into the property, in order to carry out essential
        repairs if necessary.
        <br />
        <br />
        This Booking contract, together with the Terms and Conditions and
        Cancellation Policy, given by the QuickStay website must be adhered to
        at all times. If for any reason the contract is broken QuickStay should
        also be informed about the breach of contract immediately by either
        party.
      </p>
    </section>
  );
};
