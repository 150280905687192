import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useCallback, useState } from "react";
import { EditorState } from "react-draft-wysiwyg";
import { toast } from "react-toastify";
import { AddMessageRequest } from "services/network/user";
import { PropertySchema } from "services/network/_schema";

export const useContactOwner = ({
  makeRequest,
}: {
  makeRequest: (arg: any) => any;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const messageOwner = useCallback(
    async (
      editorState: EditorState,
      property: PropertySchema,
      setEditorState: (value: any) => void
    ) => {
      setIsLoading(true);
      const message = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      const res = await makeRequest({
        apiRequest: AddMessageRequest,
        payload: {
          chat: message,
          propertyId: property.id,
          recipientId: property.user.id,
        },
      });
      setIsLoading(false);
      if (res.statusCode === 200) {
        setEditorState(undefined);
        toast.success("Message sent 🎉!");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { isLoading, messageOwner };
};
