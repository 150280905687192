import { removeStorageData, StorageKeys } from "lib/storageManager";
import Axios from "services/api";
import {
  ForgotPassInputSchema,
  LoginInputSchema,
  RegisterInputSchema,
  ResendVInputSchema,
  ResetInputSchema,
  VerifyInputSchema,
} from "./_schema";

export const loginRequest = (data: LoginInputSchema) => {
  return Axios.post("/auth/login", data);
};

export const googleLoginRequest = (access_token: string) => {
  return Axios.post(`/auth/login/google`, {
    access_token,
  });
};

export const FBLoginRequest = (access_token: string) => {
  return Axios.post(`/auth/login/facebook?access_token=${access_token}`);
};

export const signUpRequest = (data: RegisterInputSchema) => {
  return Axios.put("/auth/signup", data);
};

export const verifyRequest = (data: VerifyInputSchema) => {
  return Axios.post("/auth/verify", data);
};

export const forgotPasswordRequest = (data: ForgotPassInputSchema) => {
  return Axios.put("/auth/forgot-password", data);
};

export const resetPasswordRequest = (data: ResetInputSchema) => {
  return Axios.post("/auth/reset-password", data);
};

export const resendCodeRequest = (data: ResendVInputSchema) => {
  return Axios.post("/auth/resend-verification", data);
};

//logout
export const logoutService = (reload?: boolean) => {
  removeStorageData(StorageKeys.token);
  removeStorageData(StorageKeys.user);
  if (reload) {
    window.location.replace("/");
  }
};
