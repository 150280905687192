import { useLocation } from "react-router-dom";

export const convertArrayToSelectOptions = (values) => {
  return values.map((value) => ({ label: value, value }));
};

export const convertModelArrayToSelectOptions = (
  items,
  value,
  label,
  includeOriginal
) => {
  return items.map((item) => ({
    ...(includeOriginal ? { ...item } : {}),
    value: item[value] || "",
    label: item[label] || "",
  }));
};

export const getOneLinerErrorMessage = (payload) => {
  if (
    !payload ||
    !payload.errors ||
    payload.errors?.length !== 1 ||
    typeof payload.errors[0].detail !== "string"
  ) {
    return null;
  }

  return payload.errors[0].detail;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getAmenityIcon = (name) => {
  if (name) {
    return `${process.env.REACT_APP_ASSETS_URL}/assets/${name
      .toLowerCase()
      .split(" ")
      .join("-")}.svg`;
  }
};

export const truncateAccount = (address, numb) => {
  const len = numb || 4;
  const list = address.split("");
  const first = list.slice(0, len);
  const last = list.slice(list.length - 2, list.length);
  return `${first.join("")}***${last.join("")}`;
};
