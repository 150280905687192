import { useCurrency } from "hooks/useCurrency";
import useNetworkData from "hooks/useNetworkData";
import {
   getStorageData,
   saveStorageData,
   StorageKeys,
} from "lib/storageManager";
import React, { createContext, useCallback, useEffect, useState } from "react";
import {
   getMessagesRequest,
   getNotificationsRequest,
} from "services/network/user";

export const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
   const user = getStorageData(StorageKeys.user);
   const { makeRequest } = useNetworkData();
   const [language, setLanguage] = useState(
      getStorageData(StorageKeys.language) || "en"
   );
   const [currency, setCurrency] = useState(
      getStorageData(StorageKeys.currency) || "GBP"
   );

   const { getRate, rates } = useCurrency();

   const [bookingDetails, setBookingDetails] = useState();
   const [unreadMessages, setUnreadMessages] = useState(0);
   const [unreadNotifications, setUnreadNotifications] = useState([]);

   useEffect(() => {
      if (language) {
         saveStorageData(StorageKeys.language, language);
         // eslint-disable-next-line no-undef
         Weglot.switchTo(language);
      }
   }, [language]);

   useEffect(() => {
      if (currency) {
         saveStorageData(StorageKeys.currency, currency);
         getRate(currency);
      }
   }, [currency, getRate]);

   const getPrice = (price, curr) => {
      if (rates && curr) {
         return ((parseFloat(price) || 0) / rates[curr]).toLocaleString(
            undefined,
            {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
            }
         );
      } else {
         return (parseFloat(price) || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
         });
      }
   };

   const getAllMessages = useCallback(async (user) => {
      const res = await makeRequest({
         apiRequest: getMessagesRequest,
      });
      if (res?.statusCode === 200) {
         const list = res.data;
         const count = list.filter(
            (item) => !item.isRead && item.userId !== user.id
         ).length;
         setUnreadMessages(count);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getNotification = useCallback(async () => {
      const res = await makeRequest({
         apiRequest: getNotificationsRequest,
      });
      if (res?.data) {
         const { data } = res;
         setUnreadNotifications(data.filter((m) => !m.hasRead));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (user) {
         getAllMessages(user);
         getNotification();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [getAllMessages, getNotification]);

   return (
      <LayoutContext.Provider
         value={{
            language,
            setLanguage,
            currency,
            setCurrency,
            bookingDetails,
            setBookingDetails,
            rates,
            getPrice,
            unreadMessages,
            setUnreadMessages,
            unreadNotifications,
            setUnreadNotifications,
         }}
      >
         {children}
      </LayoutContext.Provider>
   );
};
