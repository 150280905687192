import { HospitalityCard } from "sharedComponents/hospitalityCard";
import "./styles.scss";

export const VoucherPage = ({ acceptance }: { acceptance?: boolean }) => {
  return (
    <div className="voucher-page-wrapper">
      <HospitalityCard hospitalityPage={true} acceptance={acceptance} />
    </div>
  );
};
