import React, { useState } from "react";
import { PropertySchema, ReserveDSchema } from "services/network/_schema";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";
import useNetworkData from "hooks/useNetworkData";
import { useContactOwner } from "hooks/useContactOwner";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { Editor } from "react-draft-wysiwyg";
import Button from "sharedComponents/forms/Button";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import { DateTime } from "luxon";
import { reservePropertyRequest } from "services/network/properties";
import { toast } from "react-toastify";

export const ReserveProperty = ({
  showOwner,
  setShowOwner,
  property,
  handleShowLogin,
  handleOpenRegister,
  reserveDetails,
}: {
  showOwner: boolean;
  setShowOwner: any;
  property: PropertySchema;
  handleShowLogin?: () => void;
  handleOpenRegister?: () => void;
  reserveDetails: ReserveDSchema;
}) => {
  const { makeRequest, isLoading } = useNetworkData();
  const { messageOwner } = useContactOwner({ makeRequest });
  const token = getStorageData(StorageKeys.token);
  const html =
    "<p>Hi, I am interested in your property and I would like to reserve it now. Please let's talk about the reservation plans.</p>";
  const contentBlock = htmlToDraft(html);
  const content = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const [editorState, setEditorState] = useState<EditorState | undefined>(
    EditorState.createWithContent(content)
  );

  const handleClose = () => {
    setShowOwner(false);
  };

  const submit = async () => {
    const res = await makeRequest({
      apiRequest: reservePropertyRequest,
      payload: {
        id: property?.id,
        data: {
          guests: reserveDetails.guestCount,
          checkIn: DateTime.fromJSDate(reserveDetails.checkIn).toFormat(
            "yyyy-LL-dd"
          ),
          checkOut: DateTime.fromJSDate(reserveDetails.checkOut).toFormat(
            "yyyy-LL-dd"
          ),
        },
      },
    });
    if (res.statusCode === 200 && !editorState) {
      toast.success("Message sent 🎉!");
    }
    if (editorState) {
      messageOwner(editorState, property, setEditorState);
    }
  };

  return (
    <Modal
      show={showOwner}
      onHide={handleClose}
      centered
      dialogClassName="owner-modal"
    >
      <Modal.Body>
        <div className="text-end me-5 mt-5">
          <Icon
            className="text-xxl click"
            onClick={handleClose}
            icon="akar-icons:cross"
          />
        </div>
        <p className="sub-title text-center mb-5">Property Reservation</p>
        <div className="owner-detail">
          <div className="p-img">
            <img
              src={`${process.env.REACT_APP_ASSETS_URL}/${property?.featuredImage}`}
              alt=""
            />
          </div>
          <div className="detail">
            <p className="p-name" id="no_w_translate">
              {property?.name}
            </p>
            <p
              className="d-flex align-items-center text-lg"
              id="no_w_translate"
            >
              <Icon icon="ic:outline-location-on" className="me-2 text-xxl" />
              {property?.city}, {property?.country}
            </p>
            <div className="mt-5 row">
              <div className="col-6 mb-5 text-primary-600 d-flex align-items-center gap-4">
                <div className="lh-1 text-2xl">
                  <Icon icon="fluent:people-community-28-regular" />
                </div>
                <div className="text-lg">
                  <p className="mb-0 fw-bold">Guest Capacity</p>
                  <p className="text-black-500 mb-0">
                    {reserveDetails.guestCount}
                  </p>
                </div>
              </div>
              <div className="col-6 mb-5 text-primary-600 d-flex align-items-center gap-4">
                <div className="lh-1 text-2xl">
                  <Icon icon="radix-icons:calendar" />
                </div>
                <div className="text-lg">
                  <p className="mb-0 fw-bold">Reserved</p>
                  <p className="text-black-500 mb-0">
                    {DateTime.now().toLocaleString(DateTime.DATE_FULL)}
                  </p>
                </div>
              </div>
              <div className="col-6 mb-5 text-primary-600 d-flex align-items-center gap-4">
                <div className="lh-1 text-2xl">
                  <Icon icon="radix-icons:calendar" />
                </div>
                <div className="text-lg">
                  <p className="mb-0 fw-bold">Check-In Date</p>
                  <p className="text-black-500 mb-0">
                    {DateTime.fromJSDate(reserveDetails.checkIn).toLocaleString(
                      DateTime.DATE_FULL
                    )}
                  </p>
                </div>
              </div>
              <div className="col-6 mb-5 text-primary-600 d-flex align-items-center gap-4">
                <div className="lh-1 text-2xl">
                  <Icon icon="radix-icons:calendar" />
                </div>
                <div className="text-lg">
                  <p className="mb-0 fw-bold">Check-Out Date</p>
                  <p className="text-black-500 mb-0">
                    {DateTime.fromJSDate(
                      reserveDetails.checkOut
                    ).toLocaleString(DateTime.DATE_FULL)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-xl sub-title">
              Send an enquiry to the property owner
            </p>
            {token ? (
              <>
                <div className="editor">
                  <Editor
                    editorState={editorState}
                    editorClassName="form-control textarea"
                    placeholder="Leave a message..."
                    toolbarHidden
                    onEditorStateChange={setEditorState}
                  />
                </div>
                <Button
                  title="Send Message"
                  loading={isLoading}
                  disabled={isLoading || !editorState}
                  className="btn btn-md btn-blue-dark px-5 mt-4"
                  onClick={submit}
                />
              </>
            ) : (
              <>
                <p className="text-sm">
                  You must be logged in to contact the owner of this property.
                </p>
                {handleShowLogin && handleOpenRegister && (
                  <div className="d-flex gap-5">
                    <button
                      className="btn btn-blue-dark btn-md"
                      id={"login-btn"}
                      onClick={() => {
                        handleClose();
                        handleShowLogin();
                      }}
                    >
                      Sign In
                    </button>
                    <button
                      className="btn btn-blue-dark btn-md"
                      onClick={() => {
                        handleClose();
                        handleOpenRegister();
                      }}
                    >
                      Sign Up
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
