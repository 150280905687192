// enum Expectation {
//   Fling,
//   None,
//   Relation,
// }

// enum Gender {
//   Female,
//   Male,
//   NonBinary,
// }

export type APIResponse = {
  message: string;
  data?: any;
  statusCode: number;
};

export type LoginInputSchema = {
  email: String;
  password: String;
};

export type RegisterInputSchema = {
  email: String;
  password: String;
  confirmPassword: String;
};

export type VerifyInputSchema = {
  email: String;
  code: String;
};

export type ResetInputSchema = {
  email: String;
  code: String;
  password: String;
  confirmPassword: string;
};

export type ForgotPassInputSchema = {
  email: String;
};

export type ResendVInputSchema = {
  email: String;
};

export enum Gender {
  Male = "male",
  Female = "female",
  NonBinary = "non_binary",
}
export enum Language {
  English = "en",
  French = "fr",
  Spanish = "es",
  Italian = "it",
  Portuguese = "pt",
  Chinese = "ch",
  Japanese = "ja",
}
export enum PropertyType {
  House = "House",
  Apartment = "Apartment",
  "Pent House" = "Penthouse",
  Studio = "Studio",
  Bungalow = "Bungalow",
  Villa = "Villa",
  "Private Room" = "Private Room",
  Cottage = "Cottage",
  "Shared Room" = "Shared Room",
  "Office Space" = "Office Space",
  Other = "Other",
}
export enum StayType {
  "Short Let" = "Short Let",
  "Holiday Let" = "Holiday Let",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Corporate = "Corporate",
}
export enum PropertyRelationship {
  Landlord = "Landlord",
  PropertyManager = "Property Manager",
  LiveInLandlord = "Live in Landlord",
  Other = "Other",
}

export enum PropertySizeMetrics {
  SquareFeet = "Square Feet",
  SquareMetres = "Square Metres",
}

export enum Preference {
  "Short Let" = "Short Let",
  "Holiday Let" = "Holiday Let",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Corporate = "Corporate",
  Landlord = "Landlord",
  "Property Manager" = "Property Manager",
  Agent = "Agent",
  "Live In Landlord" = "Live In Landlord",
  Guest = "Guest",
  Other = "Other",
}
export enum BookOptions {
  "Per Week" = "Per Week",
  "Per Month" = "Per Month",
  "Per Night" = "Per Night",
}
export enum BookingDurations {
  "Per Week" = "Per Week",
  "Per Month" = "Per Month",
  "Per Night" = "Per Night",
}

export enum AmenityCategory {
  Features = "Features",
  KitchenDining = "Kitchen and Dining",
  Entertainment = "Entertainment",
  Facilities = "Facilities",
  Suitability = "Suitability",
  Other = "Other",
}

export type AmenitySchema = {
  amenityCategory: string;
  createdAt: string;
  deletedAt: string;
  description: string;
  id: string;
  name: string;
  updatedAt: string;
  url: string;
};
export interface UserSchema {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  dob?: Date;
  profilePicture?: string;
  gender?: Gender;
  phoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  facebookId?: string;
  googleId?: string;
  language: Language;
  isVerified: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  roleType?: string;
}

export interface Picture {
  createdAt: Date;
  id: string;
  key: string;
  propertyId: string;
  updatedAt: Date;
  url: string;
}
export interface PropertySchema {
  name: string;
  mustReserve: boolean;
  propertyType: PropertyType;
  stayType: StayType;
  cancellationPolicy: CancellationPolicy;
  propertyRelationship: PropertyRelationship;
  description?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  country: string;
  locationLatitude: string;
  locationLongitude: string;
  bedroom: number;
  bedNumber: number;
  bathroom: number;
  guest: number;
  minimumNumberNights: number;
  floor: string;
  propertySize?: number;
  checkIn: string;
  checkOut: string;
  price: any;
  availableShortLet?: boolean;
  garden?: boolean;
  balcony?: boolean;
  terrace?: boolean;
  elevator?: boolean;
  sofaBedAvailable?: boolean;
  propertySizeMetrics: PropertySizeMetrics;
  currency: any;
  bookingDurations: BookingDurations;
  pictures: Picture[];
  propertyFees: {
    feeId: string;
    value: number;
    feeType: string;
    role: string;
  }[];
  amenities?: string[];
  updatedAt: string;
  deletedAt: string;
  id: string;
  userId?: string;
  user: UserSchema;
  featuredImage: string;
  isWishlist: boolean;
}

export interface BankAccountSchema {
  accountNumber: string;
  bank: string;
  country: string;
  createdAt: Date;
  deletedAt: Date;
  id: string;
  sortCode: string;
  updatedAt: Date;
  userId: string;
  accountName: string;
}

export interface BookingSchema {
  property: PropertySchema;
  id: string;
  createdAt: string;
  price: number;
  fees: any;
  currency: string;
  checkIn: string;
  checkOut: string;
  status: string;
  customerRef?: string;
}

export interface userMessage {
  id: string;
  chat: string;
  user: UserSchema;
  userId: string;
  propertyId: string;
  topicId: string;
  recipient: UserSchema;
  recipientId: string;
  createdAt: string;
  updatedAt: string;
  property: PropertySchema;
  isRead: boolean;
}
export interface chatSchema {
  id: string;
  chat: string;
  user: UserSchema;
  userId: string;
  propertyId: string;
  createdAt: string;
  updatedAt: string;
  recipient: UserSchema;
  recipientId: string;
  topicId: string;
}

export interface SupportSchema {
  id: string;
  userId: string;
  subject: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}
export interface SupportInquiry {
  id: string;
  userId: string;
  adminId: string | undefined;
  supportId: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

export interface SingleSupportchema {
  id: string;
  userId: string;
  subject: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  inquiries: SupportInquiry[];
}
export interface NotificationSchema {
  id: string;
  userId: string;
  message: string;
  hasRead: boolean;
  data: any;
  createdAt: string;
  updatedAt: string;
}

export interface RestaurantSchema {
  id: string;
  name: string;
  image: string;
  rating: string;
  address: string;
  discountPromotion?: string;
  discountTerms?: string;
  website: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  distance: string;
  duration: string;
}

export interface ReservationSchema {
  checkIn: string;
  checkOut: string;
  createdAt: string;
  guest: number;
  id: string;
  note: string;
  property: PropertySchema;
  propertyId: string;
  status: string;
  updatedAt: string;
  user: UserSchema;
  userId: string;
}
export interface HospitalitySchema {
  createdAt: string;
  id: string;
  updatedAt: string;
  userId: string;
  customerRef: string;
  discountCode?: string;
  discountPromotion?: string;
  discountTerms?: string;
  discountStatus: string;
  expiringAt?: string;
  restaurant: RestaurantSchema;
  restaurantId: string;
}

export interface ReserveDSchema {
  property: PropertySchema;
  guestCount: number;
  checkIn: Date;
  checkOut: Date;
}

export const AmenityCategoryList = Object.entries(AmenityCategory);
export const AvailableLanguageList = Object.entries(Language);

export enum reservationStatus {
  Requested = "Requested",
  Accepted = "Accepted",
  Declined = "Declined",
  Canceled = "Canceled",
}
export enum hospitalityStatus {
  Pending = "Pending",
  Used = "Used",
  Active = "Active",
  Expired = "Expired",
}

export enum CancellationPolicy {
  Flexible = "Flexible",
  Moderate = "Moderate",
  Firm = "Firm",
  LongTerm = "LongTerm",
}

export const CancellationPolicyList = Object.entries(CancellationPolicy).map(
  (item) => item[1]
);
