export const StorageKeys = {
  token: "qs_token",
  user: "qs_user",
  currency: "qs_currency",
  language: "qs_language",
  currencyRates: "qs_currency_rates",
};

export function getStorageData(key: string) {
  try {
    const rawUserData = localStorage.getItem(key);
    if (!rawUserData) {
      return null;
    }

    return JSON.parse(rawUserData);
  } catch (e) {
    return null;
  }
}

export function saveStorageData(key: string, value: any) {
  try {
    if (!value || !key) {
      return;
    }

    const stringifyValue = JSON.stringify(value);
    localStorage.setItem(key, stringifyValue);
    window.dispatchEvent(new Event("storage"));
  } catch (e) {
    return null;
  }
}

export function removeStorageData(key: string) {
  try {
    if (!key) {
      return;
    }

    localStorage.removeItem(key);
    window.dispatchEvent(new Event("storage"));
  } catch (e) {
    return null;
  }
}

export function getCookieData(key: string) {
  try {
    const allCookies = document.cookie;
    const rawUserData = allCookies
      .split(";")
      .find((value) => value.startsWith(` ${key}=`));
    if (!rawUserData) {
      return null;
    }
    const data = rawUserData.split("=")[1];
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
}

export function saveCookieData(key: string, value: any) {
  try {
    if (!value || !key) {
      return;
    }

    const stringifyValue = JSON.stringify(value);
    const expiryDate = new Date();
    expiryDate.setHours(expiryDate.getHours() + 24);
    document.cookie = `${key}=${stringifyValue}; expires=${expiryDate.toUTCString()}`;
  } catch (e) {
    return null;
  }
}

export function removeCookieData(key: string) {
  try {
    if (!key) {
      return;
    }
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() - 30);
    document.cookie = `${key}=''; expires=${expiryDate.toUTCString()}`;
  } catch (e) {
    return null;
  }
}
