import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

const CalendarContainer = ({ className, children }) => {
  return (
    <div className="date-picker-wrapper">
      <div className={className}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export const CustomDatePicker = ({
  dateValue,
  setDateValue,
  placeholder,
  ...props
}) => {
  return (
    <div className="custom-date-picker">
      <DatePicker
        selected={dateValue}
        onChange={(date) => setDateValue(date)}
        className="form-control"
        placeholderText={placeholder}
        dateFormat="MMM d, yyyy"
        dropdownMode="select"
        showMonthDropdown={true}
        showYearDropdown={true}
        calendarContainer={({ className, children }) =>
          CalendarContainer({ className, children })
        }
        {...props}
      />
    </div>
  );
};
