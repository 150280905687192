import React, { createRef } from "react";
import "./styles.scss";
import { Icon } from "@iconify/react";
// import { UserSchema } from "services/network/_schema";
// import { getStorageData, StorageKeys } from "lib/storageManager";
// import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
} from "formik";
import * as Yup from "yup";

import TextInput from "sharedComponents/forms/TextInput";
import ReCAPTCHA from "react-google-recaptcha";
import { addContactMessageRequest } from "services/network/properties";
import useNetworkData from "hooks/useNetworkData";
import { toast } from "react-toastify";
import Button from "sharedComponents/forms/Button";

export const ContactPage = () => {
  // const user: UserSchema = getStorageData(StorageKeys.user);
  // const navigate = useNavigate();
  const recaptchaRef = createRef<ReCAPTCHA>();
  const { isLoading, makeRequest } = useNetworkData();

  // const contactUs = () => {
  //   if (user) {
  //     navigate("/dashboard/support");
  //   } else {
  //     //login
  //   }
  // };
  const initialValues = {
    fullname: "",
    email: "",
    mobile: "",
    message: "",
  };
  const onSubmit = async (
    formValues: FormikValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    const recaptchaValue = recaptchaRef.current?.getValue();
    console.log("recaptchaValue", recaptchaValue);
    const payload = {
      fullname: formValues.fullname,
      mobile: formValues.mobile,
      email: formValues.email,
      message: formValues.message,
    };
    const response = await makeRequest({
      payload,
      apiRequest: addContactMessageRequest,
    });
    if (response.statusCode === 200) {
      toast.success("Message sent!");
      formikHelpers.resetForm();
    }
  };

  const validationSchema = Yup.object({
    fullname: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^\d+$/, "Phone number must contain only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must not exceed 15 digits")
      .required("Phone number is required"),
    message: Yup.string()
      .max(1000, "Message must not exceed 1000 characters")
      .required("Message is required"),
  });

  return (
    <section className="contact-page-wrapper">
      <section className="contact--us-header">
        <div className="container text-white mt-10">
          <div className="row align-items-center text-center">
            <div className="col-12 my-md-auto my-5 d-grid justify-content-center">
              <button
                className="btn btn-outline desktop-item get-app-btn mb-5 aboutBTN"
                type="button"
              >
                <span className="get-app-btn-text">Contact us</span>
              </button>
              <h1 className="fw-bold mb-5 softPColor">We're here to help!</h1>

              <div
                className="mb-6"
                style={{
                  width: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {/* <img className="w-100" src={supporter} alt="headphone" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1380_5438)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M67.8617 76.9549H46.5847C45.5536 76.9549 45.5536 75.4084 46.5847 75.4084H67.065V67.0194C67.065 65.9883 68.6585 65.9883 68.6585 67.0194V76.1582C68.6585 76.6269 68.2835 76.9549 67.8617 76.9549Z"
                      fill="#245FB2"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M43.5852 80.0005H38.8518C33.7903 80.0005 33.7903 72.3613 38.8518 72.3613H43.5852C48.5999 72.3613 48.5999 80.0005 43.5852 80.0005Z"
                      fill="#003580"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M73.2513 51.9749C72.8764 51.9749 72.5483 51.6469 72.5483 51.2251V42.8361C72.5483 32.4788 69.6427 23.4805 64.1125 16.8724C63.597 16.31 62.7534 15.5602 63.4564 14.9978C64.0188 14.5291 64.5343 15.0915 64.9561 15.607H66.7838C66.737 14.9978 67.5806 14.4823 68.0961 15.0915C73.7668 22.3089 76.7663 31.9164 76.7663 42.8361V57.3176C76.7663 58.255 75.3134 58.255 75.3134 57.3176V55.5367L73.3919 51.9749C73.3451 51.9749 73.2982 51.9749 73.2513 51.9749Z"
                      fill="#66C1FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.37376 51.8818L4.68659 55.3499C4.49913 56.006 5.15525 58.0213 3.93674 58.0213C3.56181 58.0213 3.23375 57.6932 3.23375 57.3183V42.8367C3.23375 31.917 6.23316 22.3563 11.9039 15.0921C12.3257 14.5766 14.8096 14.8109 16.075 14.8109C16.6842 14.8109 17.0123 15.5139 16.6374 15.9826L15.8875 16.873C10.3574 23.4811 7.45168 32.4325 7.45168 42.8367V51.2257C7.45168 51.8818 6.79555 52.1162 6.37376 51.8818Z"
                      fill="#66C1FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M69.5021 16.2631H61.9098L61.3474 15.9819C49.7247 4.31228 30.5565 4.26541 18.7463 16.0287C18.6057 16.1693 18.4652 16.2631 18.2308 16.2631H10.6854C10.0762 16.2631 9.7481 15.5601 10.0762 15.0914C24.5577 -4.7797 54.9736 -5.29522 70.1113 15.0914C70.4394 15.5601 70.1113 16.2631 69.5021 16.2631Z"
                      fill="#003580"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.232 67.5803C7.07675 67.5803 3.23375 63.6904 3.23375 58.8164V57.3166C3.23375 52.4426 7.07675 48.5527 12.232 48.5527C12.6538 48.5527 12.9818 48.8808 12.9818 49.2557V66.8304C12.9818 67.2522 12.6538 67.5803 12.232 67.5803Z"
                      fill="#245FB2"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.2783 71.1902C13.2162 71.1902 11.529 69.503 11.529 67.4409V48.6946C11.529 43.7737 18.9807 43.7737 18.9807 48.6946V67.4409C18.9807 69.503 17.3404 71.1902 15.2783 71.1902Z"
                      fill="#003580"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M68.0023 67.5803C67.5805 67.5803 67.0182 67.4865 67.0182 66.8304V49.2557C67.0182 48.6465 67.5805 48.5527 68.0023 48.5527C72.8295 48.5527 76.7663 52.4895 76.7663 57.3166V58.8164C76.7663 63.6435 72.8295 67.5803 68.0023 67.5803Z"
                      fill="#245FB2"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M64.7217 71.1902C62.6596 71.1902 60.9725 69.503 60.9725 67.4409V48.6946C60.9725 43.7737 68.471 43.7737 68.471 48.6946V67.4409C68.471 69.503 66.7838 71.1902 64.7217 71.1902Z"
                      fill="#003580"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1380_5438">
                      <rect width="80" height="80" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-6">
        <div className="row gy-5 text-center">
          <div className="col-md-4 col-sm-6">
            <Icon
              className="text-3xl text-primary-600"
              icon="ion:location-outline"
            />
            <h2 className="xsub-title text-xl my-4">VISIT US</h2>
            <p className="text-sm">
              QSP Group Ltd <br /> 1st Floor <br /> 239 High Street Kensington{" "}
              <br /> London, W8 6SN
            </p>
          </div>
          <div className="col-md-4 col-sm-6 ">
            <Icon
              className="text-3xl text-primary-600"
              icon="clarity:email-line"
            />
            <div className="email-brder">
              <h2 className="xsub-title text-xl my-4">EMAIL US</h2>
              <p className="text-sm">
                <a href="mailTo:info@quickstay.co.uk">info@quickstay.co.uk</a>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <Icon
              className="text-3xl text-primary-600"
              icon="fluent:call-48-regular"
            />
            <h2 className="xsub-title text-xl my-4">CALL US</h2>
            <p className="text-sm">
              <a href="tel:+44 0330 043 5494">+44 0330 043 5494</a> <br />{" "}
              Monday - Sunday 9am to 6pm (UK time)
            </p>
          </div>
        </div>
        {/* <div className="row mb-9">
          <div className="col-lg-8 col-md-10 mx-auto">
            <div className="chat_us_border">
              <div className="d-flex gap-3 align-items-center">
                <div>
                  <img src={discuss} alt="" />
                </div>
                <div>
                  <h4 className="text-primary-600 text-xxl mb-0">
                    {user ? "Chat with Agent" : "Want to speak to us?"}
                  </h4>
                  <p className="text-sm">
                    {user
                      ? "Contact our agents about your booking, and we'll reply as soon as possible."
                      : "Log in to get help with your bookings, listings, account, and more."}
                  </p>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary btn-rounded"
                  onClick={contactUs}
                >
                  {user ? "Chat Now" : "Sign in"}
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <section className="contact-message-wrapper">
        <div className="cmw-container">
          <h1 className="sub-title text-center">Send us a message</h1>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {() => (
              <Form>
                <div>
                  <label className=" ps-3 text-sm  mt-4_5">Full Name</label>
                  <Field
                    component={TextInput}
                    errorStyles={"ps-3"}
                    name="fullname"
                    id="fullname"
                    className="form-control "
                    placeholder="Full Name"
                  />
                </div>
                <div>
                  <label className=" ps-3 text-sm  mt-3 mb-2">
                    Email Address
                  </label>
                  <Field
                    component={TextInput}
                    errorStyles={"ps-3"}
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email Address"
                  />
                </div>
                <div>
                  <label className=" ps-3 text-sm  mt-3 mb-2">
                    Phone Number
                  </label>
                  <Field
                    component={TextInput}
                    errorStyles={"ps-3"}
                    name="mobile"
                    id="mobile"
                    className="form-control"
                    placeholder="Phone Number"
                  />
                </div>
                <div>
                  <label className=" ps-3 text-sm  mt-3 mb-2">Message</label>
                  <Field
                    as="textarea"
                    className="form-control "
                    placeholder="Message"
                    name="message"
                    rows={5}
                  />
                  <div className="text-danger text-xs ps-3">
                    <ErrorMessage name="message" component="span" />
                  </div>
                </div>
                <div className="recaptcher-wrapper">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY || "empty"}
                  />
                </div>
                <Button
                  type="submit"
                  className="mt-4 mx-auto btn btn-primary-600 btn-lg"
                  title="Send"
                  loadingTitle="Sending..."
                  disabled={isLoading}
                  loading={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </section>
  );
};
