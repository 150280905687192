import React from "react";
import { About } from "./about";
import { Featured } from "./featured";
import { HomeBanner } from "./homeBanner";
import { HowItWorks } from "./howItWorks";
import { SmartCall } from "./smartCall";
import "./styles.scss";
import { FloatingArrow } from "sharedComponents/floatingArrow";

export const HomePage = () => {
  return (
    <section className="home-wrapper">
      <HomeBanner />
      <HowItWorks />
      <Featured />
      <SmartCall />
      <About />
      <FloatingArrow />
    </section>
  );
};
