import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./styles.scss";
import useNetworkData from "hooks/useNetworkData";
import {
  getRestaurantRequest,
  saveSelectedRestaurantRequest,
} from "services/network/properties";
import { RestaurantSchema } from "services/network/_schema";
import Loader from "sharedComponents/Loader";
import { Navbar } from "sharedComponents/navbar";
import { Sidebar } from "sharedComponents/sidebar";
import Modal from "react-bootstrap/Modal";

export const Restaurants = () => {
  const [showModal, setShowModal] = useState<string | null>(null);
  const { makeRequest } = useNetworkData();
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [restaurants, setRestaurants] = useState<RestaurantSchema[]>([]);
  const { propertyId, referenceId } = useParams();
  const navigate = useNavigate();

  const getRestaurants = async () => {
    setIsLoading(true);
    const res = await makeRequest({
      apiRequest: getRestaurantRequest,
      payload: { propertyId },
    });
    setIsLoading(false);
    if (res?.data) {
      const { data }: { data: RestaurantSchema[] } = res;
      setRestaurants(data);
    }
  };

  const selectRestuarant = async (id: string) => {
    setSaving(true);
    const res = await makeRequest({
      apiRequest: saveSelectedRestaurantRequest,
      payload: {
        restaurantId: id,
        // pId: propertyId,
        customerRef: referenceId,
      },
    });
    setSaving(false);
    console.debug(res);
    if (res?.statusCode === 200) {
      // setStep(2);
      navigate(`/voucher/${referenceId}`);
    }
  };
  const handleClose = () => setShowModal(null);
  const handleShow = (id: string) => setShowModal(id);

  useEffect(() => {
    getRestaurants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className="choose-restaurants">
      <Navbar isHome={false} main={true} />
      <Sidebar />
      <div className="hospitalityVenue">
        <div className="container mt-9">
          <div className="mx-4 d-flex align-items-center justify-content-center">
            <p className="sub-title mb-0">Select Hospitality Venue</p>
          </div>
        </div>
      </div>
      <div className="body container px-md-6 my-4">
        {isLoading ? (
          <div className="mt-9 mb-10">
            <Loader title="Please wait..." />
          </div>
        ) : (
          <>
            {" "}
            {restaurants.length > 0 ? (
              <>
                {restaurants.map((item) => (
                  <div key={item.id}>
                    <div className="d-flex align-items-center justify-content-between ">
                      <div className="d-flex align-items-center">
                        <img
                          className="restaurant-img"
                          src={item.image}
                          alt=""
                        />
                        <div>
                          <h2 className="xxsub-title t text-xxl">
                            {item.name}
                          </h2>
                          <div className="d-flex gap-2 my-2 mb-3 align-items-center text-primary-600">
                            <div>
                              <Icon
                                icon="fluent:location-16-regular"
                                width={"13"}
                              />
                            </div>
                            <p className="text-sm t maxWidth  m-0">
                              {item.address}
                            </p>
                          </div>
                          <div className="btn-promotion">
                            {item.discountPromotion && (
                              <div className="btn t btn-sm no-pointer btn-primary-600">
                                Promotion
                              </div>
                            )}
                          </div>
                          <p className="text-sm t maxWidth m-0 mt-1 text-primary-600">
                            {item.discountPromotion}
                          </p>
                        </div>
                      </div>
                      <div className="text-primary-600 text-sm-center">
                        {item.rating && (
                          <div className="d-flex gap-2 my-2 mb-5 align-items-center justify-content-end  ">
                            <Icon
                              className="  text-primary-600"
                              icon="ant-design:star-filled"
                              width="16"
                            />

                            <p className="text-lg mb-1">{item.rating}</p>
                          </div>
                        )}

                        {item.discountPromotion && (
                          <button
                            className="btn t btn-md btn-blue-dark"
                            onClick={() => handleShow(item.id)}
                            disabled={saving}
                          >
                            Add Venue
                          </button>
                        )}
                      </div>
                    </div>
                    <hr className="" />
                  </div>
                ))}
              </>
            ) : (
              <p className="text-base text-center mt-9 mb-10">
                Sorry, there are no hospitality venue available at the moment.
              </p>
            )}
          </>
        )}
      </div>
      <div className="footer">
        <div className=" mx-5">
          <Link
            className="text-decoration-none text-sm float-end text-black-500"
            to={"/"}
          >
            Skip till later{" "}
            <Icon className="text-black-000" icon="mi:chevron-double-right" />
          </Link>
        </div>
      </div>
      <Modal
        show={showModal !== null ? true : false}
        onHide={handleClose}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="px-5 py-6 text-center">
            <h1 className="sub-title text-2xl mb-4_5">
              Do you wish to continue <br /> with this venue?
            </h1>
            <p className="text-sm">
              Click "Yes" to proceed or "No" to select another Venue
            </p>
            <div className="d-flex mt-5 justify-content-center gap-4">
              <button
                onClick={() => {
                  if (showModal) {
                    selectRestuarant(showModal);
                  }
                }}
                className="btn btn-sm btn-primary-600 px-5 py-4_5"
              >
                Yes
              </button>
              <button
                onClick={handleClose}
                className="btn btn-sm btn-grey-50 px-5 py-4_5"
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};
