import React from "react";
import call1 from "assets/images/call1.svg";
import call2 from "assets/images/call2.svg";
import call3 from "assets/images/call3.svg";

export const SmartCall = () => {
  const showMore = (textId: string, btnId: string) => {
    const moreBtn = document.querySelector(btnId);
    const text = document.querySelector(textId);
    if (text && moreBtn) {
      text.classList.toggle("show-more");
      if (moreBtn.innerHTML === "More...") {
        moreBtn.innerHTML = "Less";
      } else {
        moreBtn.innerHTML = "More...";
      }
    }
  };
  return (
    <section className="smartcall-wrapper">
      <div className="container">
        <h2 className="sub-title text-primary-600 mt-md-5 mt-1 text-center mb-md-10 mb-0 Nanum ">
          {/* The Smarter Way to Book a Property, <br /> The Perfect Way to Find a
          Home */}
          Where Quality Living Meets Premium Hospitality
        </h2>

        <div className="call">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4 pp20">
              <div className="call-img">
                <img src={call1} alt="" className="call-img-image" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 my-auto ms-auto pp-20">
              <h2 className="xsub-title mb-4 Nanum  go22">
                Hospitality Just Got Simpler
              </h2>
              <div id="call-1">
                <p className="text-xs text-black-200">
                  Add hospitality services to your stay in just a few clicks!
                  Unlock access to premium venues and exclusive prices, with up
                  to 40% off selected venues! Start planning your trip with ease
                  by adding venues such as restaurant dining, spas trips and
                  more to your stay.
                  <span className="more-text">
                    <br /> <br />
                    Keep all your booking details in one place on the QuickStay
                    app or platform and start preparing for your next adventure!
                    Enquire about additional services such as airport
                    transportation, boat charters, concert tickets and allow us
                    to help take your trip to the next level!
                  </span>{" "}
                  <span
                    className="more-btn more-btn-1 text-primary-500"
                    onClick={() => showMore("#call-1", ".more-btn-1")}
                  >
                    More...
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="call">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4 order-1 order-md-2 pp20">
              <div className="call-img leftt">
                <img src={call2} alt="" className="left call-img-image" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 my-auto me-auto order-2 order-md-1 pp-20">
              <h2 className="xsub-title mb-4 Nanum  go22">
                Flexible Living Without Compromise
              </h2>
              <div id="call-2">
                <p className="text-xs text-black-200">
                  QuickStay brings flexibility and convenience into one place.
                  Find quality-approved properties in safe neighbourhoods, with
                  convenient amenities for weekly and monthly stays. Say goodbye
                  to long-term leases and hello to flexible living, on your
                  terms!
                  <span className="more-text">
                    <br /> <br />
                    Whether you’re a professional, student or simply visiting,
                    QuickStay’s diverse selection of move-in- ready properties
                    have been vetted by our expert so you have the right
                    expectation before your stay. Sit back, relax and let us
                    take care of the rest! Whether you’re a professional,
                    student or simply visiting, QuickStay’s diverse selection of
                    move-in- ready properties have been vetted by our expert so
                    you have the right expectation before your stay. Sit back,
                    relax and let us take care of the rest!
                  </span>{" "}
                  <span
                    className="more-btn more-btn-2 text-primary-500"
                    onClick={() => showMore("#call-2", ".more-btn-2")}
                  >
                    More...
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="call">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4 pp20">
              <div className="call-img">
                <img src={call3} alt="" className="call-img-image" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 my-auto ms-auto pp-20">
              <h2 className="xsub-title mb-4 Nanum  go22">
                Corporate Travel and Digital Nomad Stays
              </h2>
              <div id="call-3">
                <p className="text-xs text-black-200">
                  Discover turnkey properties with the perfect blend of comfort
                  and functionality. Our work-friendly properties offer key
                  amenities, such as high-speed internet, dedicated workstations
                  or home office’s. Stay in prime locations with plenty of local
                  entertainment, or peaceful serene settings ideal for
                  exploring.
                  <span className="more-text">
                    <br /> <br />
                    Our work-friendly properties are a place you can call home,
                    while enjoying the flexibility to stay for as long as you
                    wish.
                  </span>{" "}
                  <span
                    className="more-btn more-btn-3 text-primary-500"
                    onClick={() => showMore("#call-3", ".more-btn-3")}
                  >
                    More...
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
