import React from "react";
import "./styles.scss";

export const CancellationPolicyPage = () => {
  return (
    <section className="container mb-9">
      <h2 className="sub-title my-6">Cancellation Policy</h2>
      <h3 className="xsub-title text-lg text-uppercase">
        Cancellation policy for guests
      </h3>
      <p>
        If a guest decides they want to cancel a booking, they may be liable to
        a refund depending on the cancellation policy associated with the
        booking. <br /> <br />
        The cancellation policy is available on each summary page prior to
        confirming a booking.
        <br />
        <br />
        If a guest does not pay the remaining balance after an initial deposit
        has been taken on the date specified in the booking confirmation, the
        total deposit will not be refunded and the booking will then be
        automatically cancelled. If you cancel your booking before paying the
        total balance, you will not be liable to a refund either. Once the total
        balance has been paid you can receive 50% refund of the total amount
        should you, (the guest), cancel 30 days before the check-in date. Should
        you cancel 21days before check-in then you will be liable to a 25%
        refund and should you cancel after 21 days before your stay you will not
        be entitled a refund.
      </p>
      <br />

      <h3 className="xsub-title text-lg text-uppercase">Flexible</h3>
      <p>
        Cancellations up to 24 Hours prior to check-in are eligible for a full
        refund.
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">Moderate</h3>
      <p>
        Cancellation up to 7 days prior to arrival is eligible for a full
        refund. Cancellation after 7 days prior to arrival guests receive a
        partial refund of 50% of the nightly fee.
      </p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">Firm</h3>
      <p>Bookings are non-refundable and not subject to a fund</p>
      <br />
      <h3 className="xsub-title text-lg text-uppercase">
        Long-Term stays: 30+ nights
      </h3>
      <p>
        Long-term bookings over 30 nights are subject to a full refund if the
        booking is cancelled 30 nights prior to arrival. Owners are eligible to
        state their own cancellation policy on their listing, which must be
        included in their property description/listing page.
      </p>
    </section>
  );
};
