import React from "react";
import Carousel from "react-bootstrap/Carousel";
import item1 from "assets/images/banner.png";
import item2 from "assets/images/banner2.svg";
import item3 from "assets/images/slideshow image 2.jpg";
import item4 from "assets/images/slideshow.jpg";
import { SearchProperty } from "sharedComponents/searchProperty";

export const HomeBanner = () => {
  return (
    <>
      <section className="banner-wrapper">
        <div className="carousel-wrapper">
          <Carousel fade controls={false}>
            {[item1, item2, item3, item4].map((item, index) => (
              <Carousel.Item key={index}>
                <div className="carousel-img">
                  <img src={item} alt="First slide" />
                </div>
                <Carousel.Caption className="goCar">
                  <div className="container">
                    <div className="showcaseTexter">
                      <h1 className="nfont-10 d-md-block d-none">
                        Enjoy quality approved stays, <br /> flexible living and
                        premium hospitality
                      </h1>
                      <h1 className="nfont-10 d-md-none d-block ">
                        Enjoy Quality stays, Flexibility, <br /> and Premium
                        Hospitality
                      </h1>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
      <SearchProperty />
    </>
  );
};
