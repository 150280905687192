import React from "react";
import "./styles.scss";
// import logo from "assets/images/QuickStayWhiteLogo.svg";
import logo from "assets/images/logo-white.png";
import cards from "assets/images/cardsFull.png";
import googleplay from "assets/images/google-play-badge.29ed87ab.svg";
import appstore from "assets/images/appstore.9dabe7cb.svg";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const Socials = () => {
  return (
    <>
      <div className="d-flex mb-4 gap-4">
        <Link to="" className="text-decoration-none ">
          <img className="app-img" src={appstore} alt="appstore" />
        </Link>
        <Link to="" className="text-decoration-none ">
          <img className="app-img" src={googleplay} alt="googleplay" />
        </Link>
      </div>
      <div className="d-flex mb-4">
        <a
          href="https://www.instagram.com"
          target="_blank"
          className="text-decoration-none text-white "
          rel="noreferrer"
        >
          <Icon
            className="text-2xl me-5 mobile- icon-resize"
            icon="akar-icons:instagram-fill"
          />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          className="text-decoration-none text-white "
          rel="noreferrer"
        >
          <Icon
            className="text-2xl me-5 mobile- icon-resize"
            icon="fa6-brands:x-twitter"
          />
        </a>
        <a
          href="https://www.facebook.com"
          target="_blank"
          className="text-decoration-none text-white"
          rel="noreferrer"
        >
          <Icon
            className="text-2xl me-5 mobile- icon-resize"
            icon="eva:facebook-outline"
          />
        </a>
        <a
          href="https://www.youtube.com"
          target="_blank"
          className="text-decoration-none text-white mb-3"
          rel="noreferrer"
        >
          <Icon className="text-2xl icon-resize" icon="ri:youtube-line" />
        </a>
      </div>
    </>
  );
};

export const Footer = () => {
  return (
    <footer className="mt-auto">
      <div className="container">
        <div className="row gy-5_6 mt-2">
          <div className="col-lg-3 col-md-4 col-12 mx-auto ">
            <div className=" footer-left">
              <img className="logo-img" src={logo} alt="logo" />
              <img className="cards-img" src={cards} alt="cards" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6 mx-auto ">
            <div className="d-flex justify-content-center">
              <ul className=" list-unstyled">
                <li className="mb-2">
                  <Link
                    to="/terms"
                    className="text-decoration-none text-sm text-white "
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/privacy"
                    className="text-decoration-none text-sm text-white "
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/cancellation-policy"
                    className="text-decoration-none text-sm text-white "
                  >
                    Cancellation Policy
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/booking-contract"
                    className="text-decoration-none text-sm text-white "
                  >
                    Booking Contract
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6 mx-auto">
            <div className="d-flex justify-content-center">
              <div>
                <ul className=" list-unstyled">
                  <li className="mb-2">
                    <Link
                      to="/"
                      className="text-decoration-none text-sm text-white "
                    >
                      Home
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to="/about"
                      className="text-decoration-none text-sm text-white "
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to="/contact"
                      className="text-decoration-none text-sm text-white "
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      to="/"
                      className="text-decoration-none text-sm text-white "
                    >
                      Help Support
                    </Link>
                  </li>
                </ul>
                <div className=" d-md-block d-none">
                  <Socials />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12 d-md-block d-none"></div>
          <div className="col-md-4 col-12 d-md-none d-block mt-0">
            <div className="d-flex justify-content-center">
              <div>
                <Socials />
              </div>
            </div>
          </div>
          <div className="col-12 mtt">
            <p className="text-sm text-center text-white my-4">
              © {new Date().getFullYear()} QuickStay, All Rights Reserved.
            </p>
          </div>
        </div>
        {/* <p className="text-sm text-white mt-5">
          © {new Date().getFullYear()} QuickStay, All Rights Reserved.
        </p> */}
      </div>
    </footer>
  );
};
