import { toast } from "react-toastify";
import { APIResponse } from "services/network/_schema";

export const handleGeneralErrors = (response: APIResponse) => {
  if (response?.data) {
    toast.error(response.data.message);
  } else {
    toast.error("Sorry, an error occurred!");
  }
};
