import React, { ReactNode } from "react";
import { Sidebar } from "sharedComponents/sidebar";
import { Navbar } from "sharedComponents/navbar";
import "./styles.scss";
import { Footer } from "sharedComponents/footer";

export const MainLayout = ({
  children,
  isHome,
  main,
}: {
  children: ReactNode;
  isHome: boolean;
  main: boolean;
}) => {
  return (
    <section className="layout-wrapper">
      <Sidebar main={main} />
      <div>
        <Navbar main={main} isHome={isHome} />
        <div>{children}</div>
      </div>
      <Footer />
    </section>
  );
};
