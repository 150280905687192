import React from "react";
import "./styles.scss";
// import Dpage from "assets/images/Dpage.svg";
import iPhone13Proy from "assets/images/iPhone13Proy.svg";
import google_play_badge from "assets/images/google-play-badge.2.svg";
import app_store_badge from "assets/images/app-store-badge.svg";
import code_dir from "assets/images/code-dir.svg";
import download_Qrcode from "assets/images/download-code.svg";
// import { Link } from "react-router-dom";

export const DowloadAppPage = () => {
  return (
    <section className="download-page">
      <div className="dp-wrapper">
        <div className="dp-flex-wrapper">
          <div>
            <h1 className=" vietnamF text-22xl mb-5 lh-1">
              Download <br /> our new app!
            </h1>
            <div className="d-flex gap-6 align-items-center mb-4">
              <div>
                <img className="Qr-img" src={code_dir} alt="Download" />
                <br />
                <img className="Qr-img" src={download_Qrcode} alt="Download" />
              </div>
              <div className="mt-5">
                <img
                  className="badge-img"
                  src={app_store_badge}
                  alt="Download"
                />
                <br />
                <img
                  className="badge-img"
                  src={google_play_badge}
                  alt="Download"
                />
              </div>
            </div>
            <h1 className="DMSerif text-center text-xxl mt-5 mb-0">
              It’s better on the QuickStay app
            </h1>
            <p className="text-2xs text-center">
              Browse properties, add listings, and access <br /> your booking
              details anywhere — all from the app.
            </p>
          </div>

          <img className="mobile-img" src={iPhone13Proy} alt="Download" />
        </div>
      </div>
    </section>
  );
};
