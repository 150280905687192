import React, { useState, useEffect } from "react";
import "./styles.scss";
import logoWhite from "assets/images/logo-white.png";
import getApproved1 from "assets/images/getApproved-1.svg";
import getApproved2 from "assets/images/getApproved-2.svg";
import getApproved3 from "assets/images/getApproved-3.svg";
import { Link } from "react-router-dom";
import { AuthContainer } from "sharedComponents/authContainer";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { Icon } from "@iconify/react";

export const GetApproved = () => {
  const [user, setUser] = useState(getStorageData(StorageKeys.user));
  const [showLogin, setShowLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const handleShowLogin = () => {
    if (!user) {
      setShowLogin(true);
    }
  };
  const handleOpenRegister = () => {
    if (!user) {
      setOpenRegister(true);
    }
  };

  const updateUser = () => {
    setUser(getStorageData(StorageKeys.user));
  };

  useEffect(() => {
    window.addEventListener("storage", updateUser);
    return () => window.removeEventListener("storage", updateUser);
  }, []);
  return (
    <>
      <section className="getApproved">
        <div className="getApproved-bgWrapper">
          <div className="getApproved-banner">
            <img className="getApprove-logo" src={logoWhite} alt="logo" />
            <p className="testt-none sub-title text-white-000">
              Join a diverse selection of quality-approved <br /> homes and
              hotels, meticulously reviewed by our <br /> experts.
            </p>
            <p className="testt sub-title text-white-000">
              Join a diverse selection of quality-approved homes and hotels,
              meticulously reviewed by our experts.
            </p>
            <div className="w-25">
              <Link
                className="text-decoration-none"
                to={user ? "/dashboard/listings/add" : "#!"}
                onClick={handleShowLogin}
              >
                <button className="w-100 mt-5 btn btn-lg btn-primary bg-primary-600">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
          <div className="container">
            <div className="row gy-7 gx-6 pt-5">
              <div className="col-md-5 col-12">
                <img
                  className="getapproved-img"
                  src={getApproved1}
                  alt="getApprovedIllustration"
                />
              </div>
              <div className="col-md-7 d-flex align-items-center col-12">
                <div className="d-flex justify-content-center ">
                  <p className="text-sm text-black-000">
                    Showcase your property to discerning guests who value stays
                    in quality homes and hotels that they can rely on. Take the
                    next steps in providing stays to corporate travellers,
                    digital nomads and vacation guests who prefer a
                    professionally managed feel to their stay. We’re here for
                    each guest, whenever they need us, so you can spend more
                    time on the important things.
                  </p>
                </div>
              </div>
            </div>
            <div className="row gy-7 gx-6 pt-7">
              <div className="col-md-7 d-flex align-items-center col-12 order-md-1 order-2">
                <div className="d-flex justify-content-center">
                  <p className="text-sm text-black-000">
                    QuickStay provides a seamless host experience for owners and{" "}
                    property managers with the help of our dedicated account
                    managers. Our team takes care of the time-consuming details,
                    while the QuickStay all-in-one
                    <Link
                      className="text-decoration-none text-primary-600"
                      to="/download-app"
                    >
                      {" "}
                      mobile app
                    </Link>{" "}
                    and platform is designed to make it easy for guests to
                    communicate with us, allowing owners/hosts to effortlessly
                    earn income on their property – without the high fees!
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-12 order-md-2 order-1">
                <img
                  className="getapproved-img"
                  src={getApproved2}
                  alt="getApprovedIllustration"
                />
              </div>
            </div>
            <div className="row gy-7 gx-6 pt-7 pb-8">
              <div className="col-md-5 col-12">
                <img
                  className="getapproved-img"
                  src={getApproved3}
                  alt="getApprovedIllustration"
                />
              </div>
              <div className="col-md-7 d-flex align-items-center col-12">
                <div className="d-flex justify-content-center">
                  <p className="text-sm text-black-000">
                    Enjoy our cost-effective and fully integrated management
                    platform for{" "}
                    <span className="text-primary-600">
                      owners, property managers, estate agents and hotels
                    </span>
                    . Let us handle the logistics of your short-term rental
                    while you sit back and earn income from bookings
                    hassle-free. Owners can also list their homes on QuickStay
                    subject to approval and benefit from quality short to
                    mid-term quality bookings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-9 mt-6">
          <p className="text-lg xxsub-title mb-0 text-black-000">
            Apply to list with us today and start monetising in no time!
          </p>
          <p className="text-lg xxsub-title text-black-000">
            Hospitality vendors can register{" "}
            <Link
              className="text-decoration-none"
              to={user ? "/dashboard/listings/add" : "#!"}
              onClick={handleOpenRegister}
            >
              <span className="text-primary-600">here.</span>
            </Link>
          </p>
          <div className="btn-listing">
            <Link
              className="text-decoration-none"
              to={user ? "/dashboard/listings/add" : "#!"}
              onClick={handleShowLogin}
            >
              <button className="w-100 btn btn-lg btn-primary bg-primary-600">
                Submit a listing
              </button>
            </Link>
          </div>
        </div>
      </section>

      <AuthContainer
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        handleShowLogin={handleShowLogin}
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
        handleOpenRegister={handleOpenRegister}
      />
    </>
  );
};
