import Axios from "services/api";

export const getAllAmenitiesRequest = () => {
  return Axios.get("/amenity/all");
};

export const getAllFeesRequest = () => {
  return Axios.get("/fees/all");
};

export const addPropertyRequest = (data) => {
  return Axios.put("/property/add", data);
};
export const addContactMessageRequest = (data) => {
  return Axios.put("/support/contact", data);
};
export const addPropertyImageRequest = (data) => {
  return Axios.put("/property/picture/add", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updatePropertyRequest = ({ payload, id }) => {
  return Axios.patch(`/property/update/${id}`, payload);
};

export const updatePropertyStatusRequest = ({ status, id }) => {
  return Axios.post(`/property/${status}/${id}`);
};

export const updatePropertyImageRequest = (id) => {
  return Axios.patch(`/property/picture/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const activatePropertyRequest = (id) => {
  return Axios.post(`/property/activate/${id}`);
};

export const deactivatePropertyRequest = (id) => {
  return Axios.post(`/property/deactivate/${id}`);
};

export const deletePropertyImageRequest = ({ propertyId, pictureId }) => {
  return Axios.delete(`/property/picture/${propertyId}/${pictureId}`);
};

export const getUserListingsRequest = () => {
  return Axios.get("/property/user/all");
};

export const getAllListingsRequest = () => {
  return Axios.get("/property/all");
};
export const getAllListingsRequest2 = () => {
  return Axios.get("/property/featured");
};

export const getPaginatedListingsRequest = (params) => {
  return Axios.get("/property/all2", { params });
};

export const getPropertyByIdRequest = (id) => {
  return Axios.get(`/property/${id}`);
};
export const getvoucherByIdRequest = (id) => {
  return Axios.get(`/restaurant/fetch-discount/${id}`);
};
export const verifyVoucherRequest = (discountCode) => {
  return Axios.get(`/restaurant/verify-discount/${discountCode}`);
};
export const acceptVoucherRequest = (discountCode) => {
  return Axios.get(`/restaurant/use-discount/${discountCode}`);
};
export const getPropertyByIdRequest2 = (id) => {
  return Axios.get(`/property/owner/${id}`);
};

export const getBlockedDatesRequest = (id) => {
  return Axios.get(`/booking/blocked/${id}`);
};

export const getUserBookingsRequest = () => {
  return Axios.get("/booking");
};

export const getUserWishlistRequest = () => {
  return Axios.get("/property/wishlist");
};

export const addWishlistRequest = (id) => {
  return Axios.post(`/property/wishlist/${id}`);
};

export const removeWishlistRequest = (id) => {
  return Axios.delete(`/property/wishlist/${id}`);
};

export const getOwnerPropBookingsRequest = (params) => {
  return Axios.get("/booking/property", { params });
};

export const downloadOwnerPropBookingsRequest = () => {
  return Axios.get("/booking/download", { responseType: "blob" });
};

export const bookPropertyRequest = ({ id, data }) => {
  return Axios.post(`/property/price/${id}`, data);
};

export const getCouponWorthRequest = ({ coupon }) => {
  return Axios.get(`/discount/code/${coupon}`);
};

export const cancelBookingRequest = (data) => {
  return Axios.post("/booking/cancellations", data);
};

export const getNearbySearchRequest = (data) => {
  return Axios.post("/nearby", data);
};

export const searchPropertyRequest = (data) => {
  return Axios.post("/property/filter2", data);
};

export const getRestaurantRequest = (data) => {
  return Axios.post("/restaurant", data);
};

export const saveSelectedRestaurantRequest = (data) => {
  return Axios.post("/restaurant/save", data);
};

export const reservePropertyRequest = ({ id, data }) => {
  return Axios.post(`/property/reserve/${id}`, data);
};

export const getOwnerPropReservationsRequest = (params) => {
  return Axios.get("/property/owners/reservations", { params });
};

export const getUserReservationsRequest = (params) => {
  return Axios.get("/property/reservations", { params });
};
export const getUserHospitalityRequest = (params) => {
  return Axios.get("/restaurant/all", { params });
};

export const ownerDeclineReservationRequest = ({ id, data }) => {
  return Axios.patch(`/property/reserve/${id}`, data);
};

export const ownerApproveReservationRequest = ({ id, data }) => {
  return Axios.patch(`/property/reserve/${id}`, data);
};

export const userCancelReservationRequest = (id) => {
  return Axios.delete(`/property/reserve/${id}`);
};
