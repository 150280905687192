import { Icon } from "@iconify/react";
import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { PropertySchema } from "services/network/_schema";
import { currencyObject } from "utilities/country";
import {
  addWishlistRequest,
  removeWishlistRequest,
  updatePropertyStatusRequest,
} from "services/network/properties";
import useNetworkData from "hooks/useNetworkData";
import { toast } from "react-toastify";
import { LayoutContext } from "context/layoutContext";
import notAvailable from "assets/images/notAvailable.png";
import Modal from "react-bootstrap/Modal";

export const PropertyCard = ({
  showViewListing,
  showDelete,
  property,
  showFavorite,
}: {
  showViewListing?: boolean;
  showDelete?: boolean;
  property: PropertySchema;
  showFavorite?: boolean;
}) => {
  const { makeRequest } = useNetworkData();
  const [deleting, setDeleting] = useState(false);
  const { rates, currency, getPrice } = useContext(LayoutContext);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const closeAcceptModal = () => setOpenAcceptModal(false);
  const navigate = useNavigate();

  const toggleFavorite = async () => {
    if (property.isWishlist) {
      property.isWishlist = false;
      const response = await makeRequest({
        payload: property.id,
        apiRequest: removeWishlistRequest,
      });
      if (!response) {
        property.isWishlist = true;
      }
    } else {
      property.isWishlist = true;
      const response = await makeRequest({
        payload: property.id,
        apiRequest: addWishlistRequest,
      });
      if (!response) {
        property.isWishlist = false;
      }
    }
  };

  const deactivate = async () => {
    // if (window.confirm("Are you sure you want to deactivate this property?")) {
    setDeleting(true);
    const response = await makeRequest({
      payload: { status: "deactivate", id: property.id },
      apiRequest: updatePropertyStatusRequest,
    });
    setDeleting(false);
    if (response.statusCode === 200) {
      toast.success(`Property Deactivated!`);
      closeAcceptModal();
    }
    navigate("/dashboard/listings");
  };

  return (
    <>
      <div className="property-card">
        <Link to={`/property/${property?.id}`}>
          {" "}
          <img
            src={
              property.featuredImage
                ? `${process.env.REACT_APP_ASSETS_URL}/${property.featuredImage}`
                : notAvailable
            }
            alt={property?.name}
          />
        </Link>
        {showDelete && (
          <div className="view-icons">
            <div
              className="icon text-black-000 delete"
              onClick={() => setOpenAcceptModal(!openAcceptModal)}
            >
              {deleting ? (
                <span className="spinner-border spinner-border-md" />
              ) : (
                <Icon icon="fluent:delete-24-regular" />
              )}
            </div>
            <div className="icon">
              <Link
                className="text-decoration-none text-black-000"
                //  to={`/property/${property?.id}`}
                to={`/dashboard/listing/${property.id}`}
              >
                <Icon icon="ic:outline-edit" />
              </Link>
            </div>
          </div>
        )}
        {showFavorite && (
          <div className="view-icons">
            <div className="icon dark-bg" onClick={toggleFavorite}>
              <Icon
                icon={property.isWishlist ? "el:heart" : "el:heart-empty"}
                color={property.isWishlist ? "#42C9F8" : ""}
              />
            </div>
          </div>
        )}
        <div className="p-4">
          <div className="p-name-wrap mb-2">
            <h2
              className="xsub-title text-capitalize p-name beviet"
              id="no_w_translate"
            >
              <Link
                to={`/property/${property?.id}`}
                className="text-primary-600 text-decoration-none beviet"
              >
                {property?.name}
              </Link>
            </h2>
          </div>
          <div className="d-flex gap-2 mb-4 text-primary-600 address-wrap">
            <div style={{ marginTop: "-4px" }}>
              <Icon icon="fluent:location-16-regular" />
            </div>
            <p className="text-sm m-0 address beviet" id="no_w_translate">
              {property.addressLine1}
            </p>
          </div>
          <div className="border-grey-400">
            <p className="fw-extra-bold text-sm text-black-500 beviet">Price</p>
            <p className="fw-medium text-sm text-black-500 beviet">
              {currencyObject[`${rates ? currency : property.currency}`] || ""}
              {getPrice(property.price, property.currency)} /{" "}
              {property.bookingDurations}
            </p>
          </div>
          <div className="border-grey-400 mt-4">
            <p className="fw-extra-bold text-sm text-black-500 beviet">
              Bedroom
            </p>
            <p className="fw-medium text-sm text-black-500 beviet">
              {property.bedroom}
            </p>
          </div>
          <div className="d-flex justify-content-between my-4">
            <p className="fw-extra-bold text-sm text-black-500 beviet">Type</p>
            <p className="fw-medium text-sm text-black-500 beviet">
              {property.propertyType}
            </p>
          </div>
          {showViewListing && (
            <Link
              className="text-decoration-none text-end"
              to={`/property/${property.id}`}
            >
              <button className="btn btn-md btn-blue-dark  ms-auto">
                <Icon icon="akar-icons:eye" className="me-3" />
                <p className="mb-0 beviet">Book Now</p>
              </button>
            </Link>
          )}
        </div>
      </div>
      <Modal
        className="modal-mobile"
        show={openAcceptModal}
        onHide={closeAcceptModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="px-5 py-6 text-center">
            <h1 className="sub-title text-2xl mb-4_5">
              Are you sure you want to deactivate this property?
            </h1>
            <div className="d-flex mt-5 justify-content-center gap-4">
              <button
                onClick={() => {
                  deactivate();
                }}
                className="btn btn-sm btn-primary-600 px-5 py-4_5"
              >
                Yes
              </button>
              <button
                onClick={closeAcceptModal}
                className="btn btn-sm btn-grey-50 px-5 py-4_5"
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
