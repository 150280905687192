import React from "react";
import "./styles.scss";

export const EmptyContainer = ({ title }) => {
  return (
    <div className="dashboard-empty-list">
      <span>Empty {title} </span>
    </div>
  );
};
