import { Icon } from "@iconify/react";
import QRCode from "react-qr-code";
import guy from "assets/images/voucher-img.svg";
import active from "assets/images/Active.svg";
import expired from "assets/images/EXPIRED.svg";
import useNetworkData from "hooks/useNetworkData";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  acceptVoucherRequest,
  getvoucherByIdRequest,
  verifyVoucherRequest,
} from "services/network/properties";
import Loader from "sharedComponents/Loader";
import { DateTime } from "luxon";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import { toast } from "react-toastify";

export const HospitalityCard = ({
  hospitalityPage,
  acceptance,
}: {
  hospitalityPage?: boolean;
  acceptance?: boolean;
}) => {
  const { makeRequest } = useNetworkData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [myVoucher, setMyVoucher] = useState<any>({});
  const { ref, discountCode } = useParams();
  const navigate = useNavigate();
  const emailAddress = "info@quickstay.co";
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const closeAcceptModal = () => setOpenAcceptModal(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const windowHost = `${window.location.protocol}//${window.location.hostname}`;
  // const windowHost = window.location.protocol+"//"+window.location.hostname;

  const getVoucher = async () => {
    const response = await makeRequest({
      apiRequest: discountCode ? verifyVoucherRequest : getvoucherByIdRequest,
      payload: discountCode || ref,
      returnError: true,
    });
    console.log(response);
    if (response && !response.error) {
      setIsLoading(false);
      setMyVoucher(response.data);
    } else if (response.error?.restaurantURL) {
      const splittedUrl = response.error.restaurantURL.split("/");
      const pId = splittedUrl[splittedUrl.length - 2];
      const refId = splittedUrl[splittedUrl.length - 1];
      navigate(`/choose-restaurant/${pId}/${refId}`);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getVoucher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrint = () => {
    setIsPrinting(true);
    window.print();
    window.onafterprint = () => setIsPrinting(false);
  };

  const acceptVoucher = async () => {
    const response = await makeRequest({
      apiRequest: acceptVoucherRequest,
      payload: discountCode,
    });
    if (response && response.statusCode === 200) {
      toast.success("Voucher Accepted!");
      closeAcceptModal();
    }
    navigate("/");
  };

  return (
    <>
      {isLoading ? (
        <div className="py-10">
          <Loader title="Fetching..." />
        </div>
      ) : (
        <div className="hospitality-wrapper">
          {acceptance && (
            <div className="text-center acceptance-w">
              <h1 className="sub-title text-2xl mb-3">
                Voucher Acceptance Page
              </h1>
              <p className="text-sm">
                In order to make the voucher functional kindly accept the
                voucher by clicking on the accept button
              </p>
            </div>
          )}
          <div
            className={`hospitality-container-1 ${
              hospitalityPage && !acceptance
                ? "hospitality-page"
                : hospitalityPage && acceptance
                ? "view-hospitality acceptance-page"
                : "view-hospitality"
            }`}
          >
            <div
              className={`hospitality-bg-1 ${
                hospitalityPage && !acceptance
                  ? "hospitality-page"
                  : hospitalityPage && acceptance
                  ? "view-hospitality acceptance-page"
                  : "view-hospitality"
              }`}
            >
              <div
                className={
                  acceptance
                    ? "d-flex justify-content-between align-items-center "
                    : "row"
                }
              >
                <div
                  className={`col-md-6 barcode-col ${
                    hospitalityPage && !acceptance
                      ? "col-12 "
                      : hospitalityPage && acceptance
                      ? " col-8 position-relative"
                      : "col-12 "
                  }`}
                >
                  <p className="text-sm mb-4_5">
                    {myVoucher?.restaurant?.discountPromotion}
                  </p>
                  {myVoucher?.user?.status === "Active" ? (
                    <button className="btn px-4_5 btn-sm bg-white-000 text-black-000 btn-primary fst-italic fw-semi-bold">
                      Valid until{" "}
                      {DateTime.fromJSDate(
                        new Date(myVoucher?.user?.booking_end_date)
                      ).toLocaleString(DateTime.DATE_MED)}
                    </button>
                  ) : (
                    <button className="btn px-4_5 btn-sm bg-white-000 text-error btn-primary fst-italic fw-semi-bold">
                      Expired
                    </button>
                  )}
                  {!acceptance && (
                    <>
                      <p className="text-sm mt-4_5 mb-0">
                        Please present the QR code in this <br />
                        voucher at the chosen hospitality venue
                      </p>
                      <div className="barcode-wrapper">
                        <QRCode
                          value={myVoucher?.user?.qrcode_url}
                          className="barcode"
                          style={{
                            height: "auto",
                            maxWidth: "160px",
                            width: "100%",
                          }}
                        />
                      </div>
                      <div className="pb-5">
                        <Link
                          className="text-decoration-none text-xs text-white-000"
                          to={`/voucher/verify/${myVoucher?.user?.discountCode}`}
                        >
                          {windowHost}/voucher/verify/
                          {myVoucher?.user?.discountCode}
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                {acceptance ? (
                  <div className="my-auto">
                    {myVoucher?.user?.status === "Active" && (
                      <button
                        className="rounded d-flex justify-content-center align-items-center py-sm-4 px-sm-5 px-3 py-2 btn-primary "
                        onClick={() => setOpenAcceptModal(!openAcceptModal)}
                      >
                        Accept
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="col-md-6 col-12 guy-col">
                    <img className="guy" src={guy} alt="hospitality-img" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hospitality-bg-2">
            <div className="hospitality-bg-container">
              <div className="restaurant-wrapper">
                <h1 className="pb-2 xxsub-title text-lg fw-regular text-black-000">
                  Chosen Hospitality Venue
                </h1>
                <div className="row pb-3 pt-3 gx-4">
                  <div className="col-md-3 col-3">
                    <img
                      src={myVoucher?.restaurant?.image}
                      style={{ borderRadius: "5px" }}
                      alt="chosenRestaurant"
                    />
                  </div>
                  <div className="col-md-7 col-6 mobile-vt">
                    <div className="d-flex align-items-center pt-md-2">
                      <Icon
                        className="text-primary-600 me-3 text-xl"
                        icon="ic:round-star"
                      />{" "}
                      <p className="text-xs text-black-500 mb-1">
                        {myVoucher?.restaurant?.rating}
                      </p>
                    </div>
                    <h1 className="xxsub-title text-xxl mt-md-2 mb-md-3">
                      {myVoucher?.restaurant?.name}
                    </h1>
                    <div className="d-flex align-items-center">
                      <Icon
                        className="text-primary-600 me-3 text-xl"
                        icon="pajamas:location"
                      />{" "}
                      <p className="text-xs text-primary-600 mb-1">
                        {myVoucher?.restaurant?.address}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2 col-3">
                    {myVoucher?.user?.status === "Active" ? (
                      <div>
                        <img className="v-status" src={active} alt="active" />
                      </div>
                    ) : (
                      <div>
                        <img className="v-status" src={expired} alt="expired" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="br"></div>
              <p className="text-xs pb-5 text-black-300 px-4_5 fst-italic">
                {myVoucher?.restaurant?.discountTerms || (
                  <>
                    All third-party services offered through the QuickStay
                    platform and or QuickStay mobile application, including
                    venues and other external services are independent providers
                    to QuickStay. As such all third-party services are subject
                    to their own terms and conditions. QuickStay cannot
                    guarantee the safety, reliability or quality of third-party
                    venues and services. Any issues or disputes related to
                    external third-party services should be resolved directly
                    with the respective service provider(s)
                    <span
                      className=" text-primary-600 click"
                      onClick={() => setOpenModal(!openModal)}
                    >
                      ...More...
                    </span>
                  </>
                )}
              </p>
              {!acceptance && (
                <div className="d-flex flex-wrap justify-content-between px-4_5">
                  <button
                    className="btn btn-md btn-greyy mb-3 me-2 no-print"
                    onClick={handlePrint}
                    disabled={isPrinting}
                  >
                    <Icon className="me-3" icon="bi:printer" width="23" />
                    {isPrinting ? "Printing..." : "Print"}
                  </button>
                  <Link
                    className={`text-decoration-none ${
                      hospitalityPage
                        ? "display-hospitality-page"
                        : "no-view-hospitality"
                    }`}
                    to={"/"}
                  >
                    <button className="btn btn-md btn-primary no-print px-sm-6 px-0 mb-3">
                      Back Home
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal show={openModal} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <div
            className="p-md-6 p-5 overflow-scroll"
            style={{ maxHeight: "57rem" }}
          >
            <div className="close-modal" onClick={handleClose}>
              <Icon icon="charm:square-cross" />
            </div>
            <h2 className="sub-title text-2xl my-5 text-center">
              Hospitality terms and conditions
            </h2>
            <p className="text-sm text-black-500 lh-lg">
              All third-party services offered through the QuickStay platform
              and or QuickStay mobile application, including venues and other
              external services are independent providers to QuickStay. As such
              all third-party services are subject to their own terms and
              conditions. QuickStay cannot guarantee the safety, reliability or
              quality of third-party venues and services. Any issues or disputes
              related to external third-party services should be resolved
              directly with the respective service provider(s).{" "}
            </p>
            <p className="text-sm text-black-500 lh-lg">
              By using third-party services through the QuickStay platform, you
              acknowledge that QuickStay is not liable for any damages, losses,
              or injuries such that they arise or occur at any of the venues or
              services.
            </p>
            <p className="text-sm text-black-500 lh-lg">
              By signing up to the QuickStay website, platform and or by
              accessing the QuickStay website or mobile application, whether as
              a guest, property owner or managing agent (an
              "Advertiser","Property manager","Property agent") you agree to the
              terms and conditions stipulated and agree that QuickStay does not
              assume liability for any issues or disputes that may arise at
              third-party venues or services.
            </p>
            <p className="text-sm text-black-500 lh-lg">
              For questions relating to venues or additional services please
              contact <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-mobile"
        show={openAcceptModal}
        onHide={closeAcceptModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="px-5 py-6 text-center">
            <h1 className="sub-title text-2xl mb-4_5">
              Do you wish to continue <br /> with this voucher?
            </h1>
            <p className="text-sm">
              Once submitted it cannot be reused. <br />
              Click "Yes" to proceed or "No" to cancel it
            </p>
            <div className="d-flex mt-5 justify-content-center gap-4">
              <button
                onClick={() => {
                  acceptVoucher();
                }}
                className="btn btn-sm btn-primary-600 px-5 py-4_5"
              >
                Yes
              </button>
              <button
                onClick={closeAcceptModal}
                className="btn btn-sm btn-grey-50 px-5 py-4_5"
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
