import React, { useEffect, useState } from "react";
import { DetailBanner } from "./detail-banner";
import "./styles.scss";
import { PropertyAvailablity } from "sharedComponents/propertyAvailability";
import { PropertyGallery } from "sharedComponents/propertyGallery";
import { ContactOwner } from "./contact";
import { PropertyLocation } from "./propertyLocation";
import { Amenities } from "./amenities";
import { Overview } from "./overview";
import useNetworkData from "hooks/useNetworkData";
import { getPropertyByIdRequest } from "services/network/properties";
import { useParams } from "react-router-dom";
import Loader from "sharedComponents/Loader";
import { AdditionalFees } from "./additional-fees";
import { CancellationPolicy } from "./cancellation-policy";
import { AuthContainer } from "sharedComponents/authContainer";

export const PropertyDetails = () => {
  const [openRegister, setOpenRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const { isLoading, makeRequest, response } = useNetworkData();
  const { id } = useParams();

  const handleShowLogin = () => setShowLogin(true);
  const handleOpenRegister = () => {
    setShowLogin(false);
    setOpenRegister(true);
  };

  const getProperty = async () => {
    await makeRequest({
      apiRequest: getPropertyByIdRequest,
      payload: id,
    });
  };

  useEffect(() => {
    getProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PropertyDetails">
      <DetailBanner property={response?.data} />

      {isLoading ? (
        <div className="py-10">
          <Loader title="Getting details..." />
        </div>
      ) : (
        <>
          {response && (
            <>
              <Overview property={response.data} />
              {/* <CancellationPolicy property={response.data}/> */}
              <AdditionalFees
                fees={response.data.propertyFees}
                          currency={response.data.currency}
                          propertyUserType={response.data.user.roleType}
                          propertyPrice ={response.data.price}
              />
              <PropertyAvailablity
                property={response.data}
                handleOpenRegister={handleOpenRegister}
                handleShowLogin={handleShowLogin}
              />
              <PropertyGallery property={response.data} />
              <Amenities amenities={response.data.amenities} />
              <PropertyLocation property={response.data} />
              <ContactOwner
                property={response.data}
                handleOpenRegister={handleOpenRegister}
                handleShowLogin={handleShowLogin}
              />
              <AuthContainer
                showLogin={showLogin}
                setShowLogin={setShowLogin}
                handleShowLogin={handleShowLogin}
                handleOpenRegister={handleOpenRegister}
                openRegister={openRegister}
                setOpenRegister={setOpenRegister}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
