import { Icon } from "@iconify/react";
import useNetworkData from "hooks/useNetworkData";
import React, { useCallback } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { googleLoginRequest } from "services/network/auth";
import Button from "sharedComponents/forms/Button";
import "./styles.scss";
import { toast } from "react-toastify";
import { saveStorageData, StorageKeys } from "lib/storageManager";

export const GoogleAuthButton = ({
  title,
  className,
  closeModal,
}: {
  title: string;
  className: string;
  closeModal: Function;
}) => {
  const { isLoading, makeRequest } = useNetworkData();

  const responseGoogle = useCallback(
    async ({ access_token }: { access_token: string }) => {
      const result: any = await makeRequest({
        payload: access_token,
        apiRequest: googleLoginRequest,
      });
      if (result && result.data.user.isVerified) {
        // toast.success(`Welcome ${result.data.user.firstName || ""} 😊!`);
        saveStorageData(StorageKeys.token, result.data.token);
        saveStorageData(StorageKeys.user, result.data.user);
        closeModal();
      } else {
        toast.error("Sorry, an error occurred!");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => responseGoogle(codeResponse),
    onError: () => {
      toast.error("Login Failed");
    },
  });

  return (
    <Button
      onClick={() => login()}
      disabled={isLoading}
      loading={isLoading}
      type="button"
      className={className}
      icon={() => <Icon icon="flat-color-icons:google" className="mr-2" />}
      title={title}
    />
  );
};
