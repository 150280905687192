import about1 from "assets/images/about1.svg";
import about2 from "assets/images/about2.svg";

import React from "react";
import { Link } from "react-router-dom";

export const About = () => {
  return (
    <section className="about-wrapper">
      <div className="container">
        <h2 className="sub-title text-center my-6 tailor-after">
          Tailor your stay to suit you
        </h2>
        <div className="row gy-5 gx-lg-7">
          <div className="col-md-6 col-sm-12">
            <img src={about1} alt="" />
            <div className="px-4">
              <h2 className="xxsub-title text-center my-4_5">
                Personalised services
              </h2>
              <p className="text-sm text-black">
                Enhance your travel or relocation experience with the help of
                our dedicated travel experts. Whether you're staying for
                business, leisure or moving to a new location, we offer
                personalised services to help make your stay hassle-free and
                seamless.
              </p>
              <p className="text-sm text-black">
                Let us take care of professional housekeeping services, airport
                transportation, arranging a rental car, or providing chauffeur
                services. Enjoy the convenience of our additional services,
                aimed at making your stay as effortless and enjoyable as
                possible!
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <img src={about2} alt="" />
            <div className="px-4">
              <h2 className="xxsub-title text-center my-4_5">
                Pet-friendly properties{" "}
              </h2>
              <p className="text-sm text-black">
                Discover pet-friendly properties for you and your furry friends!
                Don’t compromise on your next adventure, bring your whole family
                on the journey with you. Make the most of our additional
                pet-services such as dog-walkers or pet-sitters to allow your
                stay to be as easy and wholesome as possible!
              </p>
              <p className="text-sm text-black">
                Enjoy pet-specific accommodation that go the extra mile in
                providing amenities such as dog beds, pet showers, or cat flaps
                to help make your pet feel as comfortable and cared for as you.
              </p>
            </div>
          </div>
        </div>
        <Link to={"/dashboard/listings"} className="text-decoration-none">
          <button className="btn btn-md btn-blue-dark mx-auto my-6">
            List A Property
          </button>
        </Link>
      </div>
    </section>
  );
};
