import { useCallback, useState } from "react";
import { APIResponse } from "services/network/_schema";
import { handleGeneralErrors } from "utilities/handleGeneralErrors";

function useNetworkData() {
  const [response, setResponse] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | {
    message: string;
    statusCode: number;
    data: any;
  }>();

  const makeRequest = useCallback(
    async ({
      payload,
      apiRequest,
      returnError,
    }: {
      payload?: any;
      apiRequest: any;
      returnError?: boolean;
    }) => {
      setIsLoading(true);
      try {
        const { data }: APIResponse = await apiRequest(payload);
        setIsLoading(false);
        setResponse(data);
        setError(null);
        return data;
      } catch (error: any) {
        if (returnError) {
          setError(error.response?.data);
          setIsLoading(false);
          return { error: error.response?.data };
        } else {
          handleGeneralErrors(error.response);
          setIsLoading(false);
        }
      }
    },
    []
  );

  return { isLoading, makeRequest, response, error };
}

export default useNetworkData;
