import React, { useCallback } from "react";
import { Icon } from "@iconify/react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "./styles.scss";
import { toast } from "react-toastify";
import useNetworkData from "hooks/useNetworkData";
import { FBLoginRequest } from "services/network/auth";
import Button from "sharedComponents/forms/Button";
import { saveStorageData, StorageKeys } from "lib/storageManager";

export const FacebookAuthButton = ({
  title,
  className,
  closeModal,
}: {
  title: string;
  className: string;
  closeModal: () => void;
}) => {
  const { isLoading, makeRequest } = useNetworkData();

  const responseFacebook = useCallback(async (response: any) => {
    if (response.accessToken) {
      // Logged into your webpage and Facebook.
      const result: any = await makeRequest({
        payload: response.accessToken,
        apiRequest: FBLoginRequest,
      });

      if (result && result.data.user.isVerified) {
        // toast.success(`Welcome ${result.data.user.firstName || ""} 😊!`);
        saveStorageData(StorageKeys.token, result.data.token);
        saveStorageData(StorageKeys.user, result.data.user);
        closeModal();
      }
    } else {
      // The person is not logged into your webpage or we are unable to tell.
      toast.error("An error occurred, please try again.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
      callback={responseFacebook}
      fields="name,email,picture"
      render={({ onClick, isDisabled, isProcessing }: any) => (
        <Button
          onClick={onClick}
          disabled={isDisabled}
          loading={isProcessing || isLoading}
          type="button"
          className={className}
          icon={() => <Icon icon="logos:facebook" className="mr-2" />}
          title={title}
        />
      )}
    />
  );
};
