import React, { useContext, useEffect, useMemo, useState } from "react";
import logo from "assets/images/logo.png";
import cardsFull from "assets/images/cardsFull.svg";
import "./styles.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import useNetworkData from "hooks/useNetworkData";
import {
  bookPropertyRequest,
  getCouponWorthRequest,
} from "services/network/properties";
import { LayoutContext } from "context/layoutContext";
import { DateTime } from "luxon";
import { currencyObject } from "utilities/country";
import Button from "sharedComponents/forms/Button";
// import { Navbar } from "sharedComponents/navbar";

export const Checkout = () => {
  const [booking, setBooking] = useState(false);
  const { makeRequest } = useNetworkData();
  const [applyingCoupon, setApplyingCoupon] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const { bookingDetails, rates, currency, getPrice } =
    useContext(LayoutContext);
  const checkOut = async () => {
    if (bookingDetails) {
      setBooking(true);
      const payload = {
        id,
        data: {
          reservationId: bookingDetails?.reservationId,
          guests: bookingDetails?.guestCount,
          currency,
          checkIn: DateTime.fromJSDate(
            new Date(bookingDetails?.checkIn)
          ).toISODate(),
          checkOut: DateTime.fromJSDate(
            new Date(bookingDetails?.checkOut)
          ).toISODate(),
          discountCode: coupon,
        },
      };
      if (!coupon) {
        delete (payload as any).data.discountCode;
      }
      const res = await makeRequest({
        apiRequest: bookPropertyRequest,
        payload,
      });
      setBooking(false);
      if (res) {
        window.location.href = res.data.sessionUrl;
      }
    }
  };
  const applyCoupon = async () => {
    if (coupon) {
      setApplyingCoupon(true);
      const response = await makeRequest({
        payload: { coupon },
        apiRequest: getCouponWorthRequest,
      });
      setApplyingCoupon(false);
      if (response.statusCode === 200) {
        const { value } = response.data;
        setDiscount(value);
      }
    }
  };
  const diffTime = Math.abs(
    (bookingDetails?.checkIn as any) - (bookingDetails?.checkOut as any)
  );
  let dazs = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const totalPrice = useMemo(() => {
    if (bookingDetails) {
      const diffTime = Math.abs(
        (bookingDetails?.checkIn as any) - (bookingDetails?.checkOut as any)
      );
      let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      let additional;
      if (bookingDetails?.property?.user?.roleType == "Agent") {
        additional = bookingDetails?.property?.propertyFees.reduce(
          (prev: any, curr: any) =>
            curr.role != "Guest"
              ? prev +
                (curr?.feeType == "percent"
                  ? (curr?.value / 100) * bookingDetails?.property?.price * days
                  : prev + curr?.value)
              : prev,
          0
        );
      } else if (bookingDetails?.property?.user?.roleType == "Guest") {
        additional = bookingDetails?.property?.propertyFees.reduce(
          (prev: any, curr: any) =>
            curr?.role != "Agent"
              ? prev +
                (curr?.feeType == "percent"
                  ? (curr?.value / 100) * bookingDetails?.property?.price * days
                  : prev + curr?.value)
              : prev,
          0
        );
      } else {
        additional =
          bookingDetails?.property?.propertyFees.length > 0
            ? bookingDetails?.property?.propertyFees.reduce(
                (prev: any, curr: any) =>
                  curr?.role != "Agent"
                    ? prev +
                      (curr?.feeType == "percent"
                        ? (curr?.value / 100) *
                          bookingDetails?.property?.price *
                          days
                        : prev + curr?.value)
                    : prev,
                0
              )
            : 0;
      }
      const _discount =
        bookingDetails.property?.price * days * (discount / 100);
      return getPrice(
        `${
          parseFloat(bookingDetails?.property?.price * days + additional) -
          _discount
        }`,
        bookingDetails?.property?.currency
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingDetails, discount]);
  useEffect(() => {
    if (!bookingDetails) {
      navigate(`/property/${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="check-out-wrapper">
      {/* <div className="container">
        <img className="logo-img" src={logo} alt="logo" />
      </div> */}
      {/* <Navbar /> */}
      <Link
        to={`/property/${bookingDetails?.property?.id}`}
        className="text-decoration-none"
      >
        <div className="previous-link">
          <div className="d-flex container">
            <div className="text-lg text-black-200">
              <Icon className="text-xxl" icon="ic:round-less-than" />
            </div>
            <p className="text-lg m-0 text-black-200 ">Go Back</p>
          </div>
        </div>
      </Link>
      <div className="container">
        <h2 className="sub-title text-center">Booking Confirmation Page</h2>
        <div className="row gy-4_5 mt-md-7 mt-0 mb-7">
          <div className="col-lg-7 pe-5 col-12">
            <div className="d-flex align-items-center justify-content-between">
              <p className="sub-title text-xl text-black-500">Coupon</p>
              <img className="cardsFull" src={cardsFull} alt="cards" />
            </div>
            <div className="d-flex mt-3 mb-md-6 mb-5">
              <input
                type="text"
                className="form-control coupon-form"
                placeholder="Enter Coupon code"
                onChange={({ target: { value } }) => {
                  setCoupon(value);
                }}
              />
              <Button
                title="Apply"
                type="button"
                disabled={!coupon || applyingCoupon}
                loading={applyingCoupon}
                onClick={applyCoupon}
                className="btn btn-md btn-blue-dark apply-btn"
              />
            </div>
            <p className="sub-title text-xl text-black-500">
              Cancellation Policy -{" "}
              <span className="text-primary-600">
                {bookingDetails?.property?.cancellationPolicy}
              </span>
            </p>
            <p className="text-sm lh-lg">
              If a guest decides they want to cancel a booking, they may be
              liable to a refund depending on the cancellation policy associated
              with the booking. <br /> The cancellation policy is available on
              each summary page prior to confirming a booking. If a guest does
              not pay the remaining balance after an initial deposit has been
              taken on the date specified in the booking confirmation, the total
              deposit will not be refunded and the booking will then be
              automatically cancelled. If you cancel your booking before paying
              the total balance, you will not be liable to a refund either.{" "}
              <Link
                to="/cancellation-policy"
                target={"_blank"}
                className="click text-primary-400 text-decoration-none"
              >
                More Details...
              </Link>
            </p>
            <div className="d-flex mt-5">
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={agreement}
                  onChange={({
                    target: { checked },
                  }: {
                    target: HTMLInputElement;
                  }) => {
                    setAgreement(checked);
                  }}
                  id="flexCheckDefault"
                ></input>
              </div>
              <p className="text-sm text-black-000 m-0 lh-lg">
                I agree to the{" "}
                <Link
                  to="/cancellation-policy"
                  target={"_blank"}
                  className="click text-primary-400 text-decoration-none"
                >
                  cancellation policy
                </Link>
                ,{" "}
                <Link
                  to="/terms"
                  target={"_blank"}
                  className="click text-primary-400 text-decoration-none"
                >
                  terms & conditions
                </Link>{" "}
                and{" "}
                <Link
                  to="/booking-contract"
                  target={"_blank"}
                  className="click text-primary-400 text-decoration-none"
                >
                  booking contract
                </Link>
                . I also agree to pay the total amount shown which includes
                occupancy tax and cleaning servce.
              </p>
            </div>
            <Button
              title={"Pay Now"}
              className="btn btn-md btn-primary w-50 mt-6"
              disabled={!agreement || booking}
              loading={booking}
              onClick={checkOut}
            />
          </div>
          {bookingDetails?.property && (
            <div className="col-lg-5 col-12">
              <div className="booked-info">
                <div className="row px-4">
                  <div className="col-5">
                    <img
                      className="booked-img"
                      src={`${process.env.REACT_APP_ASSETS_URL}/${bookingDetails?.property?.featuredImage}`}
                      alt={bookingDetails?.property?.name}
                    />
                  </div>
                  <div className="col-7 text-white-000">
                    <h2 className="xsub-title text-xl text-white-000 mt-3">
                      {bookingDetails?.property?.name}
                    </h2>
                    <div className="d-flex align-items-center mt-4">
                      <Icon
                        className="text-xl me-3"
                        icon="ci:location-outline"
                      />
                      <p className="text-sm mb-0">
                        {bookingDetails?.property?.city},{" "}
                        {bookingDetails?.property?.country}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="brdr mt-4_5"></div>
                <div className="row px-5">
                  <div className="col-5 py-5 border border-top-0 border-bottom-0 border-start-0 border-white-000">
                    <div className="d-flex gap-4 align-items-center justify-content-cente">
                      <Icon
                        className="text-white-000 text-lg mb-3"
                        icon="bi:person"
                      />
                      <div>
                        <p className="text-xs text-white-000 ">Guests:</p>
                        <p className="text-sm text-white-000 ">
                          {bookingDetails?.guestCount}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-7 py-5">
                    <div className="d-flex flex-wrap gap-4 align-items-center justify-content-end">
                      <Icon
                        className="text-white-000 text-lg mb-3"
                        icon="simple-line-icons:calender"
                      />
                      <div>
                        <div className=" text-white-000 text-sm  d-flex justify-content-between align-items-center">
                          <p>Check In:</p>
                          {bookingDetails?.checkIn && (
                            <p>
                              {DateTime.fromJSDate(
                                new Date(bookingDetails?.checkIn)
                              ).toFormat("dd/M/yyyy")}
                            </p>
                          )}
                        </div>
                        <div className=" text-white-000 text-sm  d-flex justify-content-between align-items-center">
                          <p>Check Out:</p>
                          {bookingDetails?.checkOut && (
                            <p>
                              {DateTime.fromJSDate(
                                new Date(bookingDetails?.checkOut)
                              ).toFormat("dd/M/yyyy")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="brdr"></div>
                <div className="row px-5 mt-5">
                  <div className="col-6">
                    <p className="text-sm text-white-000 lh-lg">Rental Fee:</p>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-5">
                    <p className="xsub-title text-xl text-white-000 lh-lg text-end">
                      {getPrice(
                        bookingDetails?.property?.price,
                        bookingDetails?.property?.currency
                      )}
                      /{bookingDetails?.property?.bookingDurations}
                    </p>
                  </div>
                </div>
                {bookingDetails?.property?.user?.roleType == "Agent"
                  ? bookingDetails?.property?.propertyFees.map(
                      ({ fee, id, value, role, feeType }: any) =>
                        role !== "Guest" ? (
                          <div className="row px-5" key={id}>
                            <div className="col-6">
                              <p className="text-sm text-white-000 lh-lg">
                                {fee.name}:
                              </p>
                            </div>
                            <div className="col-1"></div>

                            <div className="col-5">
                              <p className="xsub-title text-xl text-white-000 lh-lg text-end">
                                {
                                  currencyObject[
                                    rates
                                      ? currency
                                      : bookingDetails?.property?.currency
                                  ]
                                }{" "}
                                {getPrice(
                                  feeType == "percent"
                                    ? (value / 100) *
                                        (bookingDetails?.property?.price * dazs)
                                    : value,
                                  bookingDetails?.property?.currency
                                )}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                    )
                  : bookingDetails?.property?.user?.roleType == "Guest"
                  ? bookingDetails?.property?.propertyFees.map(
                      ({ fee, id, value, role, feeType }: any) =>
                        role !== "Agent" ? (
                          <div className="row px-5" key={id}>
                            <div className="col-6">
                              <p className="text-sm text-white-000 lh-lg">
                                {fee.name}:
                              </p>
                            </div>
                            <div className="col-1"></div>

                            <div className="col-5">
                              <p className="xsub-title text-xl text-white-000 lh-lg text-end">
                                {
                                  currencyObject[
                                    rates
                                      ? currency
                                      : bookingDetails?.property?.currency
                                  ]
                                }{" "}
                                {getPrice(
                                  feeType == "percent"
                                    ? (value / 100) *
                                        (bookingDetails?.property?.price * dazs)
                                    : value,
                                  bookingDetails?.property?.currency
                                )}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                    )
                  : ""}
                {discount > 0 && (
                  <div className="row px-5">
                    <div className="col-6">
                      <p className="text-sm text-white-000 lh-lg">Discount:</p>
                    </div>
                    <div className="col-1"></div>

                    <div className="col-5">
                      <p className="xsub-title text-xl text-white-000 lh-lg text-end">
                        {
                          currencyObject[
                            rates
                              ? currency
                              : bookingDetails?.property?.currency
                          ]
                        }{" "}
                        {getPrice(discount, bookingDetails?.property?.currency)}
                      </p>
                    </div>
                  </div>
                )}
                <div className="brdr"></div>
                <div className="row px-5 mt-5">
                  <div className="col-6">
                    <p className="text-sm text-white-000">Total Amount:</p>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-5">
                    {totalPrice && (
                      <p className=" xsub-title text-white-000 text-end">
                        {
                          currencyObject[
                            rates
                              ? currency
                              : bookingDetails?.property?.currency
                          ]
                        }
                        {totalPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
