import React, { useEffect } from "react";
import { getUserWishlistRequest } from "services/network/properties";
import useNetworkData from "hooks/useNetworkData";
import { PropertyCard } from "sharedComponents/propertyCard";
import { Link } from "react-router-dom";
import { PropertySchema } from "services/network/_schema";
import Loader from "sharedComponents/Loader";

export const Wishlist = () => {
  const { isLoading, makeRequest, response } = useNetworkData();

  const getWishlist = async () => {
    await makeRequest({
      apiRequest: getUserWishlistRequest,
    });
  };

  useEffect(() => {
    getWishlist();
  }, []);

  return (
    <div className="listing-wrapper pt-3 mb-5">
      {isLoading ? (
        <div className="py-10">
          <Loader title="Fetching..." />
        </div>
      ) : (
        <>
          {response && (
            <>
              {response.data.length > 0 ? (
                <div className="container">
                  <h2 className="sub-title my-5 text-primary-600">Wishlist</h2>

                  <div className="row g-5">
                    {response.data.map((item: PropertySchema) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        key={item.id}
                      >
                        <PropertyCard
                          property={{ ...item, isWishlist: true }}
                          showFavorite={true}
                          showViewListing={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="py-5">
                  <p className="text-sm text-center mt-10 mb-5">
                    You currently have no wishlist.
                  </p>
                  <Link to={"/properties"} className="text-decoration-none">
                    <button className="btn btn-lg btn-blue-dark w-25 mx-auto mb-10">
                      View Properties
                    </button>
                  </Link>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
