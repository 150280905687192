import React, { useState } from "react";
import "./styles.scss";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { PropertySchema } from "services/network/_schema";

export const PropertyGallery = ({ property }: { property: PropertySchema }) => {
  const [isOpen, setisOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <section className="gallery-wrapper" id="gallery">
      <div className="container">
        <h2 className="sub-title text-center mb-sm-6 mb-4_5">Gallery</h2>
        <div className="p-gallery-grid">
          {property.pictures.map((item, index) => (
            <div
              key={item.url}
              className="item click"
              onClick={() => {
                setPhotoIndex(index);
                setisOpen(true);
              }}
            >
              <img className="grid-img" src={item.url} alt="" />
            </div>
          ))}
          {isOpen && (
            <Lightbox
              mainSrc={property.pictures[photoIndex].url}
              nextSrc={
                property.pictures[(photoIndex + 1) % property.pictures.length]
                  .url
              }
              prevSrc={
                property.pictures[
                  (photoIndex + property.pictures.length - 1) %
                    property.pictures.length
                ].url
              }
              onCloseRequest={() => setisOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + property.pictures.length - 1) %
                    property.pictures.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % property.pictures.length)
              }
            />
          )}
        </div>
      </div>
    </section>
  );
};
