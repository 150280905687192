import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ThemedSelect from "sharedComponents/forms/ThemedSelect";
import {
  convertArrayToSelectOptions,
  convertModelArrayToSelectOptions,
  useQuery,
} from "utilities";
import { Field, Form, Formik, FormikValues } from "formik";
import * as yup from "yup";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import useNetworkData from "hooks/useNetworkData";
import { getAllAmenitiesRequest } from "services/network/properties";
import {
  AmenitySchema,
  BookingDurations,
  BookOptions,
  Preference,
  PropertyType,
  StayType,
} from "services/network/_schema";

const popularSearch = [
  "Show all",
  "Wifi",
  "Workspace",
  "Parking",
  "Kitchen facilities",
  "Washing machine",
  "Dryer",
];
const priceFilters = ["Low to High", "High to Low"];
const stayOptionList = Object.entries(StayType).map((item) => ({
  label: item[1],
  value: item[0],
}));
const durationList = Object.entries(BookingDurations).map((item) => ({
  label: item[1],
  value: item[0],
}));
const minBedList = ["1", "2", "3", "4", "5", "5+", "No min"];
const maxBedList = ["1", "2", "3", "4", "5", "5+", "No max"];
const minBathroom = ["1", "2", "3", "4", "5", "5+", "No min"];
const maxBathroom = ["1", "2", "3", "4", "5", "5+", "No max"];
const preferencesList = Object.entries(Preference).map((item) => ({
  label: item[1],
  value: item[0],
}));
const accessibilityList = [
  "Show all",
  "Elevator",
  "Step-free access ",
  "Wheelchair accessible ",
  "Guest entrance wider than 30 inches",
];
const bookingOptionsList = Object.entries(BookOptions).map((item) => ({
  label: item[1],
  value: item[0],
}));
const propertyTypeList = Object.entries(PropertyType).map((item) => ({
  label: item[1],
  value: item[0],
}));
const additionalServicesList = [
  { value: "showAllAdditionalServicesList", label: "Show all" },
  { value: "Cleaning service", label: "Cleaning service" },
  { value: "Chef service", label: "Chef service" },
  { value: "Laundry services", label: "Laundry services" },
  { value: "Car rental", label: "Car rental" },
  { value: "Private driver", label: "Private driver" },
  { value: "Airport transport", label: "Airport transport" },
  { value: "Pet-friendly service", label: "Pet-friendly service" },
  { value: "Dry cleaning", label: "Dry cleaning" },
  { value: "Child care services", label: "Child care services" },
];
export const FilterProperty = ({
  setShow,
  show,
}: {
  setShow: (e: boolean) => void;
  show: boolean;
}) => {
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const params = useQuery();
  const [amenitiesList, setAmenitiesList] = useState<AmenitySchema[]>([]);
  const { isLoading, makeRequest } = useNetworkData();
  const defaultValues = {
    popularSearch: "",
    priceSort: { label: priceFilters[0], value: priceFilters[0] },
    bed1: { label: minBedList[6], value: minBedList[6] },
    bed2: { label: maxBedList[6], value: maxBedList[6] },
    bath1: { label: minBathroom[6], value: minBathroom[6] },
    bath2: { label: maxBathroom[6], value: maxBathroom[6] },
    stayType: stayOptionList[0],
    price1: "20",
    price2: "4500",
    duration: "",
    preference: "",
    accesibility: "",
    bookingOption: "",
    propertyType: [],
    amenity: [],
    additionalService: [],
  };
  const [initialValues, setInitialValues] = useState<any>(defaultValues);

  const validationSchema = yup.object().shape({
    price: yup.object().label("Price"),
  });

  useEffect(() => {
    fetchAmenities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAmenities = async () => {
    const res = await makeRequest({
      apiRequest: getAllAmenitiesRequest,
    });
    if (res.data) setAmenitiesList(res.data.items);
  };

  const handleFilter = (formValues: FormikValues) => {
    const checkIn = params.get("start_date_val") || "";
    const checkOut = params.get("end_date_val") || "";
    const latitude = params.get("loc_lat") || "";
    const longitude = params.get("loc_lng") || "";
    const guest = params.get("guest") || "";
    navigate(
      `/search?loc_lat=${latitude}&loc_lng=${longitude}&guest=${guest}&start_date_val=${checkIn}&end_date_val=${checkOut}&priceSort=${
        formValues.priceSort.value || ""
      }&popularSearch=${formValues.popularSearch.value || ""}&bed1=${
        formValues.bed1.value || ""
      }&bed2=${formValues.bed2.value}&bath1=${
        formValues.bath1.value || ""
      }&bath2=${formValues.bath2.value}&price1=${formValues.price1}&price2=${
        formValues.price2
      }&amenity=${formValues.amenity}&additionalService=${
        formValues.additionalService
      }&bookingOption=${formValues.bookingOption.value || ""}&propertyType=${
        formValues.propertyType
      }&stayType=${formValues.stayType.value || ""}&accesibility=${
        formValues.accesibility.value || ""
      }&duration=${formValues.duration.value || ""}&preference=${
        formValues.preference.value || ""
      }`
    );
    handleClose();
    // console.log(formValues);
    // console.log(formValues.duration.value);
  };

  const updateInitialValues = () => {
    const amenity = params.get("amenity")
      ? params.get("amenity")?.split(",")
      : [];
    const propertyType = params.get("propertyType")
      ? params.get("propertyType")?.split(",")
      : [];
    const additionalService = params.get("additionalService")
      ? params.get("additionalService")?.split(",")
      : [];
    setInitialValues({
      popularSearch: params.get("popularSearch")
        ? {
            label: params.get("popularSearch"),
            value: params.get("popularSearch"),
          }
        : "",

      priceSort: {
        label: params.get("priceSort") || priceFilters[0],
        value: params.get("priceSort") || priceFilters[0],
      },
      bed1: {
        label: params.get("bed1") || minBedList[6],
        value: params.get("bed1") || minBedList[6],
      },
      bed2: {
        label: params.get("bed2") || maxBedList[6],
        value: params.get("bed2") || maxBedList[6],
      },
      bath1: {
        label: params.get("bath1") || minBathroom[6],
        value: params.get("bath1") || minBathroom[6],
      },
      bath2: {
        label: params.get("bath2") || maxBathroom[6],
        value: params.get("bath2") || maxBathroom[6],
      },
      stayType: params.get("stayType") || "",

      price1: Number(params.get("price1") || "20"),
      price2: Number(params.get("price2") || "4500"),
      duration: params.get("duration") || "",
      preference: params.get("preference") || "",
      accesibility: params.get("accesibility")
        ? {
            label: params.get("accesibility"),
            value: params.get("accesibility"),
          }
        : "",
      bookingOption: params.get("bookingOption") || "",
      propertyType,
      amenity,
      additionalService,
    });
  };

  useEffect(() => {
    updateInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFormvalues = () => {
    setInitialValues(defaultValues);
    const checkIn = params.get("start_date_val") || "";
    const checkOut = params.get("end_date_val") || "";
    const latitude = params.get("loc_lat") || "";
    const longitude = params.get("loc_lng") || "";
    const guest = params.get("guest") || "";
    navigate(
      `/search?loc_lat=${latitude}&loc_lng=${longitude}&guest=${guest}&start_date_val=${checkIn}&end_date_val=${checkOut}`
    );
  };

  return (
    <div className="filter">
      <Offcanvas
        className="filter-offcanvas"
        show={show}
        placement={"end"}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h1 className="sub-title text-xl text-black-000">
              Filter your results
            </h1>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFilter}
            validateOnBlur={false}
            enableReinitialize={true}
          >
            {({ handleSubmit, isValid, values, setFieldValue }) => {
              return (
                <>
                  <Form
                    className="px-5 mb-9 position-relative"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-4">
                      <label className=" mb-3 text-sm fw-medium text-black-000 ">
                        Popular filters
                      </label>
                      <Field
                        component={ThemedSelect}
                        name="popularSearch"
                        id="popularSearch"
                        size="base"
                        options={convertArrayToSelectOptions(popularSearch)}
                      />
                    </div>
                    <hr className="m-0" />
                    <h3 className="mb-3 mt-4_5 text-sm fw-medium text-black-000 ">
                      Sort by
                    </h3>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-4">
                          <label className=" mb-2 fw-light text-black-000 text-xs">
                            Price
                          </label>
                          <Field
                            component={ThemedSelect}
                            name="priceSort"
                            id="priceSort"
                            size="base"
                            options={convertArrayToSelectOptions(priceFilters)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-4">
                          <label className=" mb-2 fw-light text-black-000 text-xs">
                            Stay Option
                          </label>
                          <Field
                            component={ThemedSelect}
                            name="stayType"
                            id="stayType"
                            size="base"
                            options={convertModelArrayToSelectOptions(
                              stayOptionList,
                              "value",
                              "label",
                              true
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <h3 className="mb-3 mt-4_5 text-sm fw-medium text-black-000 ">
                      Bedrooms
                    </h3>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="">
                          <label className=" mb-2 fw-light text-black-000 text-xs">
                            Min Bedroom
                          </label>
                          <Field
                            component={ThemedSelect}
                            name="bed1"
                            id="bed1"
                            size="base"
                            options={convertArrayToSelectOptions(minBedList)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-4">
                          <label className=" mb-2 fw-light text-black-000 text-xs">
                            Max Bedroom
                          </label>
                          <Field
                            component={ThemedSelect}
                            name="bed2"
                            id="bed2"
                            size="base"
                            options={convertArrayToSelectOptions(maxBedList)}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <h3 className="mb-3 mt-4_5 text-sm fw-medium text-black-000 ">
                      Bathrooms
                    </h3>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className=" mb-4">
                          <label className=" mb-2 fw-light text-black-000 text-xs">
                            Min Bathrooms
                          </label>
                          <Field
                            component={ThemedSelect}
                            name="bath1"
                            id="bath1"
                            size="base"
                            options={convertArrayToSelectOptions(minBathroom)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-4">
                          <label className=" mb-2 fw-light text-black-000 text-xs">
                            Max Bathrooms
                          </label>
                          <Field
                            component={ThemedSelect}
                            name="bath2"
                            id="bath2"
                            size="base"
                            options={convertArrayToSelectOptions(maxBathroom)}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <h3 className="mb-3 mt-4_5 text-sm fw-medium text-black-000 ">
                      Price
                    </h3>
                    <div>
                      <div className="multi-range-slider-container">
                        <div className="d-flex align-items-center gap-2">
                          <div className="forInput me-3">
                            <p className="mb-2 sliderMax">Min. price</p>
                            <p className="formSlider">£{values.price1}</p>
                          </div>
                          <div className="col-8">
                            <MultiRangeSlider
                              min={0}
                              max={4500}
                              step={1}
                              minValue={values.price1}
                              maxValue={values.price2}
                              onInput={(e: ChangeResult) => {
                                setFieldValue("price1", e.minValue);
                                setFieldValue("price2", e.maxValue);
                              }}
                            ></MultiRangeSlider>
                          </div>
                          <div className="forInput">
                            <p className="mb-2 sliderMax">Max. price</p>
                            <p className="formSlider">£{values.price2}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-4">
                        <label className=" mb-2 fw-light text-black-000 text-xs">
                          Duration
                        </label>
                        <Field
                          component={ThemedSelect}
                          name="duration"
                          id="duration"
                          size="base"
                          options={convertModelArrayToSelectOptions(
                            durationList,
                            "value",
                            "label",
                            true
                          )}
                        />
                      </div>
                    </div>
                    <hr className="m-0" />
                    <h3 className="mb-3 mt-4_5 text-sm fw-medium text-black-000 ">
                      Property type
                    </h3>
                    <div className="row">
                      {propertyTypeList.map((item) => (
                        <div className="col-4 mb-2" key={item.value}>
                          <label
                            className="d-flex align-items-center gap-3 click"
                            htmlFor={item.value}
                          >
                            <div>
                              <Field
                                type="checkbox"
                                name="propertyType"
                                id={item.value}
                                value={item.value}
                                className="form-check-input"
                              />
                            </div>
                            <div>{item.label}</div>
                          </label>
                        </div>
                      ))}
                    </div>
                    <hr className="m-0 mt-4" />
                    <div className="mb-4  mt-4_5">
                      <label className=" mb-3 text-sm fw-medium text-black-000 ">
                        Preferences
                      </label>
                      <Field
                        component={ThemedSelect}
                        name="preference"
                        id="preference"
                        size="base"
                        options={convertModelArrayToSelectOptions(
                          preferencesList,
                          "value",
                          "label",
                          true
                        )}
                      />
                    </div>
                    <hr className="m-0 mb-4_5" />
                    <div className="row mb-4">
                      <div className="col-md-6 col-sm-12">
                        <label className=" mb-3 text-sm fw-medium text-black-000 ">
                          Amenities
                        </label>
                        <Dropdown className="filter-dropdown">
                          <Dropdown.Toggle
                            className="text-xs w-100 "
                            id="dropdown-basic"
                          >
                            Select...
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {isLoading ? (
                              "Loading..."
                            ) : (
                              <div className="row">
                                {amenitiesList.map((item) => (
                                  <div className="col-4 mb-2" key={item.id}>
                                    <label
                                      className="d-flex align-items-center gap-3 click"
                                      htmlFor={item.id}
                                    >
                                      <div>
                                        <Field
                                          type="checkbox"
                                          name="amenity"
                                          id={item.id}
                                          value={item.id}
                                          className="form-check-input"
                                        />
                                      </div>
                                      <div>{item.name}</div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label className=" mb-3 text-sm fw-medium text-black-000 ">
                          Additional services
                        </label>
                        <Dropdown className="filter-dropdown">
                          <Dropdown.Toggle
                            className="text-xs w-100 "
                            id="dropdown-basic"
                          >
                            Select...
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="row">
                              {additionalServicesList.map((item) => (
                                <div className="col-6 mb-2" key={item.value}>
                                  <label
                                    className="d-flex align-items-center gap-3 click"
                                    htmlFor={item.value}
                                  >
                                    <div>
                                      <Field
                                        type="checkbox"
                                        name="additionalService"
                                        id={item.value}
                                        value={item.value}
                                        className="form-check-input"
                                      />
                                    </div>
                                    <div>{item.label}</div>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <div className="mb-4  mt-4_5">
                      <label className=" mb-3 text-sm fw-medium text-black-000 ">
                        Booking options
                      </label>
                      <Field
                        component={ThemedSelect}
                        name="bookingOption"
                        id="bookingOption"
                        size="base"
                        options={convertModelArrayToSelectOptions(
                          bookingOptionsList,
                          "value",
                          "label",
                          true
                        )}
                      />
                    </div>
                    <hr className="m-0" />
                    <div className="mb-4  mt-4_5">
                      <label className=" mb-3 text-sm fw-medium text-black-000 ">
                        Accessibility
                      </label>
                      <Field
                        component={ThemedSelect}
                        name="accesibility"
                        id="accesibility"
                        size="base"
                        options={convertArrayToSelectOptions(accessibilityList)}
                      />
                    </div>
                    <div className=" filter-footer">
                      <div className="d-flex align-items-center ms-9 py-4_5 gap-5">
                        <button
                          className="click text-black-000 bg-white text-xs fw-semi-bold mb-0"
                          type="button"
                          onClick={() => resetFormvalues()}
                        >
                          Reset filters
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary-600 btn-sm"
                        >
                          Update results
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
