/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { lazy, Suspense } from "react";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import ScrollToTop from "sharedComponents/ScrollToTop";
import { MainLayout } from "sharedComponents/layout/main";
import Loader from "sharedComponents/Loader";
import { LayoutProvider } from "context/layoutContext";
import OfflinePage from "pages/guest/_offlinePage";
import { HomePage } from "pages/guest/home";
import { GetApproved } from "pages/guest/getApproved";
import { AllProperties } from "pages/guest/allProperties";
import { PropertyDetails } from "pages/guest/allProperties/details";
import { Checkout } from "pages/authenticated/checkout";
import { VerifyUser } from "pages/guest/registerModal/verifyUser";
import { LoadScript } from "@react-google-maps/api";
import { TermsPage } from "pages/guest/staticPages/termsPage";
import { ContactPage } from "pages/guest/staticPages/contactPage";
import { DowloadAppPage } from "pages/guest/staticPages/dowload-app";
import { AboutPage } from "pages/guest/staticPages/aboutPage";
import { CheckoutSuccess } from "pages/authenticated/checkout/checkoutSuccess";
import { Restaurants } from "pages/authenticated/restaurants";
import { Wishlist } from "pages/authenticated/wishlist";
import { SearchResultsPage } from "pages/guest/allProperties/searchResultsPage";
import { PrivacyPage } from "pages/guest/staticPages/privacy";
import { CancellationPolicyPage } from "pages/guest/staticPages/cancellationPolicy";
import { BookingContractPage } from "pages/guest/staticPages/bookingContract";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CheckoutFailed } from "pages/authenticated/checkout/checkoutFailed";
import { ShortStays } from "pages/guest/allProperties/shortStays";
import { VoucherPage } from "pages/authenticated/voucherPage";

const DashboardRoutes = lazy(() => import("routes/dashboardRoutes"));

function App() {
  return (
    <LayoutProvider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || ""}
      >
        <LoadScript
          libraries={["places"]}
          googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}`}
        >
          <BrowserRouter>
            <ScrollToTop />
            <Suspense
              fallback={
                <div className="d-flex justify-content-center align-items-center vh-100">
                  <Loader />
                </div>
              }
            >
              <Routes>
                <Route path="/verify" element={<VerifyUser />} />
                <Route
                  element={
                    <MainLayout isHome={true} main={false}>
                      {<Outlet />}
                    </MainLayout>
                  }
                >
                  <Route path="/" element={<HomePage />} />
                </Route>
                {/* <Route path="/checkout/:id" element={<Checkout />} /> */}
                {/* <Route path="/success" element={<CheckoutSuccess />} /> */}
                <Route
                  path="/choose-restaurant/:propertyId/:referenceId"
                  element={<Restaurants />}
                />

                <Route
                  element={
                    <MainLayout isHome={false} main={true}>
                      {<Outlet />}
                    </MainLayout>
                  }
                >
                  <Route path="/offline" element={<OfflinePage />} />
                  <Route path="/get-approved" element={<GetApproved />} />
                  <Route path="/property/:id" element={<PropertyDetails />} />
                  <Route path="/failed" element={<CheckoutFailed />} />
                  <Route path="/properties" element={<AllProperties />} />
                  <Route path="/stay-options/:type" element={<ShortStays />} />
                  <Route path="/search" element={<SearchResultsPage />} />
                  <Route path="/wishlist" element={<Wishlist />} />
                  <Route path="/dashboard/*" element={<DashboardRoutes />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/download-app" element={<DowloadAppPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route path="/checkout/:id" element={<Checkout />} />
                  <Route path="/success" element={<CheckoutSuccess />} />
                  <Route path="/voucher/:ref" element={<VoucherPage />} />
                  <Route
                    path="/voucher/verify/:discountCode"
                    element={<VoucherPage acceptance={true} />}
                  />
                  <Route
                    path="/booking-contract"
                    element={<BookingContractPage />}
                  />
                  <Route
                    path="/cancellation-policy"
                    element={<CancellationPolicyPage />}
                  />
                </Route>
                <Route
                  element={
                    <MainLayout isHome={false} main={false}>
                      {<Outlet />}
                    </MainLayout>
                  }
                ></Route>

                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                toastClassName="px-2 shadow-sm rounded-md font-weight-normal text-lg"
              />
            </Suspense>
          </BrowserRouter>
        </LoadScript>
      </GoogleOAuthProvider>
    </LayoutProvider>
  );
}

export default App;
