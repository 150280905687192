import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./styles.scss";
import {
  PropertySchema,
  ReserveDSchema,
  UserSchema,
} from "services/network/_schema";
import { DateTime } from "luxon";
import useNetworkData from "hooks/useNetworkData";
import { getBlockedDatesRequest } from "services/network/properties";
import { currencyObject } from "utilities/country";
import { LayoutContext } from "context/layoutContext";
import { ReserveProperty } from "./reserveProperty";
import { getStorageData, StorageKeys } from "lib/storageManager";

const now = new Date();

export const PropertyAvailablity = ({
  property,
  handleShowLogin,
  handleOpenRegister,
}: {
  property: PropertySchema;
  handleShowLogin?: () => void;
  handleOpenRegister?: () => void;
}) => {
  const user: UserSchema = getStorageData(StorageKeys.user);
  const [guestCount, setGuestCount] = useState(1);
  const { makeRequest } = useNetworkData();
  const [isLoading, setIsLoading] = useState(false);
  const [showOwner, setShowOwner] = useState(false);
  const [blockedDates, setBlockedDates] = useState<string[]>([]);
  const [formattedDates, setFormattedDates] = useState<Date[]>([]);
  const [reserveDetails, setReserveDetails] = useState<ReserveDSchema>();
  const { setBookingDetails, rates, currency, getPrice } =
    useContext(LayoutContext);
  const navigate = useNavigate();

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
    key: "selection",
  });

  const handleSelect = (e: any) => {
    if (!isLoading) {
      const { endDate, startDate, key } = e.selection;
      const validEndDate =
        startDate.toLocaleString() === endDate.toLocaleString()
          ? new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate() + 1
            )
          : endDate;
      setSelectionRange({
        endDate: validEndDate,
        key,
        startDate,
      });
    }
  };

  useEffect(() => {
    getBlockedDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookNow = () => {
    const loginBtn: any = document.querySelector("#login-btn");
    if (!user && loginBtn) {
      loginBtn.click();
      return;
    }
    if (selectionRange.startDate && selectionRange.endDate) {
      setBookingDetails({
        property,
        guestCount,
        checkIn: selectionRange.startDate,
        checkOut: selectionRange.endDate,
      });
      navigate(`/checkout/${property?.id}`);
    }
  };

  const getBlockedDate = async () => {
    setIsLoading(true);
    const res = await makeRequest({
      apiRequest: getBlockedDatesRequest,
      payload: property?.id,
    });
    setIsLoading(false);
    if (res) {
      setBlockedDates(res.data);
      setFormattedDates(res.data.map((item: string) => new Date(item)));
    }
  };

  const shouldDisable = useMemo(() => {
    if (
      !selectionRange.startDate ||
      !selectionRange.endDate ||
      blockedDates.includes(
        DateTime.fromJSDate(selectionRange.startDate).toFormat("yyyy-MM-dd")
      ) ||
      blockedDates.includes(
        DateTime.fromJSDate(selectionRange.endDate).toFormat("yyyy-MM-dd")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }, [blockedDates, selectionRange]);

  const diffTime = Math.abs(
    (selectionRange.startDate as any) - (selectionRange.endDate as any)
  );
  const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const totalPrice = useMemo(() => {
    let additional = 0;
    if (property.user.roleType == "Agent") {
      additional = property?.propertyFees.reduce(
        (prev: any, curr) =>
          curr.role != "Guest"
            ? prev +
              (curr.feeType == "percent"
                ? (curr.value / 100) * property.price * days
                : prev + curr.value)
            : prev,
        0
      );
    } else {
      additional = property?.propertyFees.reduce(
        (prev: any, curr) =>
          curr.role != "Agent"
            ? prev +
              (curr.feeType == "percent"
                ? (curr.value / 100) * property.price * days
                : prev + curr.value)
            : prev,
        0
      );
    }
    return getPrice(
      `${parseFloat(property?.price) * days + additional}`,
      property?.currency
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, selectionRange]);

  const reserveProp = () => {
    setReserveDetails({
      property,
      guestCount,
      checkIn: selectionRange.startDate,
      checkOut: selectionRange.endDate,
    });
    setShowOwner(true);
  };

  return (
    <section id="availability">
      <div className="container">
        <h2 className="sub-title text-center mb-sm-5 mb-3">Availability</h2>
        <DateRangePicker
          ranges={[selectionRange]}
          direction={"horizontal"}
          onChange={handleSelect}
          months={2}
          rangeColors={["#42c9f8", "#42c9f8"]}
          showDateDisplay={false}
          minDate={new Date()}
          disabledDates={formattedDates}
        />
        <div className="reserved-wrapper">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-12">
              <div className="check-info">
                <div>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}/${property?.featuredImage}`}
                    alt=""
                  />
                </div>
                <div>
                  <h2 className="xsub-title text-xl text-white-000">
                    {property?.name}
                  </h2>
                  <div className="border-white-000 border rounded text-white-000 text-sm mt-5 px-3 py-2 d-flex justify-content-between align-items-center">
                    <p className="m-0">Check In:</p>
                    <p className="m-0">
                      {selectionRange.startDate ? (
                        <>
                          {DateTime.fromJSDate(
                            selectionRange.startDate
                          ).toFormat("dd/M/yyyy")}
                        </>
                      ) : (
                        "---"
                      )}
                    </p>
                  </div>
                  <div className="border-white-000 border rounded text-white-000 text-sm my-4 px-3 py-2 d-flex justify-content-between align-items-center">
                    <p className="m-0">Check Out:</p>
                    <p className="m-0">
                      {selectionRange.endDate ? (
                        <>
                          {DateTime.fromJSDate(selectionRange.endDate).toFormat(
                            "dd/M/yyyy"
                          )}
                        </>
                      ) : (
                        "---"
                      )}
                    </p>
                  </div>
                  <div className="border-white-000 border rounded text-white-000 text-sm my-4 px-3 py-2 d-flex justify-content-between align-items-center">
                    <p className="m-0">Guests:</p>
                    <div className="d-flex align-items-center gap-4">
                      <Icon
                        icon="akar-icons:circle-minus"
                        className="click"
                        onClick={() => {
                          if (guestCount > 1) {
                            setGuestCount((prev) => prev - 1);
                          }
                        }}
                      />
                      <p className="m-0">{guestCount}</p>
                      <Icon
                        icon="akar-icons:circle-plus"
                        className="click"
                        onClick={() => {
                          if (guestCount < property?.guest) {
                            setGuestCount((prev) => prev + 1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-1"></div>
            <div className="col-lg-4 col-md-12">
              <p className="text-sm text-white-000 mb-5">Amount</p>
              <div className="row">
                <div className="col-6">
                  <p className="text-sm text-white-000">Rental Fee:</p>
                </div>
                <div className="col-6">
                  <p className="xsub-title text-xl text-white-000">
                    {getPrice(property?.price, property?.currency)}/
                    {property?.bookingDurations}
                  </p>
                </div>
              </div>
              {property.user.roleType == "Agent"
                ? property?.propertyFees.map(
                    ({ fee, id, value, role, feeType }: any) =>
                      role !== "Guest" ? (
                        <div className="row" key={id}>
                          <div className="col-6">
                            <p className="text-sm text-white-000">
                              {fee?.name}:
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="xsub-title text-xl text-white-000">
                              {
                                currencyObject[
                                  rates ? currency : property?.currency
                                ]
                              }{" "}
                              {getPrice(
                                feeType == "percent"
                                  ? (value / 100) * (property.price * days)
                                  : value,
                                property?.currency
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                  )
                : property.user.roleType == "Guest"
                ? property?.propertyFees.map(
                    ({ fee, id, value, role, feeType }: any) =>
                      role !== "Agent" ? (
                        <div className="row" key={id}>
                          <div className="col-6">
                            <p className="text-sm text-white-000">
                              {fee?.name}:
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="xsub-title text-xl text-white-000">
                              {
                                currencyObject[
                                  rates ? currency : property?.currency
                                ]
                              }{" "}
                              {getPrice(
                                feeType == "percent"
                                  ? (value / 100) * (property.price * days)
                                  : value,
                                property?.currency
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                  )
                : ""}
              <div className="row">
                <div className="col-6">
                  <p className="text-sm text-white-000">Total:</p>
                </div>
                <div className="col-6">
                  <p className="xsub-title text-xl text-white-000">
                    {currencyObject[rates ? currency : property?.currency]}{" "}
                    {totalPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
              {property?.mustReserve ? (
                <button
                  className="btn btn-md w-100 btn-primary"
                  onClick={reserveProp}
                >
                  Reserve
                </button>
              ) : (
                <button
                  className="btn btn-md w-100 btn-primary"
                  disabled={shouldDisable}
                  onClick={bookNow}
                >
                  Book Now
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {reserveDetails && (
        <ReserveProperty
          showOwner={showOwner}
          setShowOwner={setShowOwner}
          property={property}
          handleOpenRegister={handleOpenRegister}
          handleShowLogin={handleShowLogin}
          reserveDetails={reserveDetails}
        />
      )}
    </section>
  );
};
