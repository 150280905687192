import { LayoutContext } from "context/layoutContext";
import React, { useContext } from "react";
import { currencyObject } from "utilities/country";

export const AdditionalFees = ({
  fees,
  currency,
  propertyUserType,
  propertyPrice,
}: {
  fees: [];
  currency: string;
  propertyUserType: string;
  propertyPrice: any;
}) => {
  const { rates, currency: generalCurr, getPrice } = useContext(LayoutContext);

  return (
    <div className="additional-fee bg-primary-600 py-4 mb-sm-6 mb-4">
      {fees.length < 1 ? null : (
        <div className="container d-flex flex-wrap justify-content-between align-items-center">
          <p className=" sub-title  text-white-000">Additional Fees:</p>
          {propertyUserType == "Agent" ? (
            <div>
              {fees.map((fee: any) =>
                fee.role !== "Guest" ? (
                  <p className="text-xl text-white-000" key={fee.id}>
                    {fee?.fee?.name}:{" "}
                    {currencyObject[`${rates ? generalCurr : currency}`]}
                    {getPrice(
                      fee?.feeType == "percent"
                        ? (fee?.value / 100) * propertyPrice
                        : fee?.value,
                      currency
                    )}
                  </p>
                ) : (
                  ""
                )
              )}
            </div>
          ) : propertyUserType == "Guest" ? (
            <div>
              {fees.map((fee: any) =>
                fee.role !== "Agent" ? (
                  <p className="text-xl text-white-000" key={fee?.id}>
                    {fee?.fee?.name}:{" "}
                    {currencyObject[`${rates ? generalCurr : currency}`]}
                    {getPrice(
                      fee?.feeType == "percent"
                        ? (fee?.value / 100) * propertyPrice
                        : fee?.value,
                      currency
                    )}
                  </p>
                ) : (
                  ""
                )
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
