import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { PropertySchema } from "services/network/_schema";
// import fp from "assets/images/fp-img1.svg";
// import { getStorageData, StorageKeys } from "lib/storageManager";
import { LocationMap } from "sharedComponents/locationMap";
import useNetworkData from "hooks/useNetworkData";
import { getNearbySearchRequest } from "services/network/properties";

export const PropertyLocation = ({
  property,
}: {
  property: PropertySchema;
}) => {
  // const token = getStorageData(StorageKeys.token);
  const [amenities, setAmenities] = useState<google.maps.places.PlaceResult[]>(
    []
  );
  // const [restaurants, setRestaurants] = useState<
  //   google.maps.places.PlaceResult[]
  // >([]);
  const { makeRequest } = useNetworkData();

  const getNearByAmenities = async () => {
    const res = await makeRequest({
      payload: {
        location: `${property.locationLatitude},${property.locationLongitude}`,
      },
      apiRequest: getNearbySearchRequest,
    });
    if (res?.data) {
      const { results } = res.data;
      setAmenities(
        results.filter(
          (item: google.maps.places.PlaceResult) =>
            item.name !== property.city &&
            !item.types?.includes("restaurant") &&
            !item.types?.includes("food") &&
            !item.types?.includes("lodging")
        )
      );
      // setRestaurants(
      //   results.filter(
      //     (item: google.maps.places.PlaceResult) =>
      //       (item.types?.includes("restaurant") ||
      //         item.types?.includes("food")) &&
      //       !item.types?.includes("lodging")
      //   )
      // );
    }
  };

  useEffect(() => {
    getNearByAmenities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  return (
    <>
      <div className="location-wrapper" id="location">
        <div className="container" id="no_w_translate">
          <h2 className="sub-title text-center pt-4 pt-sm-6 mb-4 mb-sm-6">
            Location
          </h2>
          <div className="row gy-5 mb-sm-9 mb-5">
            <div className="col-lg-6 col-12">
              <LocationMap
                lng={parseInt(property.locationLongitude)}
                lat={parseInt(property.locationLatitude)}
              />
            </div>
            <div className="col-lg-6 col-12">
              <div className="address-wrapper">
                <h2 className="xsub-title">Address</h2>
                <p className="fw-medium text-lg text-black-500">
                  {property.addressLine1}
                </p>
                <div className="border-grey-400"></div>
                <h2 className="xsub-title">Nearby Attractions</h2>
                <div className="row">
                  <div className="col-6">
                    {amenities.slice(0, 5).map((item, index) => (
                      <div
                        className="my-5 d-flex gap-4"
                        key={`${item.name}${index}`}
                      >
                        <div className="text-primary-600 text-xs">
                          <Icon icon="emojione-v1:heavy-check-mark" />
                        </div>
                        <p className="text-sm my-0">{item.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className="col-6">
                    {amenities.slice(5, 10).map((item, index) => (
                      <div
                        className="my-5 d-flex gap-4"
                        key={`${item.name}${index}`}
                      >
                        <div className="text-primary-600 text-xs">
                          <Icon icon="emojione-v1:heavy-check-mark" />
                        </div>
                        <p className="text-sm my-0">{item.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      {token && restaurants.length > 0 && (
        <div className="restaurants-wrapper" id="no_w_translate">
          <div className="container">
            <h2 className="sub-title text-center pt-6 mb-6">
              Restaurants Near you
            </h2>
            {restaurants.slice(0, 3).map((item, index) => (
              <div key={`${item.name}${index}`}>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    <img className="restaurant-img" src={fp} alt="" />
                    <div>
                      <div className="d-flex gap-2 my-2 align-items-center ">
                        <Icon
                          className="text-primary-600"
                          icon="ant-design:star-filled"
                        />

                        <p className="text-xs  m-0">{item.rating}</p>
                      </div>
                      <h2 className="xsub-title">{item.name}</h2>
                      <div className="d-flex gap-2 my-2 align-items-center text-primary-600">
                        <div>
                          <Icon icon="fluent:location-16-regular" />
                        </div>
                        <p className="text-sm  m-0">{item.vicinity}</p>
                      </div>
                    </div>
                  </div>
                  <div className="text-primary-600 text-sm-center">
                    // <p className="text-sm my-4_5">1 Mile Away</p> 
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <button className="btn btn-md btn-blue-dark">
                        Go to website
                      </button>
                    </a>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      )}
       */}
    </>
  );
};
