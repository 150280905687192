import { getCookieData, saveCookieData, StorageKeys } from "lib/storageManager";
import { useCallback, useState } from "react";
import { getConversionRatesRequest } from "services/network/locale";
// import { handleGeneralErrors } from "utilities/handleGeneralErrors";

export const useCurrency = () => {
  const [rates, setRates] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getRate = useCallback(async (currency: string) => {
    const prevData = getCookieData(StorageKeys.currencyRates);
    if (prevData && prevData[currency]) {
      setRates(prevData[currency]);
      return;
    }
    const payload = { currency };
    setIsLoading(true);
    try {
      const { data }: any = await getConversionRatesRequest(payload);
      setIsLoading(false);
      setRates(data.data.conversion_rates);
      saveCookieData(StorageKeys.currencyRates, {
        [`${currency}`]: data.data.conversion_rates,
      });
      return data;
    } catch (error: any) {
      // handleGeneralErrors(error.response);
      setIsLoading(false);
    }
  }, []);
  return { rates, isLoading, getRate };
};
