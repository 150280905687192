import { Icon } from "@iconify/react";
import React, { useRef, useState } from "react";
import "./style.scss";
import LoginRafiki from "assets/images/Login-rafiky.svg";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import Button from "sharedComponents/forms/Button";
import TextInput from "sharedComponents/forms/TextInput";
import { saveStorageData, StorageKeys } from "lib/storageManager";
import Modal from "react-bootstrap/Modal";
import useNetworkData from "hooks/useNetworkData";
import { loginRequest } from "services/network/auth";
import { toast } from "react-toastify";
import { GoogleAuthButton } from "../registerModal/socialAuths/GoogleAuthButton";
import { FacebookAuthButton } from "../registerModal/socialAuths/FacebookAuthButton";
import { RegisterSuccess } from "../registerModal/success";

export const LoginModal = ({
  show,
  handleClose,
  openRegister,
  openFP,
}: {
  show: boolean;
  handleClose: any;
  openRegister: any;
  openFP: any;
}) => {
  const { isLoading, makeRequest } = useNetworkData();
  const [step, setStep] = useState(1);
  const [unverify, setUnverify] = useState({});
  const formRef = useRef<any>(null);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().label("Email Address").required(),
    password: yup.string().label("Password").required(),
  });

  const handleLogin = async (formValues: any) => {
    const response = await makeRequest({
      payload: formValues,
      apiRequest: loginRequest,
      returnError: true,
    });
    if (response && !response.error && response.data.user.isVerified) {
      // toast.success(`Welcome ${response.data.user.firstName || ""} 😊!`);
      saveStorageData(StorageKeys.token, response.data.token);
      saveStorageData(StorageKeys.user, response.data.user);
      closeModal();
    } else if (response.error?.data?.isVerified === false) {
      setUnverify(response.error);
      setStep(2);
    } else {
      const message = response.error?.message || "Sorry, an error occurred!";
      toast.error(
        message === "Invalid login data"
          ? "Invalid username or password 😞"
          : message
      );
    }
  };

  const closeModal = () => {
    setStep(1);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={closeModal}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Body>
          {step === 1 ? (
            <div className="row">
              <div className="col-lg-6 col-12 pt-5 pb-5 px-7">
                <div className="close-modal" onClick={closeModal}>
                  <Icon icon="charm:square-cross" />
                </div>
                <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                  validateOnBlur={false}
                >
                  {({ handleSubmit, isValid }) => {
                    return (
                      <Form className="pt-0" onSubmit={handleSubmit}>
                        <>
                          <h2 className="sub-title ">Sign In</h2>
                          <label className=" text-xs  mt-4 mb-2 ms-3">
                            Email Address
                          </label>
                          <Field
                            component={TextInput}
                            className="form-control place"
                            placeholder="Enter Email Address"
                            type="email"
                            name="email"
                            id="email"
                          />
                          <label className=" text-xs  mt-4 mb-2 ms-3">
                            Password
                          </label>
                          <Field
                            component={TextInput}
                            type="password"
                            className="form-control place"
                            placeholder="Enter Password"
                            name="password"
                            id="password"
                          />
                          <p
                            className="text-xs text-primary-400 click  mt-4 mb-2 ms-3"
                            onClick={openFP}
                          >
                            Forgot Password?
                          </p>
                          <Button
                            title="Sign In"
                            type="submit"
                            disabled={isLoading || !isValid}
                            loading={isLoading}
                            loadingTitle={"Please wait..."}
                            className="btn btn-md text-xs btn-blue-dark w-100 mt-4"
                          />
                          <div className="row my-3">
                            <div className="col-4 border-line   my-auto"></div>
                            <div className="col-1 mx-auto text-lg">Or </div>
                            <div className="col-4  border-line my-auto"></div>
                          </div>
                          <GoogleAuthButton
                            closeModal={closeModal}
                            title="Continue with Google"
                            className={"btn btn-auth"}
                          />

                          <FacebookAuthButton
                            closeModal={closeModal}
                            title="Continue with Facebook"
                            className={"btn btn-auth mt-3"}
                          />
                        </>
                      </Form>
                    );
                  }}
                </Formik>
              </div>

              <div className="col-lg-6 d-mobile col-12 order-lg-2">
                <div className="NewUser-bg pb-5 px-7">
                  <div className="close-modal" onClick={closeModal}>
                    <Icon icon="charm:square-cross" />
                  </div>
                  <h2 className="sub-title pt-5 mb-4">New Users...</h2>
                  <div
                    style={{
                      width: "80%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <img className="pe-3" src={LoginRafiki} alt="product" />
                  </div>
                  <p className="text-xs mt-4">
                    Create a QuickStay account to manage bookings, connect with
                    owners and book a place faster next time. Property owners
                    can also register here to create a listing.
                  </p>
                  <button
                    className="btn btn-md text-xs btn-blue-dark w-100 mt-5"
                    onClick={openRegister}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <RegisterSuccess
              onHide={closeModal}
              email={formRef.current?.values?.email || ""}
              unverify={unverify || {}}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
