import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./sidebar.scope.scss";
import logo from "assets/images/logo-white.png";
import menu from "assets/images/menu.svg";
import close from "assets/images/Close.svg";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { logoutService } from "services/network/auth";
import { AuthContainer } from "sharedComponents/authContainer";
import { ReservationSchema, reservationStatus } from "services/network/_schema";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import { CustomDropdownToggle } from "sharedComponents/CustomDropdownToggle";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import {
  currencyArray,
  currencyObject,
  localeToFlags,
} from "utilities/country";
import { LayoutContext } from "context/layoutContext";
import {
  AvailableLanguageList,
  NotificationSchema,
} from "services/network/_schema";
import { toUpperLetter } from "utilities/helper";

export const Sidebar = ({ main }: { main?: boolean }) => {
  const user = getStorageData(StorageKeys.user);
  const [isOpen, setisOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  // const [myOpacity, setmyOpacity]=useState<string|number>('0.8')
  const {
    currency,
    setCurrency,
    language,
    setLanguage,
    unreadNotifications,
  }: {
    currency: string;
    setCurrency: Function;
    language: string;
    setLanguage: Function;
    unreadNotifications: NotificationSchema[];
  } = useContext(LayoutContext);

  const handleShowLogin = () => {
    setShowLogin(true);
    //   setisOpen(true)
    //  toggleSidebar();
  };
  const handleOpenRegister = () => {
    if (!user) {
      setOpenRegister(true);
    }
  };

  const toggleSidebar = () => {
    setisOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`menu-icon ${main ? "white" : "blue"}`}
        onClick={toggleSidebar}
      >
        <img src={menu} alt="menu" />
        {/* <Icon icon="grommet-icons:menu" /> */}
      </div>

      <section className={`sidebar ${isOpen && "sidebar-isOpen"}`}>
        <div className="sidebar-wrapper">
          <div
            className={`sidebar-overlay ${isOpen && "overlay-isOpen"}`}
            onClick={toggleSidebar}
          ></div>
          <div className="sidebar-head">
            <div className="d-flex ms-3">
              <div className="closeMenu me-5" onClick={toggleSidebar}>
                <img src={close} alt="close" />
              </div>
              <Dropdown
                className="desktop-item mobile-currency"
                autoClose="outside"
              >
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  className="nav-item tog"
                  id="dropdown-toggle"
                >
                  {currency} ({currencyObject[currency]})
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 48 48"
                    className="iconify"
                  >
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="4"
                      d="M36 18L24 30L12 18"
                    />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="bg-grey-30">
                  {currencyArray.map((cur: any) => (
                    <NavDropdown.Item
                      className="click"
                      key={cur}
                      onClick={() => {
                        setCurrency(cur);
                      }}
                    >
                      {cur} ({currencyObject[cur]})
                    </NavDropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <NavLink
              className="text-decoration-none"
              onClick={toggleSidebar}
              to="/download-app"
            >
              <button
                className="btn btn-lg me-4 btn-outline"
                type="button"
                style={{ padding: "7px" }}
              >
                <Icon
                  icon="clarity:mobile-phone-line"
                  className="me-2 text-xl"
                />
                Download App
              </button>
            </NavLink>
          </div>
          <ul className="top-list">
            {user ? (
              <>
                <li className="px-5_6">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="d-flex justify-content-center align-items-center">
                        <Icon
                          icon="bx:user-circle"
                          className="me-2 accc-icon text-sm"
                        />
                        {toUpperLetter(user.firstName)}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/dashboard/profile"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="bx:user-circle"
                              className="me-2 acc-icon text-sm"
                            />
                            <span>My Profile</span>
                          </Link>
                        </div>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/dashboard/my-bookings"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="emojione-monotone:fax-machine"
                              className="me-2 acc-icon text-sm"
                            />
                            <span>Bookings</span>
                          </Link>
                        </div>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/dashboard/my-bookings/hospitality"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="material-symbols:shield-with-house-outline"
                              className="me-2 acc-icon text-sm"
                            />
                            <span>Hospitality</span>
                          </Link>
                        </div>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/dashboard/messages"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="eva:message-square-outline"
                              className="me-2 acc-icon text-sm"
                            />
                            <span>Messages</span>
                          </Link>
                        </div>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/dashboard/listings"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="clarity:house-line"
                              className="me-2 acc-icon text-sm"
                            />
                            <span>My Property listings</span>
                          </Link>
                        </div>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/dashboard/payout"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="icon-park-outline:bank-card-one"
                              className="me-2 acc-icon text-sm"
                            />
                            <span>Payment & Payouts</span>
                          </Link>
                        </div>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/wishlist"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="el:heart-empty"
                              className="me-2 acc-icon text-sm"
                            />
                            <span> Wishlist</span>
                          </Link>
                        </div>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/dashboard/support"
                            onClick={toggleSidebar}
                          >
                            <Icon
                              icon="ic:outline-contact-support"
                              className="me-2 acc-icon text-sm"
                            />
                            <span> Chat with an agent</span>
                          </Link>
                        </div>
                        <div className="accordion-section border-none">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="/stay-options/corporate"
                            onClick={() => logoutService(true)}
                          >
                            <Icon
                              icon="bx:power-off"
                              className="me-2 acc-icon text-sm"
                            />
                            Logout
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
              </>
            ) : (
              <>
                <li className="px-5_6">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header> Sign in / Sign up</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordion-section">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="#"
                            onClick={() => handleShowLogin()}
                          >
                            Sign In
                          </Link>
                        </div>
                        <div className="accordion-section border-none">
                          <Link
                            className="click text-decoration-none text-white-000 text-sm"
                            to="#"
                            onClick={() => {
                              handleOpenRegister();
                            }}
                          >
                            Sign up
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
              </>
            )}
            <li className="px-5_6">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header> Stay Options</Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion-section">
                      <Link
                        className="click text-decoration-none text-white-000 text-sm"
                        to="/stay-options/weekly"
                        onClick={toggleSidebar}
                      >
                        Weekly
                      </Link>
                    </div>
                    <div className="accordion-section">
                      <Link
                        className="click text-decoration-none text-white-000 text-sm"
                        to="/stay-options/monthly"
                        onClick={toggleSidebar}
                      >
                        Monthly
                      </Link>
                    </div>
                    <div className="accordion-section border-none">
                      <Link
                        className="click text-decoration-none text-white-000 text-sm"
                        to="/stay-options/corporate"
                        onClick={toggleSidebar}
                      >
                        Corporate
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>
            <li className="px-5_6">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header> Get Approved</Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion-section border-none">
                      <Link
                        className="click text-decoration-none text-white-000 text-sm"
                        onClick={toggleSidebar}
                        to="/get-approved"
                      >
                        List A Property
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>
          </ul>

          <ul className="mt-auto mb-5">
            <li className="px-5">
              <NavLink
                className=" text-decoration-none w-100"
                onClick={toggleSidebar}
                to="/properties"
              >
                <button
                  className="btn py-4 mt-5 btn-lg w-100 btn-outline btn-outline-blue"
                  type="button"
                >
                  Book A Property
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
      {isOpen && (
        <AuthContainer
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          handleShowLogin={handleShowLogin}
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          handleOpenRegister={handleOpenRegister}
        />
      )}
    </>
  );
};
